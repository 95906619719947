import React, { useEffect, useState } from "react";
import Header from "./../../Components/NewHeader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OrderHistory from "./order-history";
import Packages from "./packages";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../Firebase";
import { getPaymentMethodsAction } from "../../Store/Actions/UserActions";
import { NotificationManager } from "react-notifications";

function PurchasePackages(props) {
  const dispatch = useDispatch();
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const paymentMethods = useSelector((state) => state.UserReducer.paymentMethods);
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const getPaymentMethods = async () => {
    try {
      if (!localUserData?.stripeId) return;
      let fetchURL = function_url + "/getPaymentMethods";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/getPaymentMethods";

      const authToken = await auth.currentUser.getIdToken();
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": authToken },
        body: JSON.stringify({
          customer: localUserData?.stripeId,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        dispatch(getPaymentMethodsAction(result?.paymentMethods));
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
    }
  };

  useEffect(() => {
    if (!paymentMethods?.length) getPaymentMethods();
  }, []);

  return (
    <>
      <Header title="My Subscriptions" />
      <div className="px-25">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Packages" />
          <Tab label="Billings" />
        </Tabs>

        {value === 0 ? <Packages /> : <OrderHistory />}
      </div>
    </>
  );
}
export default PurchasePackages;
