/**
 * Helpers Functions
 */
import moment from "moment";
import { auth } from "../Firebase";

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
  let time = timestamp * 1000;
  let formatDate = format ? format : "MM-DD-YYYY";
  return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : "YYYY-MM-DD";
  return moment(date, formatDate).unix();
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
  let location = url.pathname;
  let path = location.split("/");
  return path[1];
}

export function getReactAppAPIKey() {
  return (
    process.env.REACT_APP_API_KEY || "AIzaSyDHGwgmZW431-QC8FviMeOlrZHyXoRXO3k"
  );
}

export function getReactAppGoogleMapAPIKey() {
  return (
    process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
    "AIzaSyDHGwgmZW431-QC8FviMeOlrZHyXoRXO3k"
  );
}

export function getGoldFinchConfiguration() {
  //hours, days, weeks, months, years
  const goldfinch_duration_type =
    process.env.REACT_APP_GOLDFINCH_DURATION_TYPE || "hrs";
  const goldfinch_duration_number =
    parseInt(process.env.REACT_APP_GOLDFINCH_DURATION_NUMBER || 1) || 1;

  let return_data = {
    goldfinch_duration_type: goldfinch_duration_type,
    goldfinch_duration_number: goldfinch_duration_number,
  };
  let text = "";
  if (goldfinch_duration_type === "hours") {
    return_data.hours = goldfinch_duration_number;
    text = "hour";
  } else if (goldfinch_duration_type === "days") {
    return_data.hours = goldfinch_duration_number * 24;
    text = "day";
  } else if (goldfinch_duration_type === "weeks") {
    return_data.hours = goldfinch_duration_number * 24 * 7;
    text = "week";
  } else if (goldfinch_duration_type === "months") {
    return_data.hours = goldfinch_duration_number * 24 * 30;
    text = "month";
  } else if (goldfinch_duration_type === "years") {
    return_data.hours = goldfinch_duration_number * 24 * 365;
    text = "year";
  }
  text += goldfinch_duration_number > 1 ? "s" : "";
  return_data.goldfinch_duration_text = goldfinch_duration_number + " " + text;
  return_data.goldfinch_duration_ignore_for_one_text =
    (goldfinch_duration_number > 1 ? goldfinch_duration_number + " " : "") +
    text;
  return_data.goldfinch_hours_text =
    return_data.hours + " hour" + (return_data.hours > 1 ? "s" : "");

  return return_data;
}

export const GoldfinchConfig = getGoldFinchConfiguration();
