import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_DRENCH,
  GET_DRENCH_SUCCESS,
  GET_DRENCH_FAILURE,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  SET_DRENCH,
} from "Store/Actions/types";

const INITIAL_STATE = {
  categories: [],
  loading: false,
  drenches: [],
  subscription: [],
  balance: 0,
};

let drench = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_DRENCH:
      return {
        ...state,
        loading: true,
      };

    case GET_DRENCH_SUCCESS:
      return {
        ...state,
        loading: false,
        drenches: action.payload,
      };

    case GET_DRENCH_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case GET_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBSCRIPTION_SUCCESS:
      // const checkIsActive = state.drenches.map(item => {
      //   if (action.changeToArray.includes(item.drenchKey)) {
      //     return { ...item, active: !item.active }
      //   }
      //   return item
      // })
      // console.log(checkIsActive, 'checkIsActive')
      return {
        ...state,
        loading: false,
        subscription: action.payload,
        balance: action.balance,
        // drenches: checkIsActive
      };

    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_DRENCH:
      const checkIsActive = state.drenches.map((item) => {
        if (action.ids.includes(item.drenchKey)) {
          return { ...item, active: !item.active };
        }
        return item;
      });
      return {
        ...state,
        drenches: checkIsActive,
      };

    default:
      return state;
  }
};

export default drench;
