/**
 * App Reducers
 */
import { combineReducers } from "redux";
import settings from "./settings";
import sidebarReducer from "./SidebarReducer";
import authUserReducer from "./AuthUserReducer";
import Drench from "./Drench";
import Subscription from "./SubscriptionReducer";
import Analytics from "./AnalythicsReducer";
import AssignDrench from "./AssignDrench";
import Cf from "./Cf";
import Franchise from "./Franchise";
import UserReducer from "./UserReducer";
import PackagesReducer from "./PackagesReducer";

const Reducers = combineReducers({
  settings,
  sidebar: sidebarReducer,
  authUser: authUserReducer,
  AssignDrench: AssignDrench,
  Drench: Drench,
  Subscription: Subscription,
  Analytics: Analytics,
  Cf: Cf,
  Franchise,
  UserReducer,
  PackagesReducer,
});

export default Reducers;
