import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { store } from "./../../Store/index";
import { Switch, Tooltip, Typography, withStyles } from "@material-ui/core";
import _ from "underscore";
import { useSelector, useDispatch } from "react-redux";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BinIcon from "../../Assets/img/bin.svg";
import PenIcon from "../../Assets/img/pen.svg";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Header from "./../../Components/NewHeader";
import { db, auth } from "../../Firebase";
import { ADD_FRANCHISES, DELETE_FRANCHISE, UPDATE_FRANCHISE } from "./../../Store/Actions/types";
import moment from "moment";
import { getActivePackagesItemsDetails } from "../../Util/subscriptions";
import "./styles.scss";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Franchises = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const franchises = useSelector((state) => state.Franchise.franchises);
  const [subscriptionsDetails, setSubscriptionsDetails] = useState({});

  const handleDeleteFranchise = async ({ franchiseId, geofenceId }) => {
    try {
      const function_url = store?.getState()?.Cf?.function_url || "";
      const token = await auth.currentUser.getIdToken();

      fetch(function_url + "/deleteFranchise", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          franchiseId,
          geofenceId,
        }),
      });
      dispatch({
        type: DELETE_FRANCHISE,
        payload: franchiseId,
      });
    } catch (error) {
      console.log(error, "ERROR");
    }
  };

  const handleFranchiseActiveStatus = async (data) => {
    try {
      const updatedData = { ...data, isActive: !data.isActive };
      dispatch({
        type: UPDATE_FRANCHISE,
        payload: updatedData,
      });
      await db.collection("franchises").doc(data.franchiseId).update(updatedData);
    } catch (error) {
      dispatch({
        type: UPDATE_FRANCHISE,
        payload: data,
      });
      console.log(error);
    }
  };

  const columns = [
    {
      name: "name",
      label: "Franchise Name",
    },
    {
      name: "address",
      label: "Location",
    },

    {
      name: "validTill",
      label: "Renewal Date",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <div className={"d-flex justify-content-start align-items-center"}>
              {moment(new Date(value.seconds * 1000)).format("MMM-DD-YYYY")}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (_value, tableMetaData) => {
          const data = franchises[tableMetaData?.rowIndex];
          return (
            <div style={{ gap: 20 }} className={"d-flex justify-content-start align-items-center"}>
              <img
                src={PenIcon}
                style={{ cursor: "pointer" }}
                alt={"edit"}
                onClick={() => history.push("/dashboard/franchises/" + data.franchiseId)}
              />
              {/* <img
                src={BinIcon}
                style={{ cursor: "pointer" }}
                alt={"delete"}
                onClick={() =>
                  handleDeleteFranchise({
                    franchiseId: data?.franchiseId,
                    geofenceId: data?.geofenceId,
                  })
                }
              /> */}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Activate/Deactivate",
      options: {
        filter: false,
        customBodyRender: (_, tableMetaData) => {
          const data = franchises[tableMetaData?.rowIndex];
          return (
            <div className={"d-flex justify-content-start align-items-center"}>
              <Switch
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={data.isActive}
                onChange={() => handleFranchiseActiveStatus(data)}
              />
            </div>
          );
        },
      },
    },
  ];

  const getFranchise = async (data) => {
    try {
      const snap = await db.collection("franchises").where("restaurantId", "==", localUserData?.uid).get();
      const data = snap.docs.map((doc) => doc.data());
      dispatch({
        type: ADD_FRANCHISES,
        payload: data,
      });
      console.log(data, "DATA_FRANCHISE");
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  useEffect(() => {
    if (localUserData?.uid) {
      getFranchise();
      getActivePackagesItemsDetails(localUserData?.uid).then((data) => {
        setSubscriptionsDetails(data);
      });
    }
  }, [localUserData?.uid]);

  const addFranchiseButton = (
    <Button
      disabled={subscriptionsDetails?.franchise <= 0}
      variant="contained"
      color="primary"
      style={{
        backgroundColor: subscriptionsDetails?.franchise <= 0 ? "gray" : "#1A975B",
        color: "white",
      }}
      startIcon={<AddCircleIcon />}
      onClick={() => history.push("add-franchises")}
    >
      Add Franchise
    </Button>
  );
  return (
    <div>
      <Header
        title={
          <>
            <div className="remaining-franchise__container">
              <span className="page-heading">Franchises List</span>
              <div>Remaining Franchises: {subscriptionsDetails?.franchise}</div>
            </div>
          </>
        }
      />
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <MUIDataTable
          data={franchises}
          columns={columns}
          options={{
            selectableRows: false,
            rowsPerPageOptions: [10, 50, 100],
            elevation: 0,
            download: false,
            print: false,
            filter: false,
            customToolbar: () => {
              return subscriptionsDetails?.franchise <= 0 ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography className="mb-5" color="inherit" variant="body2">
                        <b>Franchise package reached.</b>
                      </Typography>
                      Please buy a new package to add more franchises.
                    </React.Fragment>
                  }
                >
                  <span>{addFranchiseButton}</span>
                </HtmlTooltip>
              ) : (
                addFranchiseButton
              );
            },
          }}
        />
      </div>
    </div>
  );
};

export default Franchises;
