import { configureStore } from "@reduxjs/toolkit";
import Thunk from "redux-thunk";
import reducers from "./Reducers";
import { persistStore, persistReducer } from "redux-persist";
import localStorage from "redux-persist/es/storage";

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["UserReducer", "PackagesReducer"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [Thunk],
});
const persistor = persistStore(store);

export { store, persistor };
