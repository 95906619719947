module.exports = {
  "sidebar.app": "应用",
  "sidebar.horizontal": "横",
  "sidebar.horizontalMenu": "水平的菜单",
  "sidebar.general": "一般",
  "sidebar.component": "零件",
  "sidebar.features": "特征",
  "sidebar.applications": "应用",
  "sidebar.dashboard": "仪表板",
  "sidebar.dashboard1": "仪表板 1",
  "sidebar.dashboard2": "仪表板 2",
  "sidebar.dashboard3": "仪表板 3",
  "sidebar.modules": "模块",
  "sidebar.agency": "机构",
  "sidebar.pages": "网页",
  "sidebar.gallery": "画廊",
  "sidebar.pricing": "价钱",
  "sidebar.terms&Conditions": "条款条件",
  "sidebar.feedback": "反馈",
  "sidebar.report": "报告",
  "sidebar.faq(s)": "FAQ（S",
  "sidebar.advancedComponent": "高级组件",
  "sidebar.blank": "空白",
  "sidebar.session": "会议",
  "sidebar.login": "登录",
  "sidebar.register": "寄存器",
  "sidebar.lockScreen": "锁屏",
  "sidebar.forgotPassword": "忘记密码",
  "sidebar.404": "404",
  "sidebar.500": "500",
  "sidebar.uiComponents": "UI组件",
  "sidebar.alerts": "警报",
  "sidebar.appBar": "应用程序栏",
  "sidebar.avatars": "头像",
  "sidebar.buttons": "纽扣",
  "sidebar.bottomNavigations": "底部导航",
  "sidebar.badges": "徽章",
  "sidebar.cards": "牌",
  "sidebar.cardsMasonry": "卡砌体",
  "sidebar.chip": "芯片",
  "sidebar.dialog": "对话",
  "sidebar.dividers": "分频器",
  "sidebar.drawers": "抽屉",
  "sidebar.popover": "酥料饼",
  "sidebar.expansionPanel": "扩展面板",
  "sidebar.gridList": "网格列表",
  "sidebar.list": "名单",
  "sidebar.menu": "菜单",
  "sidebar.popoverAndToolTip": "流行和工具提示",
  "sidebar.progress": "進展",
  "sidebar.snackbar": "小吃店",
  "sidebar.selectionControls": "選擇控件",
  "sidebar.advanceUiComponents": "提前UI組件",
  "sidebar.dateAndTimePicker": "日期和時間選擇器",
  "sidebar.tabs": "標籤",
  "sidebar.stepper": "步進",
  "sidebar.notification": "通知",
  "sidebar.sweetAlert": "甜蜜警報",
  "sidebar.autoComplete": "自動完成",
  "sidebar.aboutUs": "關於我們",
  "sidebar.widgets": "小工具",
  "sidebar.forms": "形式",
  "sidebar.formElements": "表單元素",
  "sidebar.textField": "文本域",
  "sidebar.selectList": "選擇列表",
  "sidebar.charts": "圖表",
  "sidebar.reCharts": "重新圖表",
  "sidebar.reactChartjs2": "应对 图表 Js 2",
  "sidebar.icons": "圖標",
  "sidebar.themifyIcons": "Themify圖標",
  "sidebar.simpleLineIcons": "簡單的線圖標",
  "sidebar.fontAwesome": "字體真棒",
  "sidebar.materialIcons": "材料圖標",
  "sidebar.tables": "表",
  "sidebar.basic": "基本",
  "sidebar.dataTable": "數據表",
  "sidebar.responsive": "響應",
  "sidebar.reactTable": "反應表",
  "sidebar.maps": "地圖",
  "sidebar.googleMaps": "谷歌地圖",
  "sidebar.leafletMaps": "傳單地圖",
  "sidebar.inbox": "收件箱",
  "sidebar.users": "用戶",
  "sidebar.userProfile1": "用户配置文件1",
  "sidebar.userProfile2": "用户配置文件2",
  "sidebar.userManagement": "用户管理",
  "sidebar.userProfile": "用戶資料",
  "sidebar.userList": "用戶列表",
  "sidebar.calendar": "日历",
  "sidebar.cultures": "文化",
  "sidebar.dnd": "DND",
  "sidebar.selectable": "可選",
  "sidebar.customRendering": "自定義渲染",
  "sidebar.chat": "聊",
  "sidebar.toDo": "去做",
  "sidebar.editor": "編輯",
  "sidebar.wysiwygEditor": "所見即所得的編輯器",
  "sidebar.quillEditor": "羽毛編輯器",
  "sidebar.reactAce": "应对 高手",
  "sidebar.dragAndDrop": "拖放",
  "sidebar.reactDragula": "反應Dragula",
  "sidebar.reactDnd": "反應Dnd",
  "sidebar.blogManagement": "博客管理",
  "sidebar.ecommerce": "電子商務",
  "sidebar.shopList": "商店列表",
  "sidebar.shopGrid": "車間網格",
  "sidebar.invoice": "發票",
  "sidebar.multilevel": "多級",
  "sidebar.sublevel": "綜",
  "widgets.totalEarns": "贏得總額",
  "widgets.emailsStatistics": "電子郵件統計",
  "widgets.totalRevenue": "總收入",
  "widgets.onlineVistors": "在線訪問者",
  "widgets.trafficSources": "流量來源",
  "widgets.RecentOrders": "最近的訂單",
  "widgets.topSellings": "暢銷產品",
  "widgets.productReports": "產品報告",
  "widgets.productStats": "產品統計",
  "widgets.ComposeEmail": "寫郵件",
  "widgets.employeePayroll": "員工工資",
  "widgets.visitors": "遊客",
  "widgets.orders": "命令",
  "widgets.orderStatus": "訂單狀態",
  "widgets.totalSales": "總銷售額",
  "widgets.netProfit": "淨利",
  "widgets.overallTrafficStatus": "總體交通狀況",
  "widgets.tax": "稅",
  "widgets.expenses": "筆開支",
  "widgets.currentTime": "当前时间",
  "widgets.currentDate": "当前日期",
  "widgets.todayOrders": "今日订单",
  "widgets.toDoList": "要做列表",
  "widgets.discoverPeople": "發現人",
  "widgets.commments": "註釋",
  "widgets.newCustomers": "新客戶",
  "widgets.recentNotifications": "近期通知",
  "widgets.appNotifications": "应用程序提醒",
  "widgets.newEmails": "新電子郵件",
  "widgets.siteVisitors": "網站訪問者",
  "widgets.socialCompanines": "社会公司",
  "widgets.recentActivities": "近期活動",
  "widgets.recentOrders": "最近的訂單",
  "widgets.gallery": "畫廊",
  "widgets.pricing": "價錢",
  "widgets.enterpriseEdition": "企業版",
  "widgets.personalEdition": "個人版",
  "widgets.teamEdition": "團隊版",
  "widgets.standard": "標準",
  "widgets.advanced": "高級",
  "widgets.master": "主",
  "widgets.Mega": "兆豐",
  "widgets.logIn": "登錄",
  "widgets.signUp": "註冊",
  "widgets.lockScreen": "鎖屏",
  "widgets.alertsWithLink": "警報與鏈接",
  "widgets.additionalContent": "其他內容",
  "widgets.alertDismiss": "警報關閉",
  "widgets.uncontrolledDisableAlerts": "不受控制的警報",
  "widgets.contexualAlerts": "同性戀警報",
  "widgets.ratings": "评级",
  "widgets.alertsWithIcons": "與圖標的警報",
  "widgets.Simple App Bars": "簡單的應用程序",
  "widgets.appBarsWithButtons": "與按鈕的應用酒吧",
  "widgets.imageAvatars": "圖像頭像",
  "widgets.lettersAvatars": "字母頭像",
  "widgets.iconsAvatars": "圖標頭像",
  "widgets.flatButtons": "平的按鈕",
  "widgets.raisedButton": "凸起的按鈕",
  "widgets.buttonWithIconAndLabel": "有像和標籤的按鈕",
  "widgets.floatingActionButtons": "浮動動作按鈕",
  "widgets.iconButton": "圖標按鈕",
  "widgets.socialMediaButton": "社交媒體按鈕",
  "widgets.reactButton": "反應按鈕",
  "widgets.buttonOutline": "按鈕輪廓",
  "widgets.buttonSize": "按鈕大小",
  "widgets.buttonState": "按鈕狀態",
  "widgets.buttonNavigationWithNoLabel": "沒有標籤的按鈕導航",
  "widgets.buttonNavigation": "按鈕導航",
  "widgets.iconNavigation": "圖標導航",
  "widgets.badgeWithHeadings": "與標題的徽章",
  "widgets.contexualVariations": "同性戀變異",
  "widgets.badgeLinks": "徽章鏈接",
  "widgets.materialBadge": "物質徽章",
  "widgets.simpleCards": "簡單的卡片",
  "widgets.backgroundVarient": "背景變化",
  "widgets.cardOutline": "卡大綱",
  "widgets.overlayCard": "覆蓋卡",
  "widgets.cardGroup": "卡組",
  "widgets.cardTitle": "卡標題",
  "widgets.speacialTitleTreatment": "特殊標題治療",
  "widgets.chipWithClickEvent": "芯片與點擊事件",
  "widgets.chipArray": "芯片陣列",
  "widgets.dialogs": "對話框",
  "widgets.listDividers": "列出分隔線",
  "widgets.insetDividers": "插入分隔線",
  "widgets.temporaryDrawers": "臨時抽屜",
  "widgets.permanentDrawers": "永久性抽屜",
  "widgets.simpleExpansionPanel": "簡單的擴展面板",
  "widgets.controlledAccordion": "受控手風琴",
  "widgets.secondaryHeadingAndColumns": "次級標題和列",
  "widgets.imageOnlyGridLists": "僅圖像網格列表",
  "widgets.advancedGridLists": "高級網格列表",
  "widgets.singleLineGridLists": "單線網格列表",
  "widgets.simpleLists": "簡單列表",
  "widgets.folderLists": "文件夾列表",
  "widgets.listItemWithImage": "列出具有圖像的項目",
  "widgets.switchLists": "切換列表",
  "widgets.insetLists": "插入列表",
  "widgets.nestedLists": "嵌套列表",
  "widgets.checkboxListControl": "複選框列表控件",
  "widgets.pinedSubHeader": "釘住小標題",
  "widgets.InteractiveLists": "交互式列表",
  "widgets.simpleMenus": "簡單的菜單",
  "widgets.selectedMenu": "選定的菜單",
  "widgets.maxHeightMenu": "最大高度菜單",
  "widgets.changeTransition": "改變轉變",
  "widgets.paper": "紙",
  "widgets.anchorPlayGround": "錨播放地面",
  "widgets.tooltip": "工具提示",
  "widgets.positionedToolTips": "定位小吃店",
  "widgets.circularProgressBottomStart": "圓形進展底部開始",
  "widgets.interactiveIntegration": "交互式集成",
  "widgets.determinate": "確定的",
  "widgets.linearProgressLineBar": "線性進度線條",
  "widgets.indeterminate": "不定",
  "widgets.buffer": "緩衝",
  "widgets.query": "詢問",
  "widgets.transitionControlDirection": "過渡控制方向",
  "widgets.simpleSnackbar": "簡單的小吃店",
  "widgets.positionedSnackbar": "定位小吃店",
  "widgets.contexualColoredSnackbars": "同性色彩的小吃店",
  "widgets.simpleCheckbox": "簡單的複選框",
  "widgets.interminateSelection": "中斷選擇",
  "widgets.disabledCheckbox": "已禁用複選框",
  "widgets.customColorCheckbox": "自定義顏色複選框",
  "widgets.VerticalStyleCheckbox": "垂直樣式複選框",
  "widgets.horizontalStyleCheckbox": "水平樣式複選框",
  "widgets.radioButtons": "單選按鈕",
  "widgets.disabledRadio": "殘疾人電台",
  "widgets.withError": "有錯誤",
  "widgets.switches": "Swiches",
  "widgets.dateAndTimePicker": "日期和時間選擇器",
  "widgets.defaultPicker": "默認選取器",
  "widgets.timePicker": "時間選擇器",
  "widgets.weekPicker": "每週選擇器",
  "widgets.defaultDatePicker": "默認日期選取器",
  "widgets.customPicker": "自定義選取器",
  "widgets.tabs": "標籤",
  "widgets.fixedTabs": "固定標籤",
  "widgets.basicTab": "基本選項卡",
  "widgets.wrappedLabels": "包裝的標籤",
  "widgets.centeredLabels": "居中標籤",
  "widgets.forcedScrolledButtons": "強制滾動按鈕",
  "widgets.iconsTabs": "圖標選項卡",
  "widgets.withDisableTabs": "禁用選項卡",
  "widgets.iconWithLabel": "與標籤的圖標",
  "widgets.stepper": "步進",
  "widgets.horizontalLinear": "水平線性",
  "widgets.horizontalNonLinear": "水平非線性",
  "widgets.horizontalLinerAlternativeLabel": "水平的班輪替代標籤",
  "widgets.horizontalNonLinerAlternativeLabel": "水平無襯墊替代標籤",
  "widgets.verticalStepper": "垂直步進器",
  "widgets.descriptionAlert": "說明警報",
  "widgets.customIconAlert": "自定義圖標警報",
  "widgets.withHtmlAlert": "隨著Html 警报",
  "widgets.promptAlert": "提示警報",
  "widgets.passwordPromptAlert": "密碼提示警報",
  "widgets.customStyleAlert": "自定義樣式警報",
  "widgets.autoComplete": "自動完成",
  "widgets.reactSelect": "反應選擇",
  "widgets.downshiftAutoComplete": "降檔自動完成",
  "widgets.reactAutoSuggests": "反應自動建議",
  "widgets.aboutUs": "關於我們",
  "widgets.ourVission": "我們的願景",
  "widgets.ourMissions": "我們的使命",
  "widgets.ourMotivation": "我們的動機",
  "widgets.defualtReactForm": "默认 应对 形成",
  "widgets.url": "網址",
  "widgets.textArea": "文本區域",
  "widgets.file": "文件",
  "widgets.formGrid": "表格網格",
  "widgets.inlineForm": "內聯表格",
  "widgets.inputSizing": "輸入大小",
  "widgets.inputGridSizing": "輸入網格大小",
  "widgets.hiddenLabels": "隱藏標籤",
  "widgets.formValidation": "表單驗證",
  "widgets.number": "數",
  "widgets.date": "日期",
  "widgets.time": "時間",
  "widgets.color": "顏色",
  "widgets.search": "搜索",
  "widgets.selectMultiple": "選擇多個",
  "widgets.inputWithSuccess": "輸入與成功",
  "widgets.inputWithDanger": "危險輸入",
  "widgets.simpleTextField": "簡單的文本字段",
  "widgets.componet": "組件",
  "widgets.layouts": "佈局",
  "widgets.inputAdorements": "輸入戴首饰",
  "widgets.formattedInputs": "格式化輸入",
  "widgets.simpleSelect": "簡單的選擇",
  "widgets.nativeSelect": "本地選擇",
  "widgets.mutltiSelectList": "多選擇列表",
  "widgets.lineChart": "折線圖",
  "widgets.barChart": "條形圖",
  "widgets.stackedBarChart": "堆積的條形圖",
  "widgets.lineBarAreaChart": "線條區域圖",
  "widgets.areaChart": "面積圖",
  "widgets.stackedAreaChart": "堆積面積圖",
  "widgets.verticalChart": "垂直圖表",
  "widgets.radarChart": "雷達圖",
  "widgets.doughnut": "甜甜圈",
  "widgets.polarChart": "極坐標圖",
  "widgets.pieChart": "餅形圖",
  "widgets.bubbleChart": "泡沫圖",
  "widgets.horizontalBar": "單槓",
  "widgets.basicTable": "基本表",
  "widgets.contexualColoredTable": "同性色的桌子",
  "widgets.dataTable": "數據表",
  "widgets.employeeList": "員工名單",
  "widgets.responsiveTable": "響應表",
  "widgets.responsiveFlipTable": "響應翻轉表",
  "widgets.reactGridControlledStateMode": "反應網格控制狀態模式",
  "widgets.googleMaps": "谷歌地圖",
  "widgets.productsReports": "產品報告",
  "widgets.taskList": "任務列表",
  "widgets.basicCalender": "基本日曆",
  "widgets.culturesCalender": "文化日曆",
  "widgets.dragAndDropCalender": "拖放日曆",
  "widgets.selectableCalender": "可選日曆",
  "widgets.customRendering": "自定義渲染",
  "widgets.customCalender": "自定義日曆",
  "widgets.searchMailList": "搜索郵件列表",
  "components.buyNow": "立即購買",
  "compenets.choose": "選擇",
  "compenets.username": "用戶名",
  "compenets.passwords": "密碼",
  "widgets.forgetPassword": "忘記密碼",
  "compenets.signIn": "簽到",
  "compenets.dontHaveAccountSignUp": "不要有帳戶註冊",
  "compenets.enterUserName": "輸入用戶名",
  "compenets.enterEmailAddress": "請輸入電郵地址",
  "compenets.confirmPasswords": "確認密碼",
  "components.alreadyHavingAccountSignIn": "已經有帳戶登錄",
  "components.enterYourPassword": "輸入您的密碼",
  "components.unlock": "開鎖",
  "components.enterPasswords": "輸入密碼",
  "components.resestPassword": "重設密碼",
  "components.pageNotfound": "找不到網頁",
  "components.goToHomePage": "去首頁",
  "components.sorryServerGoesWrong": "抱歉服務器出錯了",
  "components.persistentDrawer": "持久的抽屜",
  "components.back": "背部",
  "components.next": "下一個",
  "components.completeStep": "完成一步",
  "components.withHtml": "與Html",
  "components.prompt": "提示",
  "components.withDescription": "帶說明",
  "components.success": "成功",
  "components.passwordPrompt": "密碼提示",
  "components.warning": "警告",
  "components.customIcon": "自定義圖標",
  "components.customStyle": "自定義樣式",
  "components.basic": "基本",
  "components.submit": "提交",
  "components.compose": "撰寫",
  "components.sendMessage": "發信息",
  "components.addNewTasks": "添加新任務",
  "components.addToCart": "添加到購物車",
  "components.payNow": "現在付款",
  "components.print": "打印",
  "components.cart": "大车",
  "components.viewCart": "查看购物车",
  "components.checkout": "查看",
  "widgets.QuickLinks": "快速链接",
  "widgets.upgrade": "升级",
  "widgets.app": "应用",
  "widgets.addNew": "添新",
  "widgets.orderDate": "订购日期",
  "widgets.status": "状态",
  "widgets.trackingNumber": "追踪号码",
  "widgets.action": "行动",
  "widgets.designation": "指定",
  "widgets.subject": "学科",
  "widgets.send": "发送",
  "widgets.saveAsDrafts": "另存为草稿",
  "widgets.onlineSources": "在线来源",
  "widgets.lastMonth": "上个月",
  "widgets.widgets": "小工具",
  "widgets.listing": "清单",
  "widgets.paid": "付费",
  "widgets.refunded": "退款",
  "widgets.done": "完成",
  "widgets.pending": "有待",
  "widgets.cancelled": "取消",
  "widgets.approve": "批准",
  "widgets.following": "以下",
  "widgets.follow": "跟随",
  "widgets.graphs&Charts": "图表和图表",
  "widgets.open": "打开",
  "widgets.bounced": "退回",
  "widgets.spam": "垃圾邮件",
  "widgets.unset": "取消设置",
  "widgets.bandwidthUse": "带宽使用",
  "widgets.dataUse": "数据使用",
  "widgets.unsubscribe": "退订",
  "widgets.profile": "轮廓",
  "widgets.messages": "消息",
  "widgets.support": "支持",
  "widgets.faq(s)": "FAQ（S）",
  "widgets.upgradePlains": "升级平原",
  "widgets.logOut": "登出",
  "widgets.mail": "邮件",
  "widgets.adminTheme": "管理主题",
  "widgets.wordpressTheme": "WordPress的主题",
  "widgets.addToCart": "添加到购物车",
  "widgets.plan": "计划",
  "widgets.basic": "基本",
  "widgets.pro": "临",
  "widgets.startToBasic": "开始到基本",
  "widgets.upgradeToPro": "升级到专业版",
  "widgets.upgradeToAdvance": "升级提前",
  "widgets.comparePlans": "比较计划",
  "widgets.free": "自由",
  "widgets.frequentlyAskedQuestions": "经常问的问题",
  "widgets.searchIdeas": "搜索想法",
  "widgets.startDate": "开始日期",
  "widgets.endDate": "结束日期",
  "widgets.cateogary": "类别",
  "widgets.apply": "应用",
  "widgets.downloadPdfReport": "下载PDF报告",
  "widgets.yesterday": "昨天",
  "widgets.totalOrders": "总订单",
  "widgets.totalVisitors": "总访问者数",
  "widgets.typeYourQuestions": "输入您的问题",
  "widgets.username": "用户名",
  "widgets.password": "密码",
  "widgets.signIn": "签到",
  "widgets.enterYourPassword": "输入您的密码",
  "widgets.alreadyHavingAccountLogin": "已经有帐户登录",
  "widgets.composeMail": "编写邮件",
  "widgets.issue": "问题",
  "widgets.recentChat": "最近聊天",
  "widgets.previousChat": "以前的聊天",
  "widgets.all": "所有",
  "widgets.filters": "过滤器",
  "widgets.deleted": "删除",
  "widgets.starred": "主演",
  "widgets.frontend": "前端",
  "widgets.backend": "后端",
  "widgets.api": "API",
  "widgets.simpleAppBar": "简单的应用栏",
  "widgets.recents": "近期]",
  "widgets.cardLink": "卡链接",
  "widgets.anotherLink": "另一个链接",
  "widgets.cardSubtitle": "卡片副标题",
  "widgets.confirmationDialogs": "确认对话框",
  "widgets.deletableChip": "可删除芯片",
  "widgets.customDeleteIconChip": "自定义删除图标芯片",
  "widgets.openAlertDialog": "打开警报对话框",
  "widgets.openResponsiveDialog": "打开响应对话框",
  "widgets.openSimpleDialog": "打开简单对话框",
  "widgets.openFormDialog": "打开表单对话框",
  "widgets.follower": "信徒",
  "widgets.important": "重要",
  "widgets.private": "私人的",
  "widgets.openLeft": "打开 剩下",
  "widgets.openRight": "开放权利",
  "widgets.openTop": "打开顶端",
  "widgets.openBottom": "打开底部",
  "widgets.selectTripDestination": "选择旅程目的地",
  "widgets.pinnedSubheaderList": "固定的副标题列表",
  "widgets.singleLineItem": "单行项目",
  "widgets.acceptTerms": "接受条款",
  "widgets.optionA": "选项A",
  "widgets.optionB": "选项B.",
  "widgets.optionC": "选项C",
  "widgets.optionM": "选项M",
  "widgets.optionN": "选项N",
  "widgets.optionO": "选项O",
  "widgets.customColor": "自定义颜色",
  "widgets.centeredTabs": "居中的选项卡",
  "widgets.multipleTabs": "多个选项卡",
  "widgets.preventScrolledButtons": "防止滚动按钮",
  "widgets.browse": "浏览",
  "widgets.formValidate": "表单验证",
  "widgets.code": "码",
  "widgets.company": "公司",
  "widgets.price": "价钱",
  "widgets.change": "更改",
  "widgets.high": "高",
  "widgets.low": "低",
  "widgets.volume": "卷",
  "widgets.personalDetails": "个人资料",
  "widgets.occupation": "占用",
  "widgets.companyName": "公司名",
  "widgets.phoneNo": "电话号码",
  "widgets.city": "市",
  "widgets.zipCode": "邮政编码",
  "widgets.updateProfile": "更新个人信息",
  "widgets.reject": "拒绝",
  "widgets.exportToExcel": "导出到高强",
  "widgets.addNewUser": "添加新用户",
  "widgets.workWeek": "工作周",
  "widgets.agenda": "议程",
  "widgets.conference": "会议",
  "widgets.multilevel": "多级",
  "widgets.dailySales": "每日销售",
  "widgets.today": "今天",
  "widgets.campaignPerformance": "广告系列效果",
  "widgets.supportRequest": "支持请求",
  "widgets.usersList": "用户列表",
  "widgets.lastWeek": "上个星期",
  "themeOptions.sidebarOverlay": "边栏覆盖",
  "themeOptions.sidebarBackgroundImages": "侧栏背景图片",
  "themeOptions.sidebarImage": "侧栏图像",
  "themeOptions.miniSidebar": "迷你边栏",
  "themeOptions.boxLayout": "箱子布局",
  "themeOptions.rtlLayout": "Rtl布局",
  "themeOptions.darkMode": "黑暗模式",
  "themeOptions.appSettings": "应用设置",
  "themeOptions.sidebarLight": "光",
  "themeOptions.sidebarDark": "黑暗",
  "button.cancel": "取消",
  "button.add": "加",
  "button.update": "更新",
  "button.reply": "回复",
  "button.delete": "删除",
  "button.yes": "是",
  "button.viewAll": "查看全部",
  "button.like": "喜欢",
  "button.assignNow": "立即分配",
  "button.seeInsights": "见见解",
  "sidebar.dateTimePicker": "日期和时间选择器",
  "components.summary": "概要",
  "hint.whatAreYouLookingFor": "后端你在找什么",
  "components.yesterday": "昨天",
  "components.last7Days": "过去7天",
  "components.last1Month": "最近1个月",
  "components.last6Month": "过去6个月",
  "components.spaceUsed": "使用的空间",
  "components.followers": "信徒",
  "components.trending": "趋势",
  "components.paid": "付费",
  "components.refunded": "退款",
  "components.done": "完成",
  "components.pending": "有待",
  "components.cancelled": "取消",
  "components.approve": "批准",
  "components.week": "周",
  "components.month": "月",
  "components.year": "年",
  "components.today": "今天",
  "components.popularity": "声望",
  "components.email": "电子邮件",
  "components.drafts": "草稿",
  "components.sent": "发送",
  "components.trash": "垃圾",
  "components.all": "所有",
  "components.do": "做",
  "components.title": "标题",
  "components.projectName": "项目名",
  "components.companyName": "公司名",
  "components.openAlert": "打开警报",
  "components.slideInAlertDialog": "在警报对话框中滑动",
  "components.openFullScreenDialog": "打开全屏对话框",
  "components.basicChip": "基本芯片",
  "components.clickableChip": "可点击芯片",
  "components.left": "剩下",
  "components.right": "对",
  "components.expansionPanel1": "扩展面板1",
  "components.expansionPanel2": "扩展面板2",
  "components.generalSetting": "通用设置",
  "components.advancedSettings": "高级设置",
  "components.firstName": "名字",
  "components.lastName": "姓",
  "components.occupation": "占用",
  "components.phoneNo": "电话号码",
  "components.address": "地址",
  "components.city": "市",
  "components.state": "州",
  "components.zipCode": "邮政编码",
  "components.social Connection": "社交联系",
  "widgets.buyMore": "买多点",
  "widgets.trafficChannel": "交通频道",
  "widgets.stockExchange": "股票交易",
  "widgets.tweets": "推文",
  "widgets.ourLocations": "我们的地点",
  "widgets.sales": "销售",
  "widgets.to": "至",
  "widgets.shipTo": "运送到",
  "widgets.description": "描述",
  "widgets.unitPrice": "单价",
  "widgets.total": "总",
  "widgets.note": "注意",
  "widgets.chipWithAvatar": "与具体化的芯片",
  "widgets.chipWithTextAvatar": "与文本具体化的芯片",
  "widgets.chipWithIconAvatar": "与象具体化的芯片",
  "widgets.customClickableChip": "自定义可点击芯片",
  "widgets.outlineChip": "大纲芯片",
  "widgets.disableChip": "禁用芯片",
  "widgets.alertDialog": "警报对话框",
  "widgets.animatedSlideDialogs": "动画幻灯片对话框",
  "widgets.fullScreenDialogs": "全屏对话框",
  "widgets.formDialogs": "表单对话框",
  "widgets.simpleDialogs": "简单的对话框",
  "widgets.responsiveFullScreen": "响应式全屏",
  "widgets.primary": "主",
  "widgets.social": "社会",
  "widgets.user": "用户",
  "widgets.admin": "管理员",
  "widgets.permanentdrawer": "永久性抽屉",
  "widgets.persistentdrawer": "持久的抽屉",
  "widgets.swiches": "Swiches",
  "widgets.horizontalLinearAlternativeLabel": "水平线性替代标签",
  "widgets.horizontalNonLinearAlternativeLabel": "水平非线性替代标签",
  "widgets.notifications": "通知",
  "widgets.basicAlert": "基本警报",
  "widgets.successAlert": "成功警报",
  "widgets.warningAlert": "警告警报",
  "widgets.reactAutoSuggest": "反应自动建议",
  "widgets.components": "组件",
  "widgets.inputAdornments": "输入",
  "widgets.multiSelectList": "多选列表",
  "widgets.contextualColoredTable": "同性色的桌子",
  "widgets.responsiveFilpTable": "响应翻动表",
  "widgets.updateYourEmailAddress": "更新电子邮件地址",
  "widgets.selectADefaultAddress": "选择一个默认地址",
  "widgets.activity": "活动",
  "widgets.basicCalendar": "基本日历",
  "widgets.culturesCalendar": "文化日历",
  "widgets.dragAndDropCalendar": "拖放日历",
  "widgets.quillEditor": "羽毛编辑器",
  "widgets.reactDND": "反应DND",
  "widgets.dragula": "Dragula",
  "button.acceptTerms": "接受条款",
  "button.reject": "拒绝",
  "button.addNew": "添新",
  "button.goToCampaign": "转到广告系列",
  "button.viewProfile": "查看资料",
  "button.sendMessage": "发信息",
  "button.saveNow": "现在保存",
  "button.pen": "钢笔",
  "button.search": "搜索",
  "button.downloadPdfReport": "下载PDF报告",
  "button.primary": "主",
  "button.secondary": "次要",
  "button.danger": "危险",
  "button.info": "信息",
  "button.success": "成功",
  "button.warning": "警告",
  "button.link": "Link",
  "button.smallButton": "小按钮",
  "button.largeButton": "大按钮",
  "button.blockLevelButton": "块级别按钮",
  "button.primaryButton": "主要按钮",
  "button.button": "按键",
  "button.save": "保存",
  "button.openMenu": "打开菜单",
  "button.openWithFadeTransition": "打开淡入淡出过渡",
  "button.openPopover": "打开酥料饼",
  "button.accept": "接受",
  "button.click": "点击",
  "button.complete": "完成",
  "button.back": "背部",
  "button.next": "下一个",
  "button.completeStep": "完成一步",
  "button.error": "错误",
  "button.writeNewMessage": "写新消息",
  "button.saveChanges": "保存更改",
  "button.addNewUser": "添加新用户",
  "button.more": "更多",
  "hint.searchMailList": "搜索邮件列表",
  "widgets.AcceptorrRejectWithin": "接受或拒绝",
  "widgets.quoteOfTheDay": "今日报价",
  "widgets.updated10Minago": "10分钟前更新",
  "widgets.personalSchedule": "个人时间表",
  "widgets.activeUsers": "活跃用户",
  "widgets.totalRequest": "总请求",
  "widgets.new": "新",
  "widgets.ShareWithFriends": "和朋友分享！",
  "widgets.helpToShareText":
    "通过与社交媒体上的朋友和追随者分享我们的网站，帮助我们传播世界！",
  "widgets.thisWeek": "本星期",
  "widgets.howWouldYouRateUs": "你如何评价我们？",
  "widgets.booking": "预订",
  "widgets.confirmed": "确认",
  "widgets.monthly": "每月一次",
  "widgets.weekly": "每周",
  "widgets.target": "目标",
  "widgets.totalActiveUsers": "总活跃用户数",
  "sidebar.user": "用户",
  "sidebar.miscellaneous": "杂",
  "sidebar.promo": "促销",
  "themeOptions.themeColor": "主题颜色",
  "module.inbox": "收件箱",
  "module.drafts": "草稿",
  "module.sent": "发送",
  "module.trash": "垃圾",
  "module.spam": "垃圾邮件",
  "module.frontend": "前端",
  "module.backend": "后端",
  "module.api": "Api",
  "module.issue": "问题",
  "components.emailPrefrences": "电子邮件偏好",
  "components.myProfile": "我的简历",
  "sidebar.gettingStarted": "入门",
  "widgets.deadline": "截止日期",
  "widgets.team": "球队",
  "widgets.projectManagement": "项目管理",
  "widgets.latestPost": "最新的帖子",
  "widgets.projectTaskManagement": "项目任务管理",
  "widgets.selectProject": "选择项目",
  "widgets.activityBoard": "活动委员会",
  "widgets.checklist": "清单",
  "sidebar.shop": "店",
  "sidebar.cart": "大车",
  "sidebar.checkout": "查看",
  "components.product": "产品",
  "components.quantity": "数量",
  "components.totalPrice": "总价格",
  "components.removeProduct": "删除产品",
  "components.mobileNumber": "手机号码",
  "components.address2Optional": "地址2（可选）",
  "components.country": "国家",
  "components.zip": "压缩",
  "components.saveContinue": "保存并继续",
  "components.placeOrder": "下订单",
  "components.payment": "付款",
  "components.billingAddress": "帐单地址",
  "components.ShippingAddressText": "送货地址与帐单地址相同。",
  "components.CartEmptyText": "你的购物车是空的！",
  "components.NoItemFound": "未找到任何项目",
  "components.goToShop": "去商店",
  "components.cardNumber": "卡号",
  "components.expiryDate": "到期日",
  "components.cvv": "CVV",
  "components.nameOnCard": "卡片上的名字",
  "components.confirmPayment": "确认付款",
  "sidebar.saas": "SAAS",
  "sidebar.multiLevel": "多级",
  "sidebar.level1": "1级",
  "sidebar.level2": "2级",
  "sidebar.boxed": "盒装",
  "sidebar.news": "新闻",
  "sidebar.extensions": "扩展",
  "sidebar.imageCropper": "图像裁剪器",
  "sidebar.videoPlayer": "视频播放器",
  "sidebar.dropzone": "拖放区",
  "widgets.baseConfig": "基本配置",
  "widgets.customControlBar": "自定义控制栏",
  "widgets.withDownloadButton": "使用下载按钮",
  "widgets.httpLiveStreaming": "HTTP直播",
  "widgets.keyboardShortcuts": "Keyboard Shortcuts",
  "button.useDefaultImage": "使用默认图像",
  "button.cropImage": "裁剪图像",
  "widgets.preview": "预习",
  "widgets.croppedImage": "裁剪图像",
  "sidebar.crm": "CRM",
  "widgets.transactionList": "交易清单",
  "widgets.transferReport": "转移报告",
  "widgets.expenseCategory": "费用类别",
  "widgets.upcomingEvents": "接下来的活动",
  "widgets.OngoingProjects": "正在进行的项目",
  "widgets.ProjectStatus": "项目状态",
  "widgets.LiveChatSupport": "实时聊天支持",
  "sidebar.projects": "项目",
  "sidebar.projectDetail": "项目细节",
  "sidebar.clients": "客户",
  "sidebar.reports": "报告",
};
