/**
 * Auth User Reducers
 */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  USER_DATA,
  USER_DAATA,
  IS_ADMIN,
  RELOAD_PAGE_ON_AUTH_CHANGE,
  SET_BALANCE_FAIL,
  SET_BALANCE_SUCCESS,
  SET_BALANCE,
} from "Store/Actions/types";
import { auth } from "../../Firebase";
import { waitSeconds } from "../../Util/dateHelpers";

/**
 * initial auth user
 */
const INIT_STATE = {
  user: {
    userId: sessionStorage.getItem("user_id"),
    userEmail: sessionStorage.getItem("user_email"),
  },
  loading: false,
  localUserData: auth.currentUser ? auth.currentUser : undefined,
  isAdmin: false,
  reloadPageOnAuthChange: true,
  userDaata: null,
  currentUser: null,
};

let authUser = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        userDaata: {...action?.userData} || {},
        currentUser: action.currentUser,
      };

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        isAdmin: false,
        localUserData: null,
        currentUser: null,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        isAdmin: false,
        localUserData: null,
        currentUser: null,
        userDaata: null,
        loading: false,
      };

    case SIGNUP_USER:
      return { ...state, loading: true };

    case SIGNUP_USER_SUCCESS:
      return { ...state, loading: false };

    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false, currentUser: null };

    case USER_DATA:
      return {
        ...state,
        loading: false,
        localUserData: action.payload,
        currentUser: action.currentUser,
      };
    case USER_DAATA:
      return { ...state, loading: false, userDaata: action.payload };

    case SET_BALANCE_SUCCESS:
      return {
        ...state,
        userDaata: { ...state.userDaata, balance: action.balance },
      };
    case IS_ADMIN:
      return { ...state, loading: false, isAdmin: action.payload };
    case RELOAD_PAGE_ON_AUTH_CHANGE:
      return {
        ...state,
        reloadPageOnAuthChange: action.payload,
      };

    default:
      return state;
  }
};

export default authUser;
