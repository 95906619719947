import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Grid, CircularProgress, Tooltip, styled } from "@material-ui/core";
import moment from "moment";
import { FormGroup as InputGroup, Label, Input as TimeInput } from "reactstrap";
import { Input as FormInput, Form, Feedback as FormFeedback } from "@availity/form";
import Select from "@availity/select";
import "react-rangeslider/lib/index.css";
import { getCategories } from "Store/Actions/createForm";
import { NotificationManager } from "react-notifications";
import "rc-time-picker/assets/index.css";
import * as yup from "yup";
import "./style.scss";
import AppConfig from "../../Constants/AppConfig";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { GoldfinchConfig } from "../../Helpers/helpers";
import Header from "./../../Components/NewHeader";
import { useParams } from "react-router-dom";
import { ADD_FRANCHISES } from "./../../Store/Actions/types";
import { db, storage } from "../../Firebase";
import { extension } from "mime-types";
import { getActivePackagesItemsDetails, getActiveFranchisePackages } from "Util/subscriptions";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import DayPicker from "./../../Components/DayPicker";
import { tooltipClasses } from "@mui/material";

const CreateDrench = () => {
  const formRef = useRef();
  const [openingHoursObj, setOpeningHoursObj] = useState({});

  let { type } = useParams();
  const [errors, setErrors] = useState({
    termsAndConditions: "",
    startDate: "",
    endDate: "",
    location: "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState();
  const [discountPercentage, setDiscountPercentage] = useState();
  const [WebsiteUrl, setWebsiteUrl] = useState();
  const [address, setAddress] = useState();
  const [contactText, setContactText] = useState();
  const [quantity, setQuantity] = useState();
  const [phone, setPhone] = useState();
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const [latitude, setLatitude] = useState(parseFloat(localUserData?.latitude) || AppConfig.defaultLat);
  const [longitude, setLongitude] = useState(parseFloat(localUserData?.longitude) || AppConfig.defaultLng);
  const [startDate, onStartDate] = useState(null);
  const [endDate, onEndDate] = useState(null);
  const [range, setRange] = useState(0.05);
  const [drenchCode, setDrenchCode] = useState();
  const [drenchTitle, setDrenchTitle] = useState();
  const [drenchSubTitle, setDrenchSubTitle] = useState();
  const [notificationMessage, setNotificationMessage] = useState();
  const [uploadedImages, setUploadedImages] = useState({
    image: null,
    success_image: null,
    logo: null,
  });
  const [activePackages, setActivePackages] = useState({});
  const [remainingSubscription, setRemainingSubscription] = useState({});
  const [selectedFranchises, setSelectedFranchises] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const dispatch = useDispatch();
  const categories = useSelector((state) => state.Drench.categories);
  const franchises = useSelector((state) => state.Franchise.franchises);
  const function_url = useSelector((state) => state.Cf.function_url);

  const validationSchema = yup.object({
    "title-name": yup.string().label("Title").max(20).required(),
    "description": yup.string().label("Description").max(120).required(),
    "discountPercentage": yup.number().max(100).min(1).label("Discount Percentage").required(),
    "categories": yup.array().min(1).required(),
    "franchises": yup.array().min(1).required(),
    "notificationMessage":
      type === "GoldFinch" ? yup.string().label("Notification Message").required().max(30).min(1) : null,
  });

  const initialValues = {
    "title-name": "",
    description,
    notificationMessage,
    contactText,
    quantity,
    discountPercentage,
    WebsiteUrl,
    "categories": [],
    "franchises": [],
  };

  const getFranchise = async (data) => {
    try {
      const snap = await db.collection("franchises").where("restaurantId", "==", localUserData?.uid).get();
      const data = snap.docs.map((doc) => doc.data());
      dispatch({
        type: ADD_FRANCHISES,
        payload: data,
      });
    } catch (e) {
      console.log(e, "ERROR");
    }
  };

  const getAddress = (addressProps, latProps, lngProps) => {
    if (latProps && lngProps) {
      setLatitude("");
      setLongitude("");
      setLatitude(latProps);
      setLongitude(lngProps);
    }
    setAddress(addressProps);
  };

  const onCreate = async (values) => {
    const anotherString2 = WebsiteUrl?.replace(/https:[-\/\\^$*+:?.()//|[\]/{}]/g, "");
    const replaceString = anotherString2?.replace("/", "");

    const franchisesData =
      values?.franchises?.map((v) => {
        return {
          franchiseId: v,
          geofenceId: franchises?.find((b) => b.franchiseId === v)?.geofenceId,
        };
      }) || [];

    if (
      !!(
        discountPercentage &&
        description !== "" &&
        (latitude || localUserData?.latitude) &&
        (longitude || localUserData?.longitude) &&
        title !== "" &&
        startDate &&
        endDate &&
        uploadedImages.image &&
        !errors.endDate &&
        !errors.startDate
      )
    ) {
      // setEditLoading(true);
      if (type === "GoldFinch") {
        if (activePackages?.campaign?.goldfinches <= 0) {
          return NotificationManager.error(`You do not have any goldfinches left in your package.`);
        }
        createDrench({
          companyName: localUserData?.companyName,
          lat: latitude || localUserData?.latitude,
          lng: longitude || localUserData?.longitude,
          address: address || "",
          contactText: localUserData?.phone,
          quantity: parseInt(quantity) || "UNLIMITED",
          dateCreated: new Date().toUTCString(),
          detailedAddress: address || "",
          drenchDescription: description || "",
          drenchTimeStart: new Date(startDate),
          drenchTimeEnd: new Date(endDate),
          phoneNumberText: localUserData?.phone,
          title: title || "",
          categoriesID: values?.categories || [],
          franchises: franchisesData,
          drenchOfTheDayTitle: drenchTitle || "",
          drenchOfTheDaySubTitle: drenchSubTitle || "",
          drenchCode: drenchCode || "",
          notificationMessage: notificationMessage || "",
          openingHours: openingHoursObj,
          // range: range ? parseInt(range * 1000) : "",
          discountPercentage,
          drenchUrl: replaceString ? `https://${replaceString}` : "",
          images: uploadedImages.image,
          type: `${type === "GoldFinch" ? "drenches" : "drizzles"}`,
          createdDate: +new Date(),
          geoFenceDistance: range,
          geoFenceId: "",
          geoFenceCampaignId: "",
          restaurantID: localUserData.uid,
          promotionalMessage: "",
          usersWhoHaveClaimed: "",
          currentStatus: "Active",
          restuarantData: localUserData,
          active: true,
          startTime,
          endTime,
          skipDays: selectedDay,
        });
      } else {
        if (activePackages?.campaign?.finches <= 0) {
          return NotificationManager.error(`You do not have any finches left in your package.`);
        }
        createDrizzle({
          companyName: localUserData?.companyName,
          lat: latitude || localUserData?.latitude,
          lng: longitude || localUserData?.longitude,
          address: address || "",
          contactText: localUserData?.phone,
          quantity: parseInt(quantity) || "UNLIMITED",
          dateCreated: new Date().toUTCString(),
          detailedAddress: address || "",
          drenchDescription: description || "",
          drenchTimeStart: new Date(startDate),
          drenchTimeEnd: new Date(endDate),
          phoneNumberText: localUserData?.phone,
          title: title || "",
          categoriesID: values?.categories || [],
          drenchUrl: replaceString ? `https://${replaceString}` : "",
          drenchOfTheDayTitle: drenchTitle || "",
          drenchOfTheDaySubTitle: drenchSubTitle || "",
          drenchCode: drenchCode || "",
          notificationMessage: notificationMessage || "",
          openingHours: openingHoursObj,
          franchises: franchisesData,
          // range: range ? parseInt(range * 1000) : "",
          discountPercentage,
          images: uploadedImages.image,
          type: `${type === "GoldFinch" ? "drenches" : "drizzles"}`,
          createdDate: +new Date(),
          geoFenceDistance: range,
          geoFenceId: "",
          geoFenceCampaignId: "",
          restaurantID: localUserData.uid,
          promotionalMessage: "",
          usersWhoHaveClaimed: "",
          currentStatus: "Active",
          active: true,
        });
      }
      // setEditLoading(false);
      //   toggle("");
    }
  };

  const createDrench = async (data) => {
    try {
      const ref = db.collection("drenches").doc();
      const id = ref.id;
      setEditLoading(true);
      const imageRef = storage.ref(`/goldfinch/${id}/background.${extension(data?.images?.type)}`);
      await imageRef.put(data.images);
      data["imageContentType"] = data?.images?.type;
      data.images = await imageRef.getDownloadURL();
      data.startDate = startDate;
      data.endDate = endDate;
      console.log(data, "createDrench");
      // setEditLoading(false);
      // return;
      const createGeoFence = await fetch(function_url + "/createGoldFinch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const createGeoFenceResult = await createGeoFence.json();
      if (createGeoFenceResult?.success) {
        NotificationManager.success(`GoldFinch Created Successfully`);
        formRef.current.resetForm();
        setEditLoading(false);
        setTitle("");
        setDescription("");
        setDiscountPercentage("");
        setQuantity("");
        onStartDate(null);
        onEndDate(null);
        setNotificationMessage("");
        setUploadedImages({
          image: null,
          success_image: null,
          logo: null,
        });
        const uploadContainer = document.querySelector(`.goldfinch-image-upload-container`);
        uploadContainer.style.backgroundImage = `none`;
        const data = await getActivePackagesItemsDetails(localUserData?.uid);
        setActivePackages(data);
      } else {
        setEditLoading(false);
      }
    } catch (e) {
      console.error(e, "add drench");
    }
  };

  const createDrizzle = async (data) => {
    try {
      const ref = db.collection("drenches").doc();
      const id = ref.id;
      setEditLoading(true);
      const imageRef = storage.ref(`/goldfinch/${id}/background.${extension(data?.images?.type)}`);
      await imageRef.put(data.images);
      data["imageContentType"] = data?.images?.type;
      data.images = await imageRef.getDownloadURL();
      await db
        .collection(`drenches`)
        .doc(id)
        .set({
          ...data,
          restaurantID: localUserData.uid,
          drenchKey: id,
          active: true,
          id: id,
          key: id,
        });
      formRef.current.resetForm();
      setEditLoading(false);
      setTitle("");
      setDescription("");
      setDiscountPercentage("");
      setQuantity("");
      onStartDate(null);
      onEndDate(null);
      setNotificationMessage("");
      NotificationManager.success(`Finch Created Successfully`);
      setEditLoading(false);

      setUploadedImages({
        image: null,
        success_image: null,
        logo: null,
      });
      const uploadContainer = document.querySelector(`.goldfinch-image-upload-container`);
      uploadContainer.style.backgroundImage = `none`;
      const activePackageData = await getActivePackagesItemsDetails(localUserData?.uid);
      setActivePackages(activePackageData);
    } catch (e) {
      console.error(e, "add drizzle");
      setEditLoading(false);
    }
  };

  const validateDate = () => {
    let endDateError = "";
    let startDateError = "";
    const current = moment();

    if (startDate && moment(startDate) < current) {
      startDateError = "Start date should be after current date";
    }
    if (endDate && moment(endDate) < current) {
      endDateError = "End date should be after current date";
    } else {
      if (startDate && endDate) {
        const diff = moment(endDate).diff(startDate, "hours");
        if (moment(endDate).isBefore(moment(startDate))) {
          endDateError = "End date should be after start date";
          NotificationManager.error("Invalid form data!");
        }
        if (diff > GoldfinchConfig.hours) {
          // endDateError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_hours_text}!`;
          endDateError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_duration_text}!`;
          NotificationManager.error("Invalid form data!");
        }
      }
    }

    setErrors({ ...errors, endDate: endDateError, startDate: startDateError });
  };

  const onSubmitClick = () => {
    const errorLabelThumbnail = document.querySelector(".upload-image-error-label");
    const errorLabelLogo = document.querySelector(".upload-logo-error-label");
    const errorLabelSuccessImage = document.querySelector(".upload-success_image-error-label");

    errorLabelThumbnail.textContent = "";
    // errorLabelLogo.textContent = "";
    // errorLabelSuccessImage.textContent = "";

    setErrors({
      ...errors,
      location: !latitude || !longitude ? "Please select location" : "",
      startDate: !startDate ? "Please select start date" : "",
      endDate: !endDate ? "Please select end date" : "",
    });

    if (
      !(
        discountPercentage &&
        description !== "" &&
        (latitude || localUserData?.latitude) &&
        (longitude || localUserData?.longitude) &&
        title !== "" &&
        startDate &&
        endDate &&
        uploadedImages.image
      )
    )
      NotificationManager.error("Please fill all field");
    if (!uploadedImages.image) errorLabelThumbnail.textContent = "Please upload image";

    if (startDate && endDate) validateDate();
    // if (!uploadedImages.logo) errorLabelLogo.textContent = "Please upload image";
    // if (!uploadedImages.success_image) errorLabelSuccessImage.textContent = "Please upload image";
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    const type = event.target.name;
    const MAX_SIZE = 2 * 1024 * 1024;
    const errorLabel = document.querySelector(`.upload-${type}-error-label`);
    const uploadContainer = document.querySelector(`.goldfinch-${type}-upload-container`);
    uploadContainer.classList.remove("uploaded");

    if (!image) return;

    errorLabel.textContent = "";
    if (image?.size > MAX_SIZE) return (errorLabel.textContent = "Image size should be lower than 2MB.");

    setUploadedImages({ ...uploadedImages, [type]: image });
    const url = window && window.URL.createObjectURL(image);
    uploadContainer.style.backgroundImage = `url(${url})`;
    uploadContainer.classList.add("uploaded");
  };

  const getStartEndDateDiff = () => {
    if (!(endDate && startDate)) return "";
    let momentEndDate = moment(endDate);
    let momentStartDate = moment(startDate);
    const diff = moment.duration(momentEndDate.diff(momentStartDate, "seconds"), "seconds");
    return diff.format("y [years], M [months], w [weeks], d [days], h [hours], m [minutes]", {
      trim: "all",
    });
  };

  useEffect(() => {
    if (localUserData?.uid) {
      getFranchise();
      getActivePackagesItemsDetails(localUserData?.uid).then((data) => {
        setActivePackages(data);
      });
    }
  }, [localUserData?.uid]);

  useEffect(() => {
    if (!latitude || !longitude) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    validateDate();
  }, [startDate, endDate]);

  useEffect(() => {
    if (localUserData?.companyName) setCompanyName(localUserData?.companyName);
    if (localUserData?.phone) setPhone(localUserData?.phone);
    if (localUserData?.uid) {
      getActiveFranchisePackages(localUserData?.uid).then((data) => {
        setRemainingSubscription(data);
      });
    }
  }, [localUserData]);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize:"12px"
    },
  }));

  return (
    <>
      <Header title={"Create " + type} />

      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(val) => onCreate(val)}
        style={{
          padding: 20,
        }}
        innerRef={formRef}
      >
        <div
          style={{
            // marginTop: 16,
            paddingBottom: "3rem",
            marginBottom: 24,
            position: "relative",
          }}
        >
          {/* <h2 style={{ fontWeight: 600 }}>Basic Info</h2> */}
          <Grid container spacing={2}>
            <Grid item md={4} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                {/* {type + " Title"}{" "} */}
                Name
                <BootstrapTooltip 
                  className="ml-10"
                  // title={`Name your ${type} simple and effective. We'll use it as your Discount title.`}
                  title={
                    "You can add your product name, service name, or any fancy title. We'll use this name as your GoldFinch title"
                  }
                  style={{marginLeft:10}}
                >
                  <InfoIcon fontSize="small" />
                </BootstrapTooltip>
              </Label>
              <InputGroup className="has-wrapper">
                <FormInput
                  type="text"
                  value={title}
                  name="title-name"
                  id="title-name"
                  className="has-input input-lg"
                  // placeholder={`Enter ${type} Title`}
                  placeholder={"Enter Name"}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <FormFeedback name="title-name" />
                <span className="has-icon"></span>
              </InputGroup>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                GoldFinch's Quantity
                <BootstrapTooltip
                  className="ml-10"
                  title={`You can restrict the number of Goldfinch's. For example, if you want only 10 users to save the Goldfinch's, then this discount card will automatically be turned off. Alternatively, if you leave it as the default feature "unlimited" then it will be turned off according to the end date.`}
                  style={{marginLeft:10}}
                >
                  <InfoIcon fontSize="small" />
                </BootstrapTooltip>
              </Label>
              <InputGroup className="has-wrapper">
                <FormInput
                  value={quantity}
                  type="number"
                  name="quantity"
                  id="quantity"
                  className="has-input input-lg"
                  placeholder="Unlimited"
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <FormFeedback name="quantity" />
                <span className="has-icon"></span>
              </InputGroup>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                Discount Percentage (%)
                <BootstrapTooltip
                  className="ml-10"
                  title="Enter the percentage value of the discount you wish to offer. For example, if you want to offer a 10% discount, enter '10' in this field."
                  style={{marginLeft:10}}
                >
                  <InfoIcon fontSize="small" />
                </BootstrapTooltip>
              </Label>
              <InputGroup className="has-wrapper">
                <FormInput
                  value={discountPercentage}
                  type="number"
                  name="discountPercentage"
                  id="description"
                  className="has-input input-lg"
                  placeholder={`eg: 10`}
                  onChange={(e) => setDiscountPercentage(e.target.value)}
                  fullWidth
                />
                <FormFeedback name="discountPercentage" />
                <span className="has-icon"></span>
              </InputGroup>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputGroup className="has-wrapper mui-datetime-pickers">
                <Label className="d-flex justify-content-start align-items-center">
                  {/* {type + " "} Launch Date & Time */}
                  Activation Date & Time
                  <BootstrapTooltip className="ml-10" title="Set the exact date and time when you want your offer to go live." style={{marginLeft:10}}>
                    <InfoIcon fontSize="small" />
                  </BootstrapTooltip>
                </Label>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  ampm={false}
                  placeholder="Select Date & Time"
                  value={startDate}
                  onChange={(value) => (moment(value).isValid() ? onStartDate(value) : onStartDate(null))}
                  disablePast
                  format="yyyy/MM/DD HH:mm"
                  fullWidth
                  name={"startDate"}
                />

                {errors.startDate && (
                  <small className={"mt-2"} style={{ color: "red" }}>
                    {errors.startDate}
                  </small>
                )}
              </InputGroup>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputGroup className="has-wrapper mui-datetime-pickers">
                <Label className="d-flex justify-content-start align-items-center">
                  {/* {type + " "} End Date & Time{" "} */}
                  End Date & Time
                  <BootstrapTooltip
                    className="ml-10"
                    title="Select the date and time when you want to exactly stop this campaign. According to the end date & time, your campaign will be deactivated automatically"
                    style={{marginLeft:10}}
                  >
                    <InfoIcon fontSize="small" />
                  </BootstrapTooltip>
                </Label>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  ampm={false}
                  value={endDate}
                  placeholder="Select Date & Time"
                  onChange={(value) => (moment(value).isValid() ? onEndDate(value) : onEndDate(null))}
                  disablePast
                  onError={console.log}
                  format="yyyy/MM/DD HH:mm"
                  fullWidth
                  name={"endDate"}
                  min={startDate ? moment(startDate).format("YYYY-MM-DDTHH:mm") : moment().format("YYYY-MM-DDTHH:mm")}
                />

                {errors.endDate && (
                  <small className={"mt-2"} style={{ color: "red" }}>
                    {errors.endDate}
                  </small>
                )}
              </InputGroup>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputGroup className="has-wrapper">
                <Label className="d-flex justify-content-start align-items-center">
                  {/* {type + " "} Duration{" "} */}
                  Campaign Duration
                  <BootstrapTooltip
                    className="ml-10"
                    title="The duration of your campaign will depend upon your selection of the start and end dates & times. Your campaign will run between the chosen dates & times, ensuring you reach your audience effectively."
                    style={{marginLeft:10}}
                  >
                    <InfoIcon fontSize="small" />
                  </BootstrapTooltip>
                </Label>
                <FormInput
                  style={{ width: "100%" }}
                  variant="outlined"
                  type="text"
                  disabled={true}
                  value={getStartEndDateDiff()}
                  name={"duration-readonly"}
                />
              </InputGroup>
            </Grid>
            {type === "GoldFinch" && (
              <>
                <Grid item md={4} sm={12} xs={12}>
                  <Label className="d-flex justify-content-start align-items-center">
                    Opening Time
                    <BootstrapTooltip
                      className="ml-10"
                      title={`Set the campaign's active hours by selecting the opening and closing times. For instance, if you choose an opening time of "7:00 AM" and a closing time of "11:00 PM," the campaign will be visible to users during this specific duration. And, all users will receive notifications only within the chosen timeframe.`}
                      style={{marginLeft:10}}
                    >
                      <InfoIcon fontSize="small" />
                    </BootstrapTooltip>
                  </Label>
                  <TimeInput
                    // type={"time"}
                    // value={startTime}
                    // showSecond={false}
                    // defaultValue={moment()}
                    // className="xxx"
                    // onChange={({ target: { value } }) => setStartTime(moment(value, "HH:mm"))}
                    // use12Hours={false}
                    // fullWidth
                    type="time"
                    name="timePicker"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Label className="d-flex justify-content-start align-items-center">
                    Closing Time
                    <BootstrapTooltip
                      className="ml-10"
                      title={`Set the campaign's active hours by selecting the opening and closing times. For instance, if you choose an opening time of "7:00 AM" and a closing time of "11:00 PM," the campaign will be visible to users during this specific duration. And, all users will receive notifications only within the chosen timeframe.`}
                      style={{marginLeft:10}}
                    >
                      <InfoIcon fontSize="small" />
                    </BootstrapTooltip>
                  </Label>
                  <TimeInput
                    type="time"
                    name="timePicker"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Label className="d-flex justify-content-start align-items-center">
                    Days to Skip
                    <BootstrapTooltip
                      className="ml-10"
                      title={`"Days to Skip" allows you to choose specific days when your campaign won't be active. For example, if your shop is closed on Sundays, you can simply select Sunday as a skip day. This way, your campaign will only run on the days you want, ensuring you reach your customers when it matters most.`}
                      style={{marginLeft:10}}
                    >
                      <InfoIcon fontSize="small" />
                    </BootstrapTooltip>
                  </Label>
                  <DayPicker selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
                </Grid>
              </>
            )}

            {type === "GoldFinch" && (
              <Grid item md={12} sm={12} xs={12}>
                <Label className="d-flex justify-content-start align-items-center">
                  Notification Message{" "}
                  <BootstrapTooltip
                    className="ml-10"
                    title="Customize your notification message to engage users and drive action."
                    style={{marginLeft:10}}
                  >
                    <InfoIcon fontSize="small" />
                  </BootstrapTooltip>
                </Label>
                <InputGroup className="has-wrapper">
                  <FormInput
                    value={notificationMessage}
                    type="text"
                    name="notificationMessage"
                    id="notificationMessage"
                    className="has-input input-lg"
                    placeholder="Enter Notification Message"
                    onChange={(e) => setNotificationMessage(e.target.value)}
                  />
                  <FormFeedback name="notificationMessage" />
                </InputGroup>
              </Grid>
            )}
            <Grid item md={12} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                Description{" "}
                <BootstrapTooltip
                  className="ml-10"
                  title="Enter a brief description to provide additional details about your campaign."
                  style={{marginLeft:10}}
                >
                  <InfoIcon fontSize="small" />
                </BootstrapTooltip>
              </Label>
              <InputGroup className="has-wrapper">
                <FormInput
                  style={{ height: 100 }}
                  value={description}
                  type="textarea"
                  name="description"
                  id="description"
                  className="has-input input-lg"
                  placeholder="Short Description for  your offer"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <FormFeedback name="description" />
              </InputGroup>
            </Grid>
          </Grid>
          <div className={"theme-line-breaker"} style={{ position: "absolute", left: 0, bottom: 0 }} />
        </div>
        <div
          style={{
            position: "relative",
            paddingBottom: "2rem",
            marginBottom: 16,
          }}
        >
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item md={4} sm={12} xs={12}>
              <Label className="d-flex justify-content-start align-items-center">
                {/* Upload Picture{" "} */}
                Upload Image
                <BootstrapTooltip className="ml-10" title="Upload an image to showcase your campaign or promotion" style={{marginLeft:10}}>
                  <InfoIcon fontSize="small" />
                </BootstrapTooltip>
              </Label>
              {/* <h2 style={{ fontWeight: 600 }}>Upload Picture </h2> */}
              <div
                className={"main-goldfinch-upload-container d-flex justify-content-stretch flex-wrap"}
                style={{ gap: 14, margin: "0px 0xp 24px 0" }}
              >
                <div>
                  <div className={"goldfinch-upload-container goldfinch-image-upload-container"}>
                    <InputGroup className="has-wrapper">
                      <Label for={"image-label"}>
                        <h2 className="goldfinch-image-upload-label">
                          Upload thumbnail image <i className="icon-cloud-upload" />
                        </h2>
                      </Label>
                      <input
                        style={{ display: "none" }}
                        type={"file"}
                        accept={"image/png, image/jpeg, image/jpg, image/gif, image/svg"}
                        onChange={handleImageChange}
                        id={"image-label"}
                        name={"image"}
                      />
                    </InputGroup>
                  </div>
                  <small style={{ color: "red" }} className={"upload-image-error-label"} />
                </div>
              </div>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <div className={"mt-25"}>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <InputGroup>
                      <Label className="d-flex justify-content-start align-items-center">
                        Select Categories{" "}
                        <BootstrapTooltip
                          className="ml-10"
                          title="Choose relevant categories that best represent your campaign or promotion. This will help users discover your deals more easily and ensure that your offers reach the right audience. You can choose a multiple categories as well"
                          style={{marginLeft:10}}
                        >
                          <InfoIcon fontSize="small" />
                        </BootstrapTooltip>
                      </Label>
                      <Select
                        className={"availity-form-select"}
                        name="categories"
                        isMulti={true}
                        options={categories?.map((data) => ({
                          value: data?.id,
                          label: data?.title,
                        }))}
                      />
                      <FormFeedback name="categories" style={{ display: "block" }} />
                    </InputGroup>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <InputGroup>
                      <Label className="d-flex justify-content-start align-items-center">
                        Select Franchises{" "}
                        <BootstrapTooltip
                          className="ml-10"
                          title="Select the franchises to which you want to apply this campaign or promotion. By choosing specific franchises, you can target your offers to reach a more tailored audience, maximizing the impact of your marketing efforts."
                          style={{marginLeft:10}}
                        >
                          <InfoIcon fontSize="small" />
                        </BootstrapTooltip>
                      </Label>
                      <Select
                        maxLength={remainingSubscription}
                        className={"availity-form-select"}
                        name="franchises"
                        placeholder="Select Franchises"
                        isMulti={true}
                        onChange={(val) => setSelectedFranchises([...val])}
                        options={franchises?.map((data) => ({
                          value: data?.franchiseId,
                          label: data?.name,
                        }))}
                      />
                      {remainingSubscription <= selectedFranchises.length &&
                      franchises.length > remainingSubscription ? (
                        <p style={{ color: "#3F83F8", display: "block", marginTop: 5 }}>
                          Your active franchise quantity is {remainingSubscription}, you can only select{" "}
                          {remainingSubscription} franchise.
                        </p>
                      ) : null}
                      <FormFeedback name="franchises" style={{ display: "block" }} />
                    </InputGroup>
                  </Grid>
                  {/* <Grid item md={12} sm={12} xs={12}>
                    <InputGroup>
                      <Label>Full Address</Label>
                      <Input disabled={true} value={address} />
                    </InputGroup>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
            {/* <div
              className={"theme-line-breaker"}
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              //   style={{ position: "absolute", left: 0, bottom: 0 }}
            /> */}
            {/* <Grid item md={12} sm={12} xs={12}>
              <InputGroup>
                <div
                  style={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    height: 300,
                    width: "100%",
                  }}
                >
                  {latitude && longitude && (
                    <GoogleMap
                      latitude={latitude}
                      longitude={longitude}
                      getAddress={getAddress}
                      range={range && parseInt(range * 1000)}
                    />
                  )}
                </div>
              </InputGroup>
            </Grid> */}
          </Grid>

          <div className={"theme-line-breaker"} style={{ position: "absolute", left: 0, bottom: 0 }} />
        </div>

        {/* <OpeningHours
          previousSavedData={localUserData}
          openingHoursObj={openingHoursObj}
          setOpeningHoursObj={setOpeningHoursObj}
          showAllDays={true}
        /> */}
        <Grid container>
          <Grid item xs={12} align="center">
            <InputGroup style={{ marginTop: "2rem" }} className="mb-12">
              <Button
                onClick={onSubmitClick}
                className="btn-dark text-white btn-block w-70"
                variant="contained"
                size="large"
                type={"submit"}
                disabled={editLoading}
              >
                {editLoading && <CircularProgress size={24} style={{ color: "#fff", marginRight: 10 }} />}
                Schedule
              </Button>
            </InputGroup>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
export default CreateDrench;
