import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, ListSubheader } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import IntlMessages from "Util/IntlMessages";
import NavMenuItem from "./NavMenuItem";
import { onToggleMenu } from "Store/Actions";

// Application Navigation Sidebar

function SidebarContent() {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);
  const userData = useSelector((state) => state.authUser.userDaata);
  const { sidebarMenus } = sidebar;

  const toggleMenu = (menu, stateCategory) => {
    let data = { menu, stateCategory };
    dispatch(onToggleMenu(data));
  };

  return (
    <div className="rct-sidebar-nav">
      <nav className="navigation">
        {userData?.role !== 'admin' && (
          <List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            subheader={
              <ListSubheader className="side-title" component="li">
                <IntlMessages id="sidebar.applications" />
              </ListSubheader>
            }
          >
            {sidebarMenus.partnerTabsForSidebar.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => toggleMenu(menu, "partnerTabsForSidebar")}
              />
            ))}
          </List>
        )}
        {userData?.role === 'admin' && (
          <List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            // subheader={<ListSubheader className="side-title" component="li"><IntlMessages id="sidebar.extensions" /></ListSubheader>}
          >
            {sidebarMenus.adminTabsForSidebar.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => toggleMenu(menu, "adminTabsForSidebar")}
              />
            ))}
          </List>
        )}
      </nav>
    </div>
  );
}

export default withRouter(SidebarContent);
