import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { FormGroup as RsFormGroup, InputGroup } from "reactstrap";
import {
  Form,
  Input,
  Label,
  FormGroup,
  Feedback as FormFeedback,
} from "@availity/form";
import * as yup from "yup";
import "firebase/storage";
import { Grid } from "@material-ui/core";

function ViewCategoryForm(props) {
  const [initialValues, setInitialValues] = useState({
    title: props?.viewCategoryData?.title || "",
    bckcolor: props?.viewCategoryData?.bckcolor || "",
    description: props?.viewCategoryData?.description || "",
  });

  const validationSchema = yup.object({});

  useEffect(() => {
    /*if (props?.viewCategoryData?.imageUrl)
      document.querySelector(
        ".category-image-view-container"
      ).style.backgroundImage = `url(${props?.viewCategoryData?.imageUrl})`;*/
  }, [props?.viewCategoryData]);

  return (
    <div>
      <Form
        className=""
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          props.toggleViewCategoryModal(false);
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <FormGroup className="has-wrapper text-left" for="title">
              <Label for="title">Category Name</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  className="has-input input-lg"
                  placeholder="Category Name"
                  feedback={true}
                  disabled={true}
                />
                <FormFeedback name="title" />
              </InputGroup>
            </FormGroup>
          </Grid>
          {/*<Grid item md={12} sm={12} xs={12}>
            <Label>Category Image</Label>
            <div
              className={"d-flex justify-content-center align-items-center category-image-view-container"}
              style={{ height: 250, borderRadius: 8, backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
            >
              {!props?.viewCategoryData?.imageUrl ? (
                <h2>Category Logo was not found.</h2>
              ) : null}
            </div>
          </Grid>*/}
        </Grid>

        {/*<FormGroup className="has-wrapper text-left" for="description">*/}
        {/*  <Label for="description">Category Description</Label>*/}
        {/*  <InputGroup>*/}
        {/*    <Input*/}
        {/*      type="text"*/}
        {/*      name="description"*/}
        {/*      id="description"*/}
        {/*      className="has-input input-lg"*/}
        {/*      placeholder="Category Description"*/}
        {/*      feedback={true}*/}
        {/*      disabled={true}*/}
        {/*    />*/}
        {/*    <FormFeedback name="description" />*/}
        {/*  </InputGroup>*/}
        {/*</FormGroup>*/}
        {/*<FormGroup className="has-wrapper text-left" for="bckcolor">*/}
        {/*  <Label for="bckcolor">Category Back Color Hash</Label>*/}
        {/*  <InputGroup>*/}
        {/*    <Input*/}
        {/*      type="text"*/}
        {/*      name="bckcolor"*/}
        {/*      id="bckcolor"*/}
        {/*      className="has-input input-lg"*/}
        {/*      placeholder="#FFFFFF"*/}
        {/*      feedback={true}*/}
        {/*      disabled={true}*/}
        {/*    />*/}
        {/*    <FormFeedback name="bckcolor" />*/}
        {/*  </InputGroup>*/}
        {/*</FormGroup>*/}
        <RsFormGroup className="mb-15 mt-4">
          <Button
            className="btn-dark text-white btn-block w-100"
            variant="contained"
            size="large"
            onClick={() => {
              props.toggleViewCategoryModal(false);
            }}
          >
            Close
          </Button>
        </RsFormGroup>
      </Form>
    </div>
  );
}

export default ViewCategoryForm;
