import moment from "moment";

const calculateTimeLeft = (endingTime) => {
  const currentTime = moment();
  endingTime = moment(endingTime);
  const diff = endingTime.diff(currentTime);
  return moment.duration(diff);
};
const calculateTimeSpent = (startTime) => {
  const currentTime = moment();
  startTime = moment(startTime);
  const diff = currentTime.diff(startTime);
  return moment.duration(diff);
};

const waitSeconds = (seconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
};

export { waitSeconds, calculateTimeLeft, calculateTimeSpent };
