import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import AddCategoryButton from "./AddCategoryButton";
import AddCategoryForm from "./AddCategoryForm";
import EditCategoryForm from "./EditCategoryForm";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import _ from "underscore";
import ViewCategoryForm from "./ViewCategoryForm";
import ViewIcon from "../../Assets/img/view.svg";
import EditIcon from "../../Assets/img/pen.svg";
import { database, db } from "../../Firebase";
import Header from "./../../Components/NewHeader";
const CategoryItemActionMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  async function changeCategoryStatus(status, rowData) {
    if (rowData?.id) {
      // await database.ref(`category/${rowData?.id}`).update({ status: status });

      await db.collection("category").doc(rowData?.id).update({
        status: status,
      });
      props.refresh();
    }

    NotificationManager.success(`Status changed to ${status} Successfully`);
  }

  return (
    <React.Fragment>
      <IconButton
        style={{ marginLeft: 10 }}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        elevation={0}
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          className={`text-capitalize`}
          onClick={() => {
            setAnchorEl(null);
            changeCategoryStatus("active", props?.rowData, props?.tableData);
          }}
          disabled={(props?.rowData?.status || "active") === "active"}
        >
          Active
        </MenuItem>
        <MenuItem
          className={`text-capitalize`}
          onClick={() => {
            setAnchorEl(null);
            changeCategoryStatus("inactive", props?.rowData, props?.tableData);
          }}
          disabled={(props?.rowData?.status || "active") === "inactive"}
        >
          Deactivate
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const CategoriesList = () => {
  const [categories, setCategories] = useState(null);
  const uid = useSelector((state) => state?.authUser?.localUserData?.uid || "");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editCategoryData, setEditCategoryData] = useState(null);
  const [viewCategoryData, setViewCategoryData] = useState(null);

  useEffect(() => {
    getData();
  }, [addModal, editModal]);

  const toggleAddCategoryModal = (trueOrFalse = null) => {
    if (trueOrFalse === true) {
      setAddModal(true);
    } else if (trueOrFalse === false) {
      setAddModal(false);
    } else {
      setAddModal(!addModal);
    }
  };

  const toggleEditCategoryModal = (trueOrFalse = null) => {
    if (trueOrFalse === true) {
      setEditModal(true);
    } else if (trueOrFalse === false) {
      setEditModal(false);
    } else {
      setEditModal(!editModal);
    }
  };

  const toggleViewCategoryModal = (trueOrFalse = null) => {
    if (trueOrFalse === true) {
      setViewModal(true);
    } else if (trueOrFalse === false) {
      setViewModal(false);
    } else {
      setViewModal(!viewModal);
    }
  };

  const AddModalFunc = () => {
    return (
      <div>
        <Modal
          isOpen={addModal}
          toggle={toggleAddCategoryModal}
          className="custom-modal-style-drench"
        >
          <ModalHeader
            toggle={toggleAddCategoryModal}
            style={{ position: "relative" }}
          >
            <h2>Create Category</h2>
            <div
              className={"theme-line-breaker"}
              style={{ position: "absolute", bottom: 0, left: 0 }}
            />
          </ModalHeader>
          <ModalBody className="custom-modal-style-drench">
            <AddCategoryForm toggleAddCategoryModal={toggleAddCategoryModal} />
          </ModalBody>
          <ModalFooter />
        </Modal>
      </div>
    );
  };

  const EditModalFunc = () => {
    return (
      <div>
        <Modal
          isOpen={editModal}
          toggle={toggleEditCategoryModal}
          className="custom-modal-style-drench"
        >
          <ModalHeader
            toggle={toggleEditCategoryModal}
            style={{ position: "relative" }}
          >
            <h2>Edit Category</h2>
            <div
              className={"theme-line-breaker"}
              style={{ position: "absolute", bottom: 0, left: 0 }}
            />
          </ModalHeader>
          <ModalBody className="custom-modal-style-drench">
            <EditCategoryForm
              toggleEditCategoryModal={toggleEditCategoryModal}
              editCategoryData={editCategoryData}
            />
          </ModalBody>
          <ModalFooter />
        </Modal>
      </div>
    );
  };

  const ViewModalFunc = () => {
    return (
      <div>
        <Modal
          isOpen={viewModal}
          toggle={toggleViewCategoryModal}
          className="custom-modal-style-drench"
        >
          <ModalHeader
            toggle={toggleViewCategoryModal}
            style={{ position: "relative" }}
          >
            <h2>View Category</h2>
            <div
              className={"theme-line-breaker"}
              style={{ position: "absolute", bottom: 0, left: 0 }}
            />
          </ModalHeader>
          <ModalBody className="custom-modal-style-drench">
            <ViewCategoryForm
              toggleViewCategoryModal={toggleViewCategoryModal}
              viewCategoryData={viewCategoryData}
            />
          </ModalBody>
          <ModalFooter />
        </Modal>
      </div>
    );
  };

  const viewData = (rowData) => {
    setViewCategoryData(rowData);
    toggleViewCategoryModal();
  };

  const editData = (rowData) => {
    setEditCategoryData(rowData);
    toggleEditCategoryModal();
  };

  const getData = async () => {
    // const snap = await db
    // .collection("restaurants")
    // .where("role", "==", "merchant")
    // .get();
    const snap = await db.collection(`category`).get();
    const data = snap.docs.map((doc) => doc.data());

    setCategories(data);
  };

  const columns = [
    {
      name: "title",
      label: "Category Name",
    },
    {
      name: "status",
      label: "Status",
      options: {
        viewColumns: false,
        filter: false,
        setCellProps: () => ({ style: { width: "15%" } }),
        customBodyRender: (value, tableMeta) => {
          let tableData = categories;
          let currentRow = tableMeta?.currentTableData[tableMeta?.rowIndex];
          let rowData = tableData[currentRow?.index];
          return (
            <div>
              <span
                className={`badge ${
                  (rowData?.status || "active") === "active"
                    ? "badge-success"
                    : "badge-danger"
                } text-capitalize py-2`}
              >
                <b style={{ fontSize: 14 }}>{rowData?.status || "active"}</b>
              </span>
            </div>
          );
        },
      },
    },
    /*{
      name: "imageUrl",
      label: "Image",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value) => {
          return value === "" ? (
            <></>
          ) : (
            <img
              src={value}
              width={50}
              style={{ objectFit: "cover" }}
              alt={"category image"}
            />
          );
        },
      },
    },*/
    /*{
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          let tableData = categories;
          let currentRow = tableMeta?.currentTableData[tableMeta?.rowIndex];
          let rowData = tableData[currentRow?.index];
          return (
            <div
              className={"d-flex justify-content-start align-items-center"}
              style={{ cursor: "pointer" }}
            >
              <img
                src={ViewIcon}
                alt={"view"}
                className={"mr-4"}
                onClick={() => {
                  viewData(rowData, tableData);
                }}
              />
              <img
                src={EditIcon}
                alt={"edit"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  editData(rowData, tableData);
                }}
              />
            </div>
          );
        },
      },
    },*/
    {
      name: "Activate/Deactivate",
      label: "Action",
      options: {
        viewColumns: false,
        filter: false,
        setCellProps: () => ({ style: { width: "15%" } }),
        customBodyRender: (value, tableMeta) => {
          let tableData = categories;
          let currentRow = tableMeta?.currentTableData[tableMeta?.rowIndex];
          let rowData = tableData[currentRow?.index];
          return (
            <div
              className={"d-flex justify-content-start align-items-center"}
              style={{ cursor: "pointer" }}
            >
              {/* <img
                src={ViewIcon}
                alt={"view"}
                className={"mr-4"}
                onClick={() => {
                  viewData(rowData, tableData);
                }}
              /> */}
              <img
                src={EditIcon}
                alt={"edit"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  editData(rowData, tableData);
                }}
              />
              <CategoryItemActionMenu
                rowIndex={currentRow?.index}
                rowData={rowData}
                tableData={tableData}
                editData={editData}
                viewData={viewData}
                refresh={getData}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPage: 10,
    sortOrder: {
      name: "title",
      direction: "asc",
    },
    customToolbar: () => {
      return <AddCategoryButton addCategoryModal={toggleAddCategoryModal} />;
    },

    download: false,
    print: false,
    filter: false,
    search: true,
    viewColumns: false,
  };

  return (
    <>
      <Header title="Categories" onRefresh={getData} />
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <MUIDataTable
          // title={
          //   <h1 style={{ color: "#242424" }} className={"font-weight-bold"}>
          //     Category List
          //   </h1>
          // }
          data={categories ? categories : []}
          columns={columns}
          options={options}
        />
        {AddModalFunc()}
        {ViewModalFunc()}
        {EditModalFunc()}
      </div>
    </>
  );
};

export default CategoriesList;
