import React from "react";
import { Paper, Typography, Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
const ChartContainer = ({ title, children, isSmall }) => {
  return (
    <>
      <Typography
        variant="body1"
        component={"p"}
        style={{ fontSize: 16, fontWeight: 500, color: "#222" }}
      >
        {title}
      </Typography>
      <Paper
        style={{
          padding: 5,
          backgroundColor: "#F3F3F3",
          marginTop: 20,
          paddingTop: isSmall ? 50 : 0,
          paddingBottom: isSmall ? 50 : 0,
        }}
      >
        <div
          style={
            isSmall
              ? { paddingLeft: 10, paddingRight: 10 }
              : { paddingLeft: "10%", paddingRight: "10%" }
          }
        >
          {children}
        </div>
        {/* <Button
          variant="link"
          style={{ color: "#3975C2", marginTop: 15, marginBottom: 5 }}
          endIcon={<ChevronRightIcon />}
        >
          More Insights
        </Button> */}
      </Paper>
    </>
  );
};
export default ChartContainer;
