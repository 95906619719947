import appLocaleData from "react-intl/locale-data/it";
import saMessages from "../locales/it_IT";

const itLang = {
  messages: {
    ...saMessages,
  },
  locale: "it-it",
  data: appLocaleData,
};

export default itLang;
