import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "firebase/auth";
import "firebase/firestore";
import { differenceInMinutes } from "date-fns";
import {
  getDrenchAction,
  deleteDrenchAction,
  activeDrenchActive,
  getAdminAssignAction,
} from "Store/Actions/createForm";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import BinIcon from "../../Assets/img/bin.svg";
import PenIcon from "../../Assets/img/pen.svg";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
} from "@material-ui/core";
import RctCollapsibleCard from "../../Components/RctCollapsibleCard/RctCollapsibleCard";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { PLAN_ADVANCE, PLAN_PREMIUM, PLAN_STARTER } from "../../const";
import Countdown from "react-countdown";
import Header from "./../../Components/NewHeader";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function GoldfinchesSavedByUsers(props) {
  const classes = useStyles();
  const [createType, setCreateType] = useState("");
  const [drenchList, setDrenchList] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  const deleteDrench = (key, type) => {
    dispatch(
      deleteDrenchAction(
        key,
        type === undefined || type === "drenches" ? "drenches" : "drizzles"
      )
    );
  };
  const dispatch = useDispatch();
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const drenches = useSelector((state) => state.Drench.drenches);
  const subscription = useSelector((state) => state.Drench.subscription);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [, setLatitude] = useState(null);
  const [, setLongitude] = useState(null);
  const [singleDrenchData, setSingleDrenchData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({ id: null, type: null });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    navigator.geolocation.watchPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
    getData();
  }, []);

  const getData = () => {
    dispatch(getDrenchAction());
    dispatch(getAdminAssignAction());
  };

  useEffect(() => {
    if (drenchList.length) {
      setDrenchList(
        drenchList.map((item) => {
          if (item.createdDate) {
            if (differenceInMinutes(new Date(), item.createdDate) >= 1) {
              return { ...item, active: false, disabled: true };
            }
          }
          return item;
        })
      );
    }
  }, [drenches]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Timer Finished</span>;
    } else {
      return (
        <span>
          {days ? days + " days, " : ""} {hours ? hours + " hours, " : ""}{" "}
          {minutes ? minutes + " minutes, " : ""} {seconds + " seconds"}{" "}
        </span>
      );
    }
  };

  const columns = [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "savedFinches",
      label: "Saved By Users",
      options: {
        setCellProps: () => ({
          style: { minWidth: "100px!important", maxWidth: "120px!important" },
        }),
      },
    },
    {
      name: "nullValue",
      label: "Current Status",
      options: {
        customBodyRender: (_, tableMeta) => {
          const goldfinch = drenches[tableMeta?.rowIndex];
          const value = drenches[tableMeta?.rowIndex].currentStatus;
          let backgroundColor,
            fontSize = 14;
          if (!goldfinch?.active) {
            backgroundColor = "gray";
          } else if (value === "Claimed All") {
            backgroundColor = "#1B5E20";
          } else if (value === "Awaiting") {
            backgroundColor = "#11A4C2";
          } else if (value === "Dying Up") {
            backgroundColor = "#4950BA";
            fontSize = 14;
          } else if (value === "Active") {
            backgroundColor = "#1CBC3C";
          } else {
            backgroundColor = "#DC2845";
          }

          return (
            <div
              style={{
                padding: "4px 6px",
                backgroundColor,
                borderRadius: 6,
                color: "#fff",
                textAlign: "center",
                fontWeight: 500,
                fontSize,
                minWidth: "250px",
                maxWidth: "fit-content",
              }}
            >
              {value === "Active" ? (
                <>
                  <span>Active: </span>
                  <Countdown
                    renderer={renderer}
                    date={moment(
                      goldfinch?.drenchTimeEnd?.seconds * 1000
                    ).toDate()}
                  />
                </>
              ) : value === "Awaiting" ? (
                <>
                  <span>Awaiting: </span>
                  <Countdown
                    renderer={renderer}
                    date={moment(
                      goldfinch?.drenchTimeStart?.seconds * 1000
                    ).toDate()}
                  />
                </>
              ) : (
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              padding: "4px 14px",
              backgroundColor: value === "finch" ? "#11A4C2" : "#e5b11e",
              borderRadius: 6,
              color: "#fff",
              textAlign: "center",
              fontWeight: 500,
              minWidth: "100px",
              maxWidth: "fit-content",
            }}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </div>
        ),
      },
    },
    // {
    //   name: "currentStatus",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const goldfinch = drenches[tableMeta?.rowIndex];
    //       let backgroundColor,
    //         fontSize = 14;
    //       if (!goldfinch?.active) {
    //         backgroundColor = "gray";
    //       } else if (value === "Claimed All") {
    //         backgroundColor = "#1B5E20";
    //       } else if (value === "Awaiting") {
    //         backgroundColor = "#11A4C2";
    //       } else if (value === "Dying Up") {
    //         backgroundColor = "#4950BA";
    //         fontSize = 14;
    //       } else if (value === "Active") {
    //         backgroundColor = "#1CBC3C";
    //       } else {
    //         backgroundColor = "#DC2845";
    //       }
    //
    //       return (
    //         <div
    //           style={{
    //             padding: "4px 6px",
    //             backgroundColor,
    //             borderRadius: 6,
    //             color: "#fff",
    //             textAlign: "center",
    //             fontWeight: 500,
    //             fontSize,
    //           }}
    //         >
    //           {value}
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  return (
    <>
      <Header title="Campaigns Saved By Users" onRefresh={getData} />
      <div className="video-player-wrapper">
        <Backdrop
          className={classes.backdrop}
          open={editLoading}
          onClick={() => setEditLoading(!editLoading)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="row">
          <RctCollapsibleCard colClasses="col-sm-12 col-md-12 col-lg-12">
            <MUIDataTable
              data={(drenches || []).map((d) => ({
                ...d,
                savedFinches: d?.usersWhoHaveClaimed
                  ? d?.usersWhoHaveClaimed.length
                  : 0,
              }))}
              // title={
              //   <h1 className={"font-weight-bold"} style={{ color: "#242424" }}>
              //     Finches Saved By User’s
              //   </h1>
              // }
              columns={columns}
              options={{
                selectableRows: false,
                elevation: 0,
                download: false,
                print: false,
                filter: false,
                search: false,
                viewColumns: false,
              }}
            />
          </RctCollapsibleCard>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">
            Delete {deleteData.type === "drizzles" ? "finch" : "Goldfinch"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this{" "}
              {deleteData.type === "drizzles" ? "finch" : "Goldfinch"}?<br />{" "}
              This action can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              No
            </Button>
            <Button
              onClick={async () => {
                await deleteDrench(deleteData?.id, deleteData.type);
                setDeleteData({ id: null, type: null });
                handleClose();
              }}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default GoldfinchesSavedByUsers;
