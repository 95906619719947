import { auth, database, storage, db } from "../../Firebase/index";

import { NotificationManager } from "react-notifications";
import moment from "moment";
import _ from "underscore";
import {
  ACTIVE_TIER,
  ACTIVE_TIER_FAIL,
  CREATE_DRENCH,
  EDIT_DRENCH,
  GET_ANALYTICS,
  GET_ANALYTICS_FAIL,
  GET_ANALYTICS_FAIL_MERCENT,
  GET_ANALYTICS_MERCENT,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_SUCCESS_MERCENT,
  GET_ASSIGN_DRENCH,
  GET_ASSIGN_DRENCH_ADMIN,
  GET_ASSIGN_DRENCH_FAIL,
  GET_ASSIGN_DRENCH_FAIL_ADMIN,
  GET_ASSIGN_DRENCH_SUCCESS_ADMIN,
  GET_CATEGORIES,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_SUCCESS,
  GET_DRENCH,
  GET_DRENCH_FAILURE,
  GET_DRENCH_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  SET_BALANCE,
  SET_BALANCE_FAIL,
  SET_BALANCE_SUCCESS,
} from "Store/Actions/types";
import { PLAN_ADVANCE, PLAN_PREMIUM, PLAN_STARTER } from "../../const";
import { extension } from "mime-types";
import {
  calculateTimeLeft,
  calculateTimeSpent,
  waitSeconds,
} from "../../Util/dateHelpers";
import { store } from "./../index";
// const _plotProjectsAPIUrl =
//   "https://corsfordrench.herokuapp.com/https://admin.plotprojects.com/api/v1";
// const _plotProjectsAppId = "2ca887d0f3d8414ebf58970679185aa7";
// const _plotProjectsPrivateToken = "Z824g8PzbnaTzFEW";

const createStatus = (goldfinch) => {
  const timeSpent = calculateTimeSpent(
    new Date(goldfinch?.drenchTimeStart?.seconds * 1000)
  );
  const timeLeft = calculateTimeLeft(goldfinch?.drenchTimeEnd?.seconds * 1000);
  // console.log(timeSpent, "TIME_SPENT");
  const drenchQuantity = goldfinch?.quantity || 0;
  const drenchQuantityNum = parseInt(drenchQuantity) || 0;
  const totalClaimedLength = goldfinch?.usersWhoHaveClaimed?.length || 0;

  let text;
  if (!goldfinch?.active) {
    text = "Disabled";
  } else if (
    !(
      drenchQuantity === "UNLIMITED" ||
      drenchQuantity === "" ||
      drenchQuantityNum > totalClaimedLength
    )
  ) {
    //Claimed Qty Exceeded
    text = "Claimed All";
  } else if (timeSpent.asMilliseconds() < 0) {
    text = "Awaiting";
  } else if (timeLeft.asMilliseconds() > 0 && timeLeft.asHours() < 5) {
    text = "Dying Up";
  } else if (timeLeft.asMilliseconds() > 0) {
    text = "Active";
  } else {
    text = "Expired";
  }
  return text;
};

export const createDrenchAction = (data) => async (dispatch) => {
  dispatch({ type: CREATE_DRENCH });
  const userLocal = auth.currentUser ? auth.currentUser : undefined;
  try {
    if (userLocal) {
      const ref = db.collection("drenches").doc();
      const id = ref.id;
      const function_url = store?.getState()?.Cf?.function_url || "";
      const imageRef = storage.ref(
        `/goldfinch/${id}/background.${extension(data?.images?.type)}`
      );
      await imageRef.put(data.images);
      data["imageContentType"] = data?.images?.type;
      data.images = await imageRef.getDownloadURL();

      const createGeoFence = await fetch(function_url + "/createGoldFinch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const createGeoFenceResult = await createGeoFence.json();
      console.log(createGeoFenceResult, "createGeoFenceResult");
      if (createGeoFenceResult?.success) {
        console.log(createGeoFenceResult, "createGeoFenceResult?.success");
        NotificationManager.success(`Goldfinch Created Successfully`);
      }
    } else {
      console.log("NO_USER_LOGGED_IN");
    }
  } catch (e) {
    console.error(e, "add drench");
  }
};

export const createDrizzleAction = (data) => async (dispatch) => {
  dispatch({ type: CREATE_DRENCH });
  const userLocal = auth.currentUser ? auth.currentUser : undefined;
  try {
    if (userLocal) {
      const ref = db.collection("drenches").doc();
      const id = ref.id;
      const imageRef = storage.ref(
        `/goldfinch/${id}/background.${extension(data?.images?.type)}`
      );
      await imageRef.put(data.images);
      data["imageContentType"] = data?.images?.type;
      data.images = await imageRef.getDownloadURL();
      console.log({
        ...data,
        restaurantID: userLocal.uid,
        drenchKey: id,
        active: true,
        id: id,
        key: id,
      });
      await db
        .collection(`drenches`)
        .doc(id)
        .set({
          ...data,
          restaurantID: userLocal.uid,
          drenchKey: id,
          active: true,
          id: id,
          key: id,
        });
      NotificationManager.success(`Finch Created Successfully`);
    }
  } catch (e) {
    console.error(e, "add drizzle");
  }
};

// const _deleteGeoFence = (geoFenceId) => {
//   if (geoFenceId !== undefined && geoFenceId !== null && geoFenceId !== "") {
//     _processPlotProjectsRequest(
//       "DELETE",
//       _plotProjectsAPIUrl + "/geofence/" + geoFenceId,
//       null,
//       (data) => {
//       },
//     );
//   }
// };
// const _processPlotProjectsRequest = (method, url, data, callback) => {
//   axios({
//     method: method,
//     url: url,
//     data: data,
//     auth: {
//       username: _plotProjectsAppId,
//       password: _plotProjectsPrivateToken,
//     },
//     headers: {
//       "Content-Type": "application/json",
//       "Accept": "application/json",
//     },
//   })
//     .then((response) => {
//       callback(response.data);
//     })
//     .catch((error) => {
//       callback(null);
//     });
// };
// const _addGeoFence = (title, longitude, latitude, range, callback) => {
//   const data = {
//     name: title,
//     location: {
//       longitude: longitude,
//       latitude: latitude,
//     },
//     label: title,
//     matchRange: range,
//   };
//   //console.log("response _addGeoFence", data);
//   _processPlotProjectsRequest(
//     "POST",
//     _plotProjectsAPIUrl + "/geofence",
//     data,
//     (data) => {
//       if (data === null) {
//         callback("");
//       } else {
//         if (data.success) {
//           //console.log(data.success, "response data.success");
//           callback(data.result);
//         } else {
//           callback("");
//         }
//       }
//     },
//   );
// };

// const _updateGeoFence = (
//   title,
//   longitude,
//   latitude,
//   range,
//   geoFenceId,
//   callback,
// ) => {
//   const data = {
//     name: title,
//     location: {
//       longitude: longitude,
//       latitude: latitude,
//     },
//     label: title,
//     matchRange: range,
//   };
//   //console.log(data, "_updateGeoFence");
//   _processPlotProjectsRequest(
//     "PATCH",
//     _plotProjectsAPIUrl + "/geofence/" + geoFenceId,
//     data,
//     (data) => {
//       if (data === null) {
//         callback(false);
//       } else {
//         callback(data.success);
//       }
//     },
//   );
// };

// const _updateGeoFenceCampaign = (
//   title,
//   message,
//   drenchId,
//   startDate,
//   endDate,
//   geoFenceId,
//   cooldownSeconds,
//   dailyTimingsInfo,
//   campaignId,
//   callback,
// ) => {
//
//   const data = {
//     name: title,
//     message: message,
//     enabled: true,
//     data: `{"drenchId": "${drenchId}"}`,
//     regionIds: [geoFenceId],
//     trigger: "enter",
//     oncePerLocation: false,
//     startDate,
//     endDate,
//     cooldownSeconds: cooldownSeconds,
//     openingHours: dailyTimingsInfo,
//   };
//
//
//   _processPlotProjectsRequest(
//     "PATCH",
//     _plotProjectsAPIUrl + "/campaign/" + campaignId,
//     data,
//     (data) => {
//       if (data === null) {
//         callback(false);
//       } else {
//         callback(data.success);
//       }
//     },
//   );
// };

// const _addGeoFenceCampaign = (
//   title,
//   message,
//   drenchId,
//   startDate,
//   endDate,
//   geoFenceId,
//   dailyTimingsInfo,
//   callback,
// ) => {
//
//   const data = {
//     name: title,
//     message: message,
//     enabled: true,
//     data: `{"drenchId": "${drenchId}"}`,
//     regionIds: [geoFenceId],
//     trigger: "enter",
//     oncePerLocation: false,
//     startDate,
//     endDate,
//     openingHours: dailyTimingsInfo,
//     cooldownSeconds: 0,
//   };
//   //console.log("response _addGeoFenceCampaign", data);
//
//   _processPlotProjectsRequest(
//     "POST",
//     _plotProjectsAPIUrl + "/campaign",
//     data,
//     (responseData) => {
//       if (responseData === null) {
//         callback("");
//       } else {
//         if (responseData.success) {
//           //console.log(responseData.success, "response responseData.success");
//           callback(responseData.result);
//         } else {
//           callback("");
//         }
//       }
//     },
//   );
// };

export const editDrenchAction = (data) => async (dispatch) => {
  dispatch({ type: EDIT_DRENCH });
  try {
    const function_url = store?.getState()?.Cf?.function_url || "";
    if (data?.images && typeof data?.images !== "string") {
      const imageRef = storage.ref(
        `/goldfinch/${data.drenchKey}/thumbnail.${extension(
          data?.images?.type
        )}`
      );
      await imageRef.put(data?.images);
      data.imageContentType = data?.images?.type;
      data.images = await imageRef.getDownloadURL();
    }

    const editCampaign = await fetch(function_url + "/editGoldFinch", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    const editCampaignResult = await editCampaign.json();

    NotificationManager.success(`Goldfinch Updated Successfully`);
  } catch (e) {
    console.error(e, "edit drench");
  }
};

export const editDrizzleAction = (data) => async (dispatch) => {
  dispatch({ type: EDIT_DRENCH });
  try {
    if (data?.images && typeof data?.images !== "string") {
      const imageRef = storage.ref(
        `/goldfinch/${data.drenchKey}/thumbnail.${extension(data?.images.type)}`
      );
      await imageRef.put(data?.images);
      data.imageContentType = data?.images.type;
      data.images = await imageRef.getDownloadURL();
    }

    await db
      .collection("drenches")
      .doc(data.key)
      .update({
        ...data,
      });
    NotificationManager.success(`Finch Updated Successfully`);
  } catch (e) {
    console.error(e, "add drench");
  }
};

export const getCategories = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORIES });
  try {
    const snap = await db
      .collection("category")
      .where("status", "==", "active")
      .get();
    const data = snap.docs.map((doc) => doc.data());

    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_CATEGORIES_FAILURE });
    NotificationManager.error(error.message);
  }
};

export const getDrenchAction = (restID) => async (dispatch) => {
  console.log(restID, "restID, restID, restID");
  dispatch({ type: GET_DRENCH });
  try {
    const userLocal = auth.currentUser ? auth.currentUser : undefined;
    const snap = await db
      .collection("drenches")
      .where("restaurantID", "==", restID || userLocal?.uid)
      .get();
    const data = snap.docs.map((doc) => doc.data());

    dispatch({
      type: GET_DRENCH_SUCCESS,
      payload: data?.map((data) => ({
        ...data,
        type: data?.type === "drizzles" ? "finch" : "goldfinch",
        currentStatus: createStatus(data),
      })),
    });
  } catch (error) {
    dispatch({ type: GET_DRENCH_FAILURE });
    NotificationManager.error(error.message);
  }
};

export const deleteDrenchAction = (drenchKey, type, id) => async (dispatch) => {
  dispatch({ type: GET_DRENCH });
  try {
    const docToDelete = db.collection("drenches").doc(drenchKey);
    const data = await docToDelete.get();
    
    await db.collection("deletedCampaigns").doc(drenchKey).set(data.data());
    await docToDelete.delete()
    
    getDrenchAction(id)(dispatch);
    // await database.ref(`/drenches/${drenchKey}`).remove();
  } catch (error) {
    dispatch({ type: GET_DRENCH_FAILURE });
    console.log(error);
    NotificationManager.error(error.message);
  }
};

export const AssignDrenchAction = (AssigndrenchKey) => async (dispatch) => {
  dispatch({ type: GET_ASSIGN_DRENCH });
  try {
    await database
      .ref(`admin/restaurants/${AssigndrenchKey.categoriesKey}`)
      .set({
        balance: AssigndrenchKey.total,
      });
    NotificationManager.success("Goldfinch has been assigned successfully!");
  } catch (error) {
    dispatch({ type: GET_ASSIGN_DRENCH_FAIL });
    NotificationManager.error(error.message);
  }
};

export const balanceAction = (AssigndrenchKey) => async (dispatch) => {
  dispatch({ type: SET_BALANCE });
  try {
    await database
      .ref(`restaurants/${AssigndrenchKey.categoriesKey}`)
      .once("value", (snap) => {
        let data = snap.val();
        database.ref(`restaurants/${AssigndrenchKey.categoriesKey}`).update({
          ...data,
          balance:
            parseInt(data.balance || 0) + parseInt(AssigndrenchKey.total),
        });
        let balance = parseInt(data.balance) + parseInt(AssigndrenchKey.total);
        dispatch({ type: SET_BALANCE_SUCCESS, balance: balance });
      });
  } catch (error) {
    dispatch({ type: SET_BALANCE_FAIL });
    NotificationManager.error(error.message);
  }
};

const balanceRemove = (AssigndrenchKey) => async (dispatch) => {
  dispatch({ type: SET_BALANCE });
  try {
    await database
      .ref(`restaurants/${AssigndrenchKey.categoriesKey}`)
      .once("value", (snap) => {
        let data = snap.val();
        database.ref(`restaurants/${AssigndrenchKey.categoriesKey}`).update({
          ...data,
          balance: parseInt(data.balance || 0) - 1,
        });
        let balance = parseInt(data.balance) - 1;
        dispatch({ type: SET_BALANCE_SUCCESS, balance: balance });
      });
  } catch (error) {
    dispatch({ type: SET_BALANCE_FAIL });
    NotificationManager.error(error.message);
  }
};

export const balanceAdd = (AssigndrenchKey) => async (dispatch) => {
  dispatch({ type: SET_BALANCE });
  try {
    await database
      .ref(`restaurants/${AssigndrenchKey.categoriesKey}`)
      .once("value", (snap) => {
        let data = snap.val();
        database.ref(`restaurants/${AssigndrenchKey.categoriesKey}`).update({
          ...data,
          balance:
            parseInt(data.balance || 0) + parseInt(AssigndrenchKey.total),
        });
        let balance = parseInt(data.balance) + parseInt(AssigndrenchKey.total);
        dispatch({ type: SET_BALANCE_SUCCESS, balance: balance });
      });
  } catch (error) {
    dispatch({ type: SET_BALANCE_FAIL });
    NotificationManager.error(error.message);
  }
};

export const getAdminAssignAction = (restID) => async (dispatch) => {
  dispatch({ type: GET_ASSIGN_DRENCH_ADMIN });
  try {
    database.ref(`admin`).on("value", async (snap) => {
      let data = snap.val();
      if (data) {
        let keyData = Object.keys(data);
        let valueData = Object.values(data);
        const userLocal = auth.currentUser ? auth.currentUser : undefined;
        let claimedUsersInfo = [];
        valueData.map((values) => {
          let keys = Object.keys(values);
          let dataVal = Object.values(values);
          //console.log(keys, dataVal, "keys,dataVal");
          keys.map((a, index) => {
            //console.log("=====hello =====", a, restID);
            if (restID) {
              if (a === restID) {
                //console.log("userid assigned drench", restID, dataVal[index]);
                claimedUsersInfo.push(dataVal[index]);
              }
            } else {
              if (a === userLocal?.uid) {
                claimedUsersInfo.push(dataVal[index]);
              }
            }
          });
        });
        //console.log("user assigned ", claimedUsersInfo);
        dispatch({
          type: GET_ASSIGN_DRENCH_SUCCESS_ADMIN,
          payload: claimedUsersInfo,
        });
      }
    });
  } catch (error) {
    dispatch({ type: GET_ASSIGN_DRENCH_FAIL_ADMIN });
    NotificationManager.error(error.message);
  }
};

export const getSubscriptionAction = (restID) => async (dispatch) => {
  dispatch({ type: GET_SUBSCRIPTION });
  try {
    database.ref(`subscription`).on("value", async (snap) => {
      let data = snap.val();
      if (data) {
        let keyData = Object.keys(data);
        let valueData = Object.values(data);
        const userLocal = auth.currentUser ? auth.currentUser : undefined;
        const arrayValue = valueData
          .map((ele, key) => {
            return { ...ele, key: keyData[key] };
          })
          .filter(
            (item) =>
              item.restaurantID === userLocal?.uid ||
              item.restaurantID === restID
          );
        const dublicateDrenchRemove = new Set(
          arrayValue.map((item) => item.drenchId).flat()
        );
        const changeToArray = Array.from(dublicateDrenchRemove);
        // Drench balance
        const drenchBalance = arrayValue.map((item) => item.tierId);
        let balance = 0;
        drenchBalance.forEach((element) => {
          if (element === PLAN_STARTER) {
            balance += 30;
          } else if (element === PLAN_PREMIUM) {
            // premium balance
            balance += 50;
          } else if (element === PLAN_ADVANCE) {
            // advance balance
            balance += 75;
          }
        });
        dispatch({
          type: GET_SUBSCRIPTION_SUCCESS,
          payload: arrayValue,
          balance,
          changeToArray,
        });
      }
    });
  } catch (error) {
    dispatch({ type: GET_SUBSCRIPTION_FAIL });
    NotificationManager.error(error.message);
  }
};

// first check which tier is purchased
// depend on tier enable the drench
// else show error
export const activeDrenchByAdmin = (payload) => async (dispatch) => {
  const function_url = store?.getState()?.Cf?.function_url || "";
  const activateFence = await fetch(function_url + "/editFenceStatus", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      enabled: !payload.data.active,
      campaignId: payload.data.campaignId,
    }),
  });
  const activateFenceResult = await activateFence.json();

  if (activateFenceResult?.success) {
    console.log(activateFenceResult, "activateFenceResult");
    if (payload.btAdmin) {
      await db
        .collection("drenches")
        .doc(payload.data.key)
        .update({
          ...payload.data,
          active: !payload.data.active,
          byAdmin: !payload.checkAdmin,
        });

      getDrenchAction(payload.data.restaurantID)(dispatch);
    }
  }
};

export const activeDrenchActive = (payload) => async (dispatch) => {
  dispatch({ type: ACTIVE_TIER });
  try {
    const function_url = store?.getState()?.Cf?.function_url || "";
    console.log(payload.geoFenceCampaignId, "STATUSCHANGE");
    const activateFence = await fetch(function_url + "/editFenceStatus", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        enabled: !payload.active,
        campaignId: payload?.campaignId,
      }),
    });
    const activateFenceResult = await activateFence.json();

    if (activateFenceResult?.success) {
      await db
        .collection("drenches")
        .doc(payload.key)
        .update({
          ...payload,
          active: !payload.active,
        });
      getDrenchAction()(dispatch);
    } else if(activateFenceResult?.message) {
      NotificationManager.error(activateFenceResult?.message);
    }
  } catch (error) {
    dispatch({ type: ACTIVE_TIER_FAIL });
    NotificationManager.error(error.message);
  }
};

export const getanalyticsAction = () => async (dispatch) => {
  dispatch({ type: GET_ANALYTICS });
  try {
    database.ref(`analytics`).on("value", (snap) => {
      let data = snap.val();
      let keyData = Object.keys(data);
      let valueData = Object.values(data);
      let finalViewed = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "drenchViewed") {
            finalViewed.push(dataVal[index]);
          }
        });
      });
      let drenchViewed = [];
      finalViewed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          drenchViewed.push(dataVal[index]);
        });
      });
      let claimedUsersInfo = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "claimedUsersInfo") {
            claimedUsersInfo.push(dataVal[index]);
          }
        });
      });
      let claimedUsersInfo2 = [];
      claimedUsersInfo.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          claimedUsersInfo2.push(dataVal[index]);
        });
      });

      let getDirectionsPressed = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "getDirectionsPressed") {
            getDirectionsPressed.push(dataVal[index]);
          }
        });
      });
      let getDirectionsPressed2 = [];
      getDirectionsPressed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          getDirectionsPressed2.push(dataVal[index]);
        });
      });
      let notificationsInfo = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "notificationsInfo") {
            notificationsInfo.push(dataVal[index]);
          }
        });
      });
      let notificationsInfo2 = [];
      notificationsInfo.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          notificationsInfo2.push(dataVal[index]);
        });
      });
      let phoneNumberPressed = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "phoneNumberPressed") {
            phoneNumberPressed.push(dataVal[index]);
          }
        });
      });
      let phoneNumberPressed2 = [];
      phoneNumberPressed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          phoneNumberPressed2.push(dataVal[index]);
        });
      });

      let websitePressed = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "websitePressed") {
            websitePressed.push(dataVal[index]);
          }
        });
      });
      let websitePressed2 = [];
      websitePressed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          websitePressed2.push(dataVal[index]);
        });
      });

      let drenchFacebook = [];
      valueData.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "drenchFacebook") {
            drenchFacebook.push(dataVal[index]);
          }
        });
      });
      let drenchFacebook2 = [];
      drenchFacebook.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          drenchFacebook2.push(dataVal[index]);
        });
      });

      let obj = {
        drenchViewed,
        claimedUsersInfo2,
        getDirectionsPressed2,
        notificationsInfo2,
        phoneNumberPressed2,
        websitePressed,
        drenchFacebook,
      };
      //console.log("drenchViewedData", drenchViewed);
      //console.log("drenchViewedData2", claimedUsersInfo2);
      //console.log("getDirectionsPressed", getDirectionsPressed2);
      //console.log("notificationsInfo", notificationsInfo2);
      //console.log("phoneNumberPressed", phoneNumberPressed2);
      //console.log("websitePressed", websitePressed);
      //console.log("drenchFacebook", drenchFacebook);

      dispatch({ type: GET_ANALYTICS_SUCCESS, payload: obj });
      // dispatch({ type: GET_ANALYTICS_SUCCESS, payload: claimedUsersInfo2})
      // dispatch({ type: GET_ANALYTICS_SUCCESS, payload: getDirectionsPressed2})
    });
  } catch (error) {
    dispatch({ type: GET_ANALYTICS_FAIL });
    NotificationManager.error(error.message);
  }
};

export const getanalyticsActionMercent = (drenchId) => async (dispatch) => {
  dispatch({ type: GET_ANALYTICS_MERCENT });
  try {
    database.ref(`analytics`).on("value", (snap) => {
      let data = snap.val();
      let keyData = Object.keys(data);
      let valueData = Object.values(data);
      //console.log(data, "getanalyticsAction");
      //console.log(keyData, "getanalyticsAction");

      const arrayValue = valueData
        .map((ele, key) => {
          return { ...ele, key: keyData[key] };
        })
        // .filter(item => item.key === "-MLwQGKe4xRwPNHrd9c_")
        .filter((item) => item.key === drenchId);
      //console.log("drenchmercentanalathyes", arrayValue);
      let finalViewed = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "drenchViewed") {
            finalViewed.push(dataVal[index]);
          }
        });
      });
      let drenchViewed = [];
      finalViewed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          drenchViewed.push(dataVal[index]);
        });
      });
      //console.log("drenchVieweddrenchViewed", finalViewed);
      //console.log("drenchVieweddrenchViewed", drenchViewed);
      // let drenchViewed =[]
      // arrayValue.map((values) => {
      //   let keys = Object.keys(values);
      //   let dataVal = Object.values(values);
      //   keys.map((a, index) => {
      //     if(a === 'drenchViewed') {
      //       drenchViewed.push(dataVal[index])
      //     }
      //   })
      //   console.log("drenchVieweddrenchViewed",drenchViewed)
      // })
      // let claimedUsersInfo =[]
      // arrayValue.map((values) => {
      //   let keys = Object.keys(values);
      //   let dataVal = Object.values(values);
      //   keys.map((a, index) => {
      //     if(a === 'claimedUsersInfo') {
      //       claimedUsersInfo.push(dataVal[index])
      //     }
      //   })
      //   console.log("claimedUsersInfo111",claimedUsersInfo)
      // })

      let claimedUsersInfo = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "claimedUsersInfo") {
            claimedUsersInfo.push(dataVal[index]);
          }
        });
      });
      let claimedUsersInfo2 = [];
      claimedUsersInfo.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          claimedUsersInfo2.push(dataVal[index]);
        });
      });

      let getDirectionsPressed = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "getDirectionsPressed") {
            getDirectionsPressed.push(dataVal[index]);
          }
        });
      });
      let getDirectionsPressed2 = [];
      getDirectionsPressed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          getDirectionsPressed2.push(dataVal[index]);
        });
      });
      let notificationsInfo = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "notificationsInfo") {
            notificationsInfo.push(dataVal[index]);
          }
        });
      });
      let notificationsInfo2 = [];
      notificationsInfo.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          notificationsInfo2.push(dataVal[index]);
        });
      });
      let phoneNumberPressed = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "phoneNumberPressed") {
            phoneNumberPressed.push(dataVal[index]);
          }
        });
      });
      let phoneNumberPressed2 = [];
      phoneNumberPressed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          phoneNumberPressed2.push(dataVal[index]);
        });
      });

      let websitePressed = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "websitePressed") {
            websitePressed.push(dataVal[index]);
          }
        });
      });
      let websitePressed2 = [];
      websitePressed.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          websitePressed2.push(dataVal[index]);
        });
      });

      let drenchFacebook = [];
      arrayValue.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          if (a === "drenchFacebook") {
            drenchFacebook.push(dataVal[index]);
          }
        });
      });
      let drenchFacebook2 = [];
      drenchFacebook.map((values) => {
        let keys = Object.keys(values);
        let dataVal = Object.values(values);
        keys.map((a, index) => {
          drenchFacebook2.push(dataVal[index]);
        });
      });

      let obj = {
        drenchViewed,
        claimedUsersInfo2,
        getDirectionsPressed2,
        notificationsInfo2,
        phoneNumberPressed2,
        websitePressed2,
        drenchFacebook2,
      };
      //console.log("drenchViewedData", drenchViewed);
      //console.log("drenchViewedData2", claimedUsersInfo2);
      //console.log("getDirectionsPressed", getDirectionsPressed2);
      //console.log("notificationsInfo", notificationsInfo2);
      //console.log("phoneNumberPressed", phoneNumberPressed2);
      //console.log("websitePressed", websitePressed2);
      //console.log("drenchFacebook", drenchFacebook2);

      dispatch({ type: GET_ANALYTICS_SUCCESS_MERCENT, payload: obj });
      // dispatch({ type: GET_ANALYTICS_SUCCESS, payload: claimedUsersInfo2})
      // dispatch({ type: GET_ANALYTICS_SUCCESS, payload: getDirectionsPressed2})
    });
  } catch (error) {
    dispatch({ type: GET_ANALYTICS_FAIL_MERCENT });
    NotificationManager.error(error.message);
  }
};
