import appLocaleData from "react-intl/locale-data/ko";
import saMessages from "../locales/ko_KO";

const koLang = {
  messages: {
    ...saMessages,
  },
  locale: "ko-ko",
  data: appLocaleData,
};

export default koLang;
