import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "Assets/scss/reactifyCss";
import "./style.scss";
import { auth } from "./Firebase";
import App from "Container/App";
import * as yup from "yup";
import _ from "underscore";
import GoldFinchLoader from "./GoldFinchLoader";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { store, persistor } from "Store";
import { PersistGate } from "redux-persist/integration/react";
import { Elements } from "@stripe/react-stripe-js";
import { stripe } from "Stripe/Stripe";
import { ConfirmProvider } from "material-ui-confirm";

yup.addMethod(yup.string, "strongPassword", function (message) {
  return this.test("strongPasswordTest", message, function (value) {
    const { path, createError } = this;
    let label = this?.schema?._label || "Password";
    switch (Boolean(value)) {
      case !/^(?=.*[a-z])/.test(value):
        return createError({
          path,
          message: label + " must include lowercase letter ",
        });
      case !/^(?=.*[A-Z])/.test(value):
        return createError({
          path,
          message: label + " must include uppercase letter",
        });
      case !/^(?=.*[0-9])/.test(value):
        return createError({ path, message: label + " must include digit" });
      // case !/^(?=.*[!@#\$%\^&\*])/.test(value):
      // return createError({ path, message: label + " must include special character" });
      default:
        return true;
    }
  });
});

_.mixin({
  isBlank: function (string) {
    return _.isUndefined(string) || _.isNull(string) || string.toString().trim().length === 0;
  },
});

function MainApp() {
  const [initialLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("user logged in");
      } else {
        console.log("user logged out");
      }
      setInitialLoading(false);
    });
  }, []);

  return initialLoading ? (
    <GoldFinchLoader />
  ) : (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <Switch>
              <Elements stripe={stripe}>
                <ConfirmProvider>
                  <Route path="/" component={App} />
                </ConfirmProvider>
              </Elements>
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </PersistGate>
    </Provider>
  );
}

export default MainApp;
