//Video Player

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import ChartContainer from "./../../Components/ChartContainer";
import LineChartSmall from "./../../Routes/charts/LineChartSmall";
import LineChartBig from "./../../Routes/charts/LineChartBig";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Modal,
  Paper,
} from "@material-ui/core";
import { auth, database, storage, db } from "../../Firebase/index";
import moment from "moment";
import _ from "lodash";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays, format } from "date-fns";
import Header from "./../../Components/NewHeader";

function AnalyticsMerchant(props) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [data, setData] = useState([]);
  const [savedFinches, setSavedFinches] = useState([]);
  const [favoriteFinches, setFavoriteFinches] = useState([]);
  const [viewedFinches, setViewedFinches] = useState([]);
  const [directions, setDirections] = useState([]);
  const [phoneNo, setPhoneNo] = useState([]);
  const [website, setWebsite] = useState([]);
  const [media, setMedia] = useState([]);

  const [datePicker, setDatePicker] = useState(false);
  const getAnalytics = async () => {
    try {
      const user = props?.id || auth.currentUser.uid;
      console.log(
        value?.[0]?.startDate,
        value?.[0]?.endDate,
        user,
        "value?.[0]?.startDate, value?.[0]?.endDate"
      );

      const data = await db
        .collection("analytics")
        .where("partnerId", "==", user)
        .where("createdAt", ">=", new Date(value?.[0]?.startDate))
        .where("createdAt", "<=", new Date(value?.[0]?.endDate))
        .get();

      const dataArray = data.docs.map((doc) => doc.data());
      console.log(sortFilterTypeData(dataArray, "claimed"), "ANALYTICS");

      setFavoriteFinches(sortFilterTypeData(dataArray, "favourite"));
      setSavedFinches(sortFilterTypeData(dataArray, "claimed"));
      setViewedFinches(sortFilterTypeData(dataArray, "viewed"));
      setDirections(sortFilterData(dataArray, "location"));
      setPhoneNo(sortFilterData(dataArray, "phone"));
      setWebsite(sortFilterData(dataArray, "website"));

      setMedia(sortMediaData(dataArray, "social"));
      // console.log(dataArray, "data");
    } catch (error) {
      console.log(error.message, "ERROR_WHILR_FETCHING");
    }
  };
  const sortFilterTypeData = (arr, type) => {
    const newArr = arr.filter((item) => item.type === type);
    const drizzle = newArr.filter((item) => item.finchType === "drizzles");
    const drenches = newArr.filter((item) => item.finchType !== "drizzles");
    const drenchesData = filterSortDrenches(drenches);
    const drizzlesData = filterSortDrenches(drizzle);
    console.log(newArr, "newArr");
    return {
      drizzle: drizzlesData,
      drench: drenchesData,
    };
  };
  const sortMediaData = (data, type) => {
    let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const mediaSupported = ["facebook", "instagram", "twitter", "youtube"];
    const mediaData = data.filter((item) =>
      mediaSupported.some((v) => v === item?.platform)
    );
    const dateArr = mediaData.map((item) => {
      return {
        ...item,
        dateString: moment(new Date(item.createdAt?.seconds * 1000)).format(
          "ddd"
        ),
      };
    });

    const sortedData = _.groupBy(dateArr, "dateString");
    const newGroupByDay = dayArr.map((key) => {
      return [
        key,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[0])
          ?.length || 0,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[1])
          ?.length || 0,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[2])
          ?.length || 0,
        sortedData?.[key]?.filter((item) => item.platform === mediaSupported[3])
          ?.length || 0,
      ];
    });
    console.log(newGroupByDay, "dateArr");
    return newGroupByDay;
  };

  const sortFilterData = (arr, type) => {
    let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const newArr = arr.filter((item) => item.type === type);
    const dateArr = newArr.map((item) => {
      return {
        ...item,
        dateString: moment(new Date(item.createdAt?.seconds * 1000)).format(
          "ddd"
        ),
      };
    });
    // console.log(newArr, "newArr", type);
    const sortedByData = dateArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.createdAt?.seconds * 1000) -
        new Date(a.createdAt?.seconds * 1000)
      );
    });

    const groupByDay = _.groupBy(sortedByData, "dateString");

    const newGroupByDay = dayArr.map((key) => {
      return groupByDay?.[key]?.length || 0;
    });
    return newGroupByDay;
  };

  const filterSortDrenches = (arr, type) => {
    let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dateArr = arr.map((item) => {
      // console.log(item, "LOLOLOs");
      return {
        ...item,
        dateString: moment(new Date(item.createdAt?.seconds * 1000)).format(
          "ddd"
        ),
      };
    });
    // console.log(newArr, "newArr", type);
    const sortedByData = dateArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.createdAt?.seconds * 1000) -
        new Date(a.createdAt?.seconds * 1000)
      );
    });

    const groupByDay = _.groupBy(sortedByData, "dateString");

    const newGroupByDay = dayArr.map((key) => {
      return groupByDay?.[key]?.length || 0;
    });
    return newGroupByDay;
  };

  useEffect(() => {
    if (!datePicker) {
      getAnalytics();
    }
  }, [datePicker, props?.id]);

  var startDate = format(new Date(value?.[0]?.startDate), "dd/MM/yyyy");
  var endDate = format(new Date(value?.[0]?.endDate), "dd/MM/yyyy");

  // console.log(savedFinches, "SAVED_FINCHES");
  return (
    <>
      {!props?.adminLayout && <Header title="Analytics" />}
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {!props?.adminLayout && (
              <Typography
                variant="h6"
                style={{
                  padding: 10,
                  marginTop: 10,
                  marginBottom: 20,
                  color: "#222",
                }}
              >
                Global Analytics
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              onClick={() => setDatePicker(true)}
              variant="contained"
              color="primary"
            >
              {startDate} to {endDate}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={6}>
            <ChartContainer title="User Saved Goldfinch/Finches" isSmall>
              <LineChartSmall data={savedFinches} isMultiple />
            </ChartContainer>
          </Grid>
          <Grid item xs={6}>
            <ChartContainer title="User Viewed Goldfinch/Finches" isSmall>
              <LineChartSmall data={viewedFinches} isMultiple />
            </ChartContainer>
          </Grid>
          {/* <Grid item xs={4}>
          <ChartContainer title="Users Pressed Email" isSmall>
            <LineChartSmall data={email} />
          </ChartContainer>
        </Grid> */}
          <Grid item xs={4}>
            <ChartContainer title="Users Pressed Get Directions" isSmall>
              <LineChartSmall data={directions} />
            </ChartContainer>
          </Grid>
          <Grid item xs={4}>
            <ChartContainer title="Users Received Notifications" isSmall>
              <LineChartSmall />
            </ChartContainer>
          </Grid>
          <Grid item xs={4}>
            <ChartContainer title="Users Pressed Phone Number" isSmall>
              <LineChartSmall data={phoneNo} />
            </ChartContainer>
          </Grid>
          <Grid item xs={6}>
            <ChartContainer title="Users Pressed Website" isSmall>
              <LineChartSmall data={website} />
            </ChartContainer>
          </Grid>
          <Grid item xs={6}>
            <ChartContainer title="User Favorite GoldFinches/Finches" isSmall>
              <LineChartSmall data={favoriteFinches} isMultiple />
            </ChartContainer>
          </Grid>
          <Grid item xs={12}>
            <ChartContainer title="Social Media Platforms Activity" isSmall>
              <LineChartBig data={media} />
            </ChartContainer>
          </Grid>
        </Grid>
        <Modal open={datePicker} onClose={() => setDatePicker(false)}>
          <Grid
            container
            spacing={5}
            style={{ minHeight: "100vh" }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Paper style={{ padding: 10 }}>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <DateRangePicker
                      ranges={value}
                      onChange={(item) => setValue([item.selection])}
                    />
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button
                      onClick={() => setDatePicker(false)}
                      variant="contained"
                      color="primary"
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Modal>
      </div>
    </>
  );
}
export default AnalyticsMerchant;
