import React from "react";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { auth, db } from "../../Firebase";
import Header from "./../../Components/NewHeader";
import { Autocomplete, TextField, Button } from "@mui/material";
import { Label } from "reactstrap";
import moment from "moment";
import { capitalize } from "lodash";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";

const Subscriptions = () => {
  const [partners, setPartners] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState(null);
  const [statistics, setStatistics] = useState({
    totalRevenue: 0,
    totalCustomers: 0,
    activeSubscriptions: 0,
    totalAssigned: 0,
  });
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const confirm = useConfirm();

  const columns = [
    {
      name: "packageType",
      label: "Package Type",
      options: {
        customBodyRender: (value) => {
          return capitalize(value);
        },
      },
    },
    {
      name: "current_period_start",
      label: "Period Start",
      options: {
        customBodyRender: (value) => {
          return moment(value * 1000).format("lll");
        },
      },
    },
    {
      name: "current_period_end",
      label: "Period End",
      options: {
        customBodyRender: (value) => {
          return moment(value * 1000).format("lll");
        },
      },
    },
    {
      name: "plan",
      label: "Interval",
      options: {
        customBodyRender: (value) => {
          return value?.interval ? capitalize(value.interval) : "Assigned";
        },
      },
    },
    {
      name: "quantity",
      label: "Quantity",
    },
    {
      name: "plan",
      label: "Price",
      options: {
        customBodyRender: (value, tableMeta) => {
          return value?.amount
            ? "$" + (parseFloat(value.amount) / 100).toFixed(2)
            : "Assigned";
        },
      },
    },
    {
      name: "plan",
      label: "Currency",
      options: {
        customBodyRender: (value) => {
          return value?.currency ? value.currency : "Assigned";
        },
      },
    },
    {
      name: "plan",
      label: "Total Price",
      options: {
        customBodyRender: (value, tableMeta) => {
          const subscription = subscriptions[tableMeta?.rowIndex];
          return value?.amount
            ? "$" +
                (
                  (parseFloat(value.amount) / 100) *
                  subscription.quantity
                ).toFixed(2)
            : "Assigned";
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (id, tableMeta) => {
          const subscription = subscriptions[tableMeta?.rowIndex];
          if (subscription?.status === "canceled") {
            return (
              <Button variant="contained" disabled>
                Canceled
              </Button>
            );
          }
          return (
            <>
              <Button
                onClick={() => cancelSubscription(id)}
                variant="contained"
                style={{ backgroundColor: "#DC2845", color: "white" }}
                className={`ml-2`}
              >
                Cancel Subscription
              </Button>
            </>
          );
        },
      },
    },
  ];

  const getPartners = () => {
    db.collection("restaurants")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setPartners(data);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const calculateStatistics = (subscriptions) => {
    const totalRevenue = subscriptions.reduce((acc, curr) => {
      const price = curr?.plan?.amount
        ? parseFloat(curr?.plan?.amount) / 100
        : 0;
      return acc + price * curr.quantity;
    }, 0);

    const activeSubscriptions = subscriptions.reduce((acc, curr) => {
      const status = curr?.status === "active";
      return status ? acc + 1 : acc;
    }, 0);

    const totalAssigned = subscriptions.reduce((acc, curr) => {
      return curr?.isAssigned ? acc + 1 : acc;
    }, 0);

    return { totalRevenue, activeSubscriptions, totalAssigned };
  };

  const getData = async () => {
    if (subscriptions.length) return;

    db.collection("subscriptions")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setSubscriptions(data);
        setStatistics({ ...calculateStatistics(data) });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const cancelSubscription = async (subscriptionId) => {
    try {
      try {
        await confirm({
          title: "Cancel Subscription",
          description: "Are you sure you want to cancel this subscription?",
        });
      } catch (err) {
        return;
      }

      setSubscriptions(
        subscriptions.map((item) =>
          item.id === subscriptionId ? { ...item, status: "canceled" } : item
        )
      );

      let fetchURL = function_url + "/cancelSubscription";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/cancelSubscription";
      const authToken = await auth.currentUser.getIdToken();

      let response = await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": authToken,
        },
        body: JSON.stringify({
          subscriptionId,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        NotificationManager.success("Subscription cancelled successfully");
      }
    } catch (err) {
      console.log(err, "error response");
      if (err) NotificationManager.error(JSON.stringify(err));
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    getData();

    if (selectedPartners) {
      const data = subscriptions.filter(
        (subscription) => subscription?.uid === selectedPartners?.uid
      );
      setFilteredSubscriptions([...data]);
      setStatistics({ ...calculateStatistics(data) });
    } else {
      setStatistics({ ...calculateStatistics(subscriptions) });
    }

    if (!selectedPartners) setFilteredSubscriptions([]);
  }, [selectedPartners]);

  return (
    <>
      <Header title="Subscriptions" />
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div className="mx-20 mb-30">
          <h1>Statistics</h1>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-around",
              gap: 20,
              alignItems: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>Total Customers</h3>
              <h4>{partners.length}</h4>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>Total Income</h3>
              <h4>${statistics.totalRevenue.toLocaleString()}</h4>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>Active Subscriptions</h3>
              <h4>{statistics.activeSubscriptions}</h4>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>Total Assigned</h3>
              <h4>{statistics.totalAssigned}</h4>
            </div>
          </div>
        </div>
        <MUIDataTable
          onRefresh={getData}
          title={
            <div className="mui-default-input-styles d-flex justify-content-start align-items-center">
              <Label>Partners :</Label>
              <Autocomplete
                multiple={false}
                options={partners}
                getOptionLabel={(option) => option.companyName}
                style={{
                  marginLeft: "16px",
                  padding: 0,
                  width: 300,
                  height: "3rem",
                }}
                onChange={(_event, value) => setSelectedPartners(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ padding: 0, border: "none" }}
                    variant="outlined"
                  />
                )}
              />
            </div>
          }
          data={
            !filteredSubscriptions.length
              ? selectedPartners
                ? []
                : subscriptions
              : filteredSubscriptions
          }
          columns={columns}
          options={{
            selectableRows: false,
            print: false,
            download: false,
          }}
        />
      </div>
    </>
  );
};

export default Subscriptions;
