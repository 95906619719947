import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import UpdateProfile from "../profileMercent";
import { db } from "../../Firebase";
import { useSelector } from "react-redux";

export function AdminEditProfile() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [isReadonly, setIsReadonly] = useState(false);
  const localUserData = useSelector((state) => state.authUser?.userDaata);

  const getData = async () => {
    if (!id) {
      return;
    }
    const snap = await db.collection("restaurants").doc(id).get();
    if (!snap.exists) return history.push("/dashboard/account-request");
    setData(snap.data());
  };

  useEffect(() => {
    if (window.location.pathname.includes("view")) setIsReadonly(true);
    getData();
  }, [id]);

  if (id && !data) return <div>Loading...</div>;
  if (!id && !localUserData) return <div>Loading...</div>;

  return <UpdateProfile readonly={isReadonly} userData={data && id ? data : localUserData} />;
}

export default AdminEditProfile;
