/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { auth, database, db } from "../../Firebase/index";

import { NotificationManager } from "react-notifications";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  USER_DATA,
  IS_ADMIN,
  USER_DAATA,
  RELOAD_PAGE_ON_AUTH_CHANGE,
} from "Store/Actions/types";
import { store } from "../index";
import { useSelector } from "react-redux";
import { extension } from "mime-types";
import { waitSeconds } from "../../Util/dateHelpers";

/**
 * Redux Action To Sigin User With Firebase
 */

const getLoginUrlForUser = (subdomain) => {
  return `https://${subdomain}.thegoldfinch.app`;
};

export const signinUserInFirebase =
  (user, history, setLoading, setMessage) => async (dispatch) => {
    console.log("signinUserInFirebase - signinUserInFirebase");
    dispatch({ type: LOGIN_USER });
    auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL) //SESSION
      .then(() => {
        return auth
          .signInWithEmailAndPassword(user.email, user.password)
          .then(async (res) => {
            console.log(res, "login successfully");

            let user = res.user;
            sessionStorage.setItem("user_id", user.uid);
            sessionStorage.setItem("user_email", user.email);
            const snap = await db.collection("restaurants").doc(user.uid).get();
            let data = snap.data();
            if (data) {
              if (data.status === "approved") {
                // dispatch(setReloadPageOnAuthChange(false));
                const userLocal = auth.currentUser
                  ? auth.currentUser
                  : undefined;

                const subdomain = window.location.host.split(".")[0];

                if (!window.location.host.includes("localhost")) {
                  if (subdomain === "admin" && data.role !== "admin") {
                    navigator.clipboard.writeText(
                      getLoginUrlForUser("partners")
                    );
                    setMessage(
                      "Please login with partner application. Partner app URL copied to clipboard."
                    );
                    return setLoading(false);
                  }

                  if (subdomain === "partners" && data.role !== "merchant") {
                    navigator.clipboard.writeText(getLoginUrlForUser("admin"));
                    setMessage(
                      "Please login with admin application. Admin app URL copied to clipboard."
                    );
                    return setLoading(false);
                  }
                }

                dispatch({
                  type: LOGIN_USER_SUCCESS,
                  payload: {
                    userId: sessionStorage.getItem("user_id"),
                    userEmail: sessionStorage.getItem("user_email"),
                  },
                  userData: data,
                  currentUser: auth.currentUser,
                });

                if (data?.role === "admin") {
                  return history.push("/dashboard/account-request");
                } else if (data?.role === "merchant") {
                  return history.push("/dashboard/goldfinch");
                }

                if (!auth.currentUser) {
                  return history.replace("/auth/signin");
                }

                // history.replace("/");
                NotificationManager.success("Successfully Login!");
              } else {
                setMessage(
                  "Thank you for signing up for Goldfinch. You will receive an email soon regarding your approval."
                );
                setLoading(false);
                dispatch({ type: LOGOUT_USER });
                sessionStorage.removeItem("user_id");
                sessionStorage.removeItem("user_email");
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("FAILURE2", error);
            dispatch({ type: LOGIN_USER_FAILURE });
            NotificationManager.error(error.message);
          });
      })
      .catch((err) => {
        console.error("Error of setPersistance", err);
      });
  };

export const getUserData = (data) => async (dispatch) => {
  dispatch({ type: USER_DAATA, payload: data });
};

export const setUserData = (userData) => async (dispatch) => {
  //console.log("hello world this is set user data", userData);
  dispatch({ type: LOGIN_USER });
  dispatch({ type: USER_DATA, payload: userData });
  // history.push('/');
};

// export const setIsAdmin = isAdmin => async dispatch => {
//   dispatch({ type: IS_ADMIN, payload: isAdmin })
//   // history.push('/');
// }

export const setIsAdmin = (isAdmin) => async (dispatch) => {
  dispatch({ type: IS_ADMIN, payload: isAdmin });
  // history.push('/');
};

export const setReloadPageOnAuthChange = (true_or_false) => (dispatch) => {
  dispatch({
    type: RELOAD_PAGE_ON_AUTH_CHANGE,
    payload: true_or_false,
  });
};

/*
 * Redux Action To Forgot Password With Firebase
 */
export const forgotPassInFirebase = (user, history) => async (dispatch) => {
  dispatch({ type: LOGIN_USER });
  auth
    .sendPasswordResetEmail(user.email)
    .then((res) => {
      history.push("/");
      NotificationManager.success("Reset Password Email Send Successfully");
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    });
};

/**
 * Redux Action To Signout User From  Firebase
 */
export const logoutUserFromFirebase = () => (dispatch) => {
  auth
    .signOut()
    .then(() => {
      dispatch({ type: LOGOUT_USER });
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("user_email");
      // const userKey = Object.keys(window.sessionStorage)
      // .filter(it => it.startsWith('firebase:authUser'))[0];
      // sessionStorage.removeItem(userKey)
      // const userLocal = userKey ? JSON.parse(sessionStorage.getItem(userKey)) : undefined;
      NotificationManager.success("Successfully Logout");
    })
    .catch((error) => {
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signup User In Firebase
 */
export const signupUserInFirebase = (user, history) => async (dispatch) => {
  try {
    const function_url = store?.getState()?.Cf?.function_url || "";
    dispatch({ type: SIGNUP_USER });
    const success = await auth.createUserWithEmailAndPassword(
      user.email,
      user.password
    );
    const resUser = success?.user;

    let data = {
      email: user.email,
      fullName: user.fullName,
      companyName: user.companyName,
      address: user?.fullAddress || "",
      description: user.description,
      phone: user.phone,
      latitude: user?.latitude || 0,
      longitude: user?.longitude || 0,
      role: "merchant",
      status: "Waiting For Approval",
      logoUrl: null,
      labelClass: "badge-danger",
      uid: resUser.uid,
      imageContentType: user.logo?.type,
      // customerId: result.customerId,
      balance: 3,
      isProfileCompleted: false,
    };
    const token = await resUser.getIdToken();
    console.log(token);
    // await db.collection("restaurants").doc(resUser.uid).set(data);
    let response = await fetch(function_url + "/signup", {
      // let response = await fetch(
      //   "http://localhost:5001/goldfinch-c2012/us-central1/signup",
      // {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(data),
    });
    console.log("function_url with staging",function_url)
    console.log("function_url with local",function_url)
    let result = await response.json();

    dispatch({ type: LOGOUT_USER });
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("user_email");
    history.push("/auth/signin");
    NotificationManager.success("Account Created, Pending For Approval");
    // NotificationManager.success(
    //   "Thank you for signing up to Goldfinch, a member of our team will approve your account shortly"
    // );
  } catch (error) {
    dispatch({ type: SIGNUP_USER_FAILURE });
    console.error("error.message", error);
    NotificationManager.error(error.message);
  }
};

/**
 * Redux Action To Signup User In Firebase
 */
export const createUserInFirebase = (user, currentUser) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    const function_url = store?.getState()?.Cf?.function_url || "";
    auth
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(async (success) => {
        let resUser = success?.user;
        if (currentUser) {
          await auth.updateCurrentUser(currentUser);
        }

        try {
          let response = await fetch(function_url + "/signup", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: user.email,
              password: user.password,
              companyName: user.companyName,
              address: user?.fullAddress || "",
              description: user.description,
              phone: user.phone,
              facebookLink: user.facebookLink,
              instagramLink: user.instagramLink,
              twitterLink: user.twitterLink,
              youtubeLink: user.youtubeLink,
              website: user.website,
              latitude: user?.latitude || 0,
              longitude: user?.longitude || 0,
              role: "merchant",
              status: "Rejected",
              labelClass: "badge-danger",
              uid: resUser.uid,
              balance: 3,
            }),
          });
          let result = await response.json();
          console.log("ON_SIGNUP_SUCCESS", result);
          // await db.collection("restaurants").doc(resUser.uid).set(data);
          NotificationManager.success("Account Created Successfully!");
          logoutUserFromFirebase();
          resolve();
        } catch (error) {
          NotificationManager.error(
            "There is some error when creating account"
          );
          logoutUserFromFirebase();
          reject();
        }
      })
      .catch((error) => {
        console.error("error.message", error);
        NotificationManager.error(error.message);
        logoutUserFromFirebase();
        reject();
      });
  });
};

export const signupUserInFirebaseUpdate =
  (user, history) => async (dispatch) => {
    const function_url = store?.getState()?.Cf?.function_url || "";

    //console.log("useeeerrrrdata", user);
    return false;

    dispatch({ type: SIGNUP_USER });

    // auth
    //   .createUserWithEmailAndPassword(user.email, user.password)
    //   .then(success => {
    //     auth
    //       .signInWithEmailAndPassword(user.email, user.password)
    //       .then(async res => {
    //         let resUser = res.user
    //         sessionStorage.setItem('user_id', 'user-id')
    //         console.log(user, 'user signup')
    //         let response = await fetch(
    //           function_url + '/addAccount',
    //           {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({
    //               email: user.email,
    //               address: user.fullAddress,
    //               description: user.description,
    //               phone: user.phone
    //             })
    //           }
    //         )
    //         if (response.ok) {

    // let result = await response.json()
    let data = {
      email: user.email,
      password: user.password,
      companyName: user.companyName,
      address: user.fullAddress,
      description: user.description,
      phone: user.phone,
      facebookLink: user.facebookLink,
      instagramLink: user.instagramLink,
      twitterLink: user.twitterLink,
      website: user.website,
      latitude: user.latitude,
      longitude: user.longitude,
      // role: 'merchant',
      // status: 'disapprove',
      // labelClass: 'badge-danger',
      // uid: resUser.uid,
      // customerId: result.customerId,
      // balance: 3
    };
    database.ref(`restaurants/`).update(data);

    dispatch({
      type: SIGNUP_USER_SUCCESS,
      // payload: sessionStorage.getItem('user_id')
    });
    //   if (response.ok) { // history.push('/signin')
    //   NotificationManager.success('Account Created Successfully!')
    NotificationManager.success(
      "Thank you for singing up to Drench,a member of our team will approve your account shortly"
    );
    // } else {
    //   console.log('error when creating customer in stripe')
    //   NotificationManager.error(
    //     'There is some error when creating account'
    //   )
    //   }
  };

/**
 * Redux Action To Signin User In Firebase With Facebook
 */
export const signinUserWithFacebook = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.FacebookAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      //console.log(result, "login successfully");
      sessionStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Google
 */
export const signinUserWithGoogle = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.GoogleAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      sessionStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Github
 */
export const signinUserWithGithub = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.GithubAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      sessionStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Twitter
 */
export const signinUserWithTwitter = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.TwitterAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function (result) {
      sessionStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success("User Login Successfully!");
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};
