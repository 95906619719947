import React from "react";
import { useSelector } from "react-redux";
import { MenuItem, Select } from "@material-ui/core";

function SelectPaymentMethod({ customViewRender, value, setValue, valueName }) {
  const localUserData = useSelector((state) => state.authUser.userDaata);
  const paymentMethods = useSelector((state) => state.UserReducer.paymentMethods);
  const [cards, setCards] = React.useState({
    defaultCard: null,
    cards: [],
  });

  React.useEffect(() => {
    if (paymentMethods?.length) {
      let cards = paymentMethods.filter((card) => card.id !== localUserData?.defaultPaymentMethod);
      if (localUserData?.defaultPaymentMethod) {
        let defaultCard = paymentMethods.find((card) => card.id === localUserData.defaultPaymentMethod);
        setCards({ defaultCard, cards });
        setValue(
          valueName
            ? {
                ...value,
                [valueName]: defaultCard?.id,
              }
            : defaultCard?.id
        );
        return;
      }
      setCards({ defaultCard: null, cards: [...paymentMethods] });
    }
  }, [paymentMethods]);

  return customViewRender ? (
    customViewRender(paymentMethods)
  ) : cards?.cards.length || cards?.defaultCard ? (
    <Select
      style={{ width: "100%", color: "black" }}
      variant="outlined"
      value={valueName ? value[valueName] : value}
      onChange={(e) =>
        setValue(
          valueName
            ? {
                ...value,
                [valueName]: e.target.value,
              }
            : e.target.value
        )
      }
    >
      {cards.defaultCard && <MenuItem value={cards.defaultCard.id}>**** {cards.defaultCard?.cardLast4}</MenuItem>}
      {cards.cards?.map((item) => (
        <MenuItem value={item.id}>**** {item?.cardLast4}</MenuItem>
      ))}
    </Select>
  ) : null;
}
export default SelectPaymentMethod;
