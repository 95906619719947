/**
 * App Config File
 */
const AppConfig = {
  defaultLat: "45.424721",
  defaultLng: "-75.695000",
  appLogo: `${process.env.PUBLIC_URL}/assets/images/img/site-logo.png`, // App Logo
  brandName: "Goldfinch", // Brand Name
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: `${process.env.PUBLIC_URL}/assets/images/img/bg.png`, // Select sidebar image
  isDarkSidenav: true, // Set true to dark sidebar
  enableThemeOptions: true, // Enable Theme Options
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "en",
  },
  enableUserTour: process.env.NODE_ENV === "production", // Enable / Disable User Tour
  copyRightText: "Reactify © 2021 All Rights Reserved.", // Copy Right Text
  // light theme colors
  themeColors: {
    primary: "#363636",
    secondary: "#E4E4E4",
    success: "#28B873",
    info: "#11A4C2",
    danger: "#DC2845",
    warning: "#EBB600",
    light: "#F3F3F3",
    dark: "#242424",
    orange: "#FF824C",
    purple: "#4950BA",
    default: "#FAFAFA",
    greyLighten: "#A5A7B2",
    grey: "#677080",
    white: "#FFFFFF",
    yellow: "#E5B11E",
    male_color: "#5D92F4",
    female_color: "#B5333B",
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: "#424242",
  },
};

export default AppConfig;
