import appLocaleData from "react-intl/locale-data/ar";
import saMessages from "../locales/ar_SA";

const saLang = {
  messages: {
    ...saMessages,
  },
  locale: "ar-SA",
  data: appLocaleData,
};
export default saLang;
