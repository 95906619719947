import appLocaleData from "react-intl/locale-data/ru";
import saMessages from "../locales/ru_RU";

const saLang = {
  messages: {
    ...saMessages,
  },
  locale: "ru-RU",
  data: appLocaleData,
};
export default saLang;
