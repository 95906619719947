import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  buttonGroup: {
    marginTop: "0px",
    width: "100%!important",
  },
}));

const DayPicker = ({ selectedDay, setSelectedDay }) => {
  const classes = useStyles();

  const handleDayToggle = (day) => {
    if (selectedDay?.includes(day)) {
      setSelectedDay(selectedDay?.filter((d) => d !== day));
    } else {
      setSelectedDay([...selectedDay, day]);
    }
  };
  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Grid container spacing={1} style={{ width: "100%" }}>
        {[0, 1, 2, 3, 4, 5, 6].map((day) => (
          <Grid item>
            <Button
              key={day}
              variant={selectedDay?.includes(day) ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleDayToggle(day)}
            >
              {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][day]}
            </Button>
          </Grid>
        ))}
      </Grid>
    </ButtonGroup>
  );
};

export default DayPicker;
