import {
  GET_PAYMENT_METHODS,
  ADD_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD,
  LOGOUT_USER,
  GET_CUSTOMER_SUBSCRIPTION,
  TOGGLE_SUBSCRIPTION,
} from "../Actions/types";

const initialState = {
  paymentMethods: [],
  customerSubscription: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };

    case ADD_PAYMENT_METHOD:
      if (action.payload?.alreadyExists) return state;
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.payload],
      };

    case REMOVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: [...state.paymentMethods.filter((item) => item.id !== action.payload)],
      };

    case GET_CUSTOMER_SUBSCRIPTION:
      return {
        ...state,
        customerSubscription: action.payload,
      };

    case TOGGLE_SUBSCRIPTION:
      const customerSubscription = state.customerSubscription.map((sub) => {
        if (sub.subscriptionId === action.payload) {
          sub.cancelAtPeriodEnd = !sub.cancelAtPeriodEnd;
        }
        return sub;
      });
      return {
        ...state,
        customerSubscription: [...customerSubscription],
      };

    case LOGOUT_USER:
      return {
        ...state,
        paymentMethods: [],
        customerSubscription: [],
      };

    default:
      return state;
  }
};

export default userReducer;
