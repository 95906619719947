/**
 * CRM Reducer
 */
//action types
import {
  GET_ASSIGN_DRENCH,
  GET_ASSIGN_DRENCH_FAIL,
  GET_ASSIGN_DRENCH_SUCCESS,
  GET_ASSIGN_DRENCH_ADMIN,
  GET_ASSIGN_DRENCH_FAIL_ADMIN,
  GET_ASSIGN_DRENCH_SUCCESS_ADMIN,
} from "Store/Actions/types";

const INITIAL_STATE = {
  assignDrench: [],
};

let AssignDrench = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGN_DRENCH:
      return {
        ...state,
        loading: true,
        //   analyticsdata:[]
      };
    case GET_ASSIGN_DRENCH_SUCCESS:
      //console.log("payloaddddd2222224444446666", action.payload);
      return {
        ...state,
        loading: false,
        // assignDrench: action.payload
      };
    // update client
    case GET_ASSIGN_DRENCH_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_ASSIGN_DRENCH_ADMIN:
      return {
        ...state,
        loading: true,
        //   analyticsdata:[]
      };
    case GET_ASSIGN_DRENCH_SUCCESS_ADMIN:
      //console.log("GET_ASSIGN_DRENCH_SUCCESS_ADMIN", action.payload);
      return {
        ...state,
        loading: false,
        assignDrench: action.payload,
      };
    // update client
    case GET_ASSIGN_DRENCH_FAIL_ADMIN:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default AssignDrench;
