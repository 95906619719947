import React, { useEffect, useState } from "react";
import { auth } from "../../Firebase";
import {store} from "./../../Store/index";
import FranchiseForm from "../../Components/FranchiseForm/FranchiseForm";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UPDATE_FRANCHISE } from "../../Store/Actions/types";

const UpdateFranchise = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const franchises = useSelector((state) => state.Franchise.franchises);
  let { id } = useParams();

  const handleSubmit = async (obj) => {
    try {
      const function_url =
        store?.getState()?.Cf?.function_url || "";

      let errorObj = {};

      if (!obj.name) {
        errorObj.name = "Franchise Name is Required";
      }

      if (!obj.address) {
        errorObj.address = "Franchise Address is Required";
      }

      if (!obj.longitude) {
        errorObj.address = "Franchise Address is Required";
      }

      if (!obj.latitude) {
        errorObj.address = "Franchise Address is Required";
      }

      if (Object.keys(errorObj).length) {
        setErrors(errorObj);
        return;
      }

      setLoading(true);
      const token = await auth.currentUser.getIdToken();

      let response = await fetch(function_url + "/editFranchise", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({ ...data, ...obj }),
      });
      let result = await response.json();
      setLoading(false);
      dispatch({ type: UPDATE_FRANCHISE, payload: { ...data, ...result } });
      history.push("/dashboard/franchises");
    } catch (e) {
      setLoading(false);
      console.log(e, "CREATE_FRANCHISE_ERROR");
    }
  };

  useEffect(() => {
    if (id) {
      const franchise = franchises.find(
        (franchise) => franchise.franchiseId === id
      );
      if (!franchise) return history.push("/dashboard/franchises");
      setData(franchise);
    }
  }, [franchises, id]);

  return (
    <FranchiseForm
      type="update"
      title="Update Franchises Location"
      defaultValues={data}
      loading={loading}
      errors={errors}
      setLoading={setLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export default UpdateFranchise;
