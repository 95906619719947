import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { v4 } from "uuid";
import { CircularProgress } from "@material-ui/core";

const LocationApi = ({ getAddress, styles, address: fullAddress }) => {
  const [address, setAddress] = useState("");
  // const [gmapsLoaded, setGmapsLoaded] = useState(false);
  const handleChange = (address) => {
    console.log(address, "address, lat, lng");
    setAddress(address);
  };

  /**
   * @link https://github.com/hibiken/react-places-autocomplete/issues/195#issuecomment-382596430
   * @link https://github.com/hibiken/react-places-autocomplete/issues/195#issuecomment-470568244
   * @link https://github.com/hibiken/react-places-autocomplete/issues/195#issuecomment-757367130
   * */

  /*useEffect(() => {
    console.log('gmapsLoaded', gmapsLoaded)
    if (!gmapsLoaded) {
      window.initMap = () => setGmapsLoaded(true)
      const gmapScriptEl = document.createElement(`script`)
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${getReactAppGoogleMapAPIKey()}&libraries=places&callback=initMap`
      document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
    }
  })*/

  const handleSelect = (address) => {
    console.log(address, "address");

    geocodeByAddress(address).then(async (results) => {
      getLetLngFunc(results[0]);
    });
  };
  const getLetLngFunc = (results) => {
    console.log(results);
    getLatLng(results).then((e) => {
      getAddress(results.formatted_address, e.lat, e.lng);
      setAddress(results.formatted_address);
    });
  };

  const onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
  };

  const [mapActive, setMapActive] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setMapActive(true);
    }, 1000);

    return () => {
      window.clearTimeout(t);
    };
  }, []);

  return mapActive ? (
    !window?.google?.maps ? (
      <LocationApi
        getAddress={getAddress}
        styles={styles}
        address={fullAddress}
      />
    ) : (
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div style={{ position: "relative", width: "100%" }}>
              <div style={{ position: "relative" }}>
                <input
                  defaultValue={fullAddress}
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className:
                      "location-search-input form-control input-lg has-input",
                  })}
                  style={{
                    width: "100%",
                    border: "none",
                    marginRight: 0,
                    height: 52,
                    paddingRight: loading ? 44 : 0,
                    borderTopWidth: 1,
                    borderTopColor: "black",
                    pointerEvents: "auto",
                    ...styles,
                  }}
                />
                <div
                  style={{
                    width: "40px",
                    position: "absolute",
                    right: 0,
                    top: 12,
                  }}
                >
                  {loading && <CircularProgress size={24} />}
                </div>
              </div>
              {suggestions?.length ? (
                <div className="autocomplete-dropdown-container">
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span key={v4()}>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          );
        }}
      </PlacesAutocomplete>
    )
  ) : null;
};

export default LocationApi;
