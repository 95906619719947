/**
 * CRM Reducer
 */
//action types
import {
  GET_ANALYTICS,
  GET_ANALYTICS_FAIL,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_MERCENT,
  GET_ANALYTICS_FAIL_MERCENT,
  GET_ANALYTICS_SUCCESS_MERCENT,
} from "Store/Actions/types";

const INITIAL_STATE = {
  analyticsdata: [],
  analyticsdataMercent: [],
};

let Analytics = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTICS:
      return {
        ...state,
        loading: true,
        //   analyticsdata:[]
      };
    case GET_ANALYTICS_SUCCESS:
      console.log("payloaddddd", action.payload);
      return {
        ...state,
        loading: false,
        analyticsdata: action.payload,
      };
    // update client
    case GET_ANALYTICS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_ANALYTICS_MERCENT:
      return {
        ...state,
        loading: true,
        //   analyticsdata:[]
      };
    case GET_ANALYTICS_SUCCESS_MERCENT:
      console.log("payloaddddd", action.payload);
      return {
        ...state,
        loading: false,
        analyticsdataMercent: action.payload,
      };
    // update client
    case GET_ANALYTICS_FAIL_MERCENT:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default Analytics;
