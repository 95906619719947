import AccountRequest from "Routes/account-request";
import Drenches from "Routes/Drenches";
import Purchase from "./PurchasePackages";
import Analytics from "Routes/analathys";
import ProfileMercent from "Routes/profileMercent";
import AnalythesMercent from "Routes/analythesMercent";
import AssignDrench from "Routes/AssignDrench";
import ActiveInactive from "Routes/activeInactive";
import Notification_Send from "Routes/Notification_Send";
import UsersList from "Routes/UsersList";
import ClaimedGoldfinchesList from "Routes/ClaimedGoldfinchesList";
import CategoriesList from "Routes/CategoriesList";
import Franchises from "Routes/Franchises";
import AddFranchise from "Routes/AddFranchise";
import CreateFinch from "Routes/CreateFinch";
import EditFinch from "Routes/EditFinch";

import Reports from "./reports";
import Subscriptions from "./Subscriptions";
import GoldfinchesSavedByUsers from "./GoldfinchesSavedByUsers";
import UpdateFranchise from "./UpdateFranchise";
import AdminEditProfile from "./AdminEditProfile";
import UpdatePrivacyPolicy from "./privacy-policy/update-privacy-policy";

let routes = [
  {
    path: "account-request",
    component: AccountRequest,
  },
  {
    path: "goldfinch",
    component: Drenches,
  },
  {
    path: "purchase-packages",
    component: Purchase,
  },
  {
    path: "global-analytics",
    component: Analytics,
  },
  {
    path: "analytics",
    component: AnalythesMercent,
  },
  {
    path: "profile-merchant",
    component: AdminEditProfile,
  },
  {
    path: "admin/update-merchant/:id",
    component: AdminEditProfile,
  },
  {
    path: "admin/view-merchant/:id",
    component: AdminEditProfile,
  },
  {
    path: "rewards",
    component: AssignDrench,
  },
  {
    path: "active-campaigns",
    component: ActiveInactive,
  },
  {
    path: "email-broadcast",
    component: Notification_Send,
  },
  {
    path: "app-users",
    component: UsersList,
  },
  {
    path: "Reports",
    component: Reports,
  },
  {
    path: "Subscriptions",
    component: Subscriptions,
  },
  {
    path: "ClaimedGoldfinchesList",
    component: ClaimedGoldfinchesList,
  },
  {
    path: "goldfinches_saved_by_users",
    component: GoldfinchesSavedByUsers,
  },
  {
    path: "categories",
    component: CategoriesList,
  },
  {
    path: "franchises",
    component: Franchises,
  },
  {
    path: "add-franchises",
    component: AddFranchise,
  },
  {
    path: "franchises/:id",
    component: UpdateFranchise,
  },
  {
    path: "create-campaign/:type",
    component: CreateFinch,
  },
  {
    path: "update-campaign/:type/:id",
    component: EditFinch,
  },
  {
    path: "update-privacy-policy",
    component: UpdatePrivacyPolicy,
  },
];

export default routes;
