import React, { useEffect, useState } from "react";
import { auth } from "../../Firebase";
import { store } from "./../../Store/index";
import FranchiseForm from "../../Components/FranchiseForm/FranchiseForm";
import { useHistory } from "react-router-dom";
import { getActivePackagesItemsDetails } from "Util/subscriptions";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const AddFranchise = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [activePackagesDetails, setActivePackagesDetails] = useState({});
  const localUserData = useSelector((state) => state.authUser.userDaata);

  const handleSubmit = async (obj) => {
    try {
      const function_url = store?.getState()?.Cf?.function_url || "";
      if (activePackagesDetails?.franchise <= 0) {
        return NotificationManager.error(
          "You have reached your limit of franchises. Please upgrade your subscription to add more franchises."
        );
      }

      let errorObj = {};

      if (!obj.name) {
        errorObj.name = "Franchise Name is Required";
      }

      if (!obj.address) {
        errorObj.address = "Franchise Address is Required";
      }

      if (!obj.longitude) {
        errorObj.address = "Franchise Address is Required";
      }

      if (!obj.latitude) {
        errorObj.address = "Franchise Address is Required";
      }

      if (Object.keys(errorObj).length) {
        setErrors(errorObj);
        return;
      }

      setLoading(true);
      const token = await auth.currentUser.getIdToken();

      let response = await fetch(function_url + "/addFranchise", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(obj),
      });
      let result = await response.json();
      setLoading(false);
      history.push("/dashboard/franchises");
      const data = await getActivePackagesItemsDetails(localUserData.uid);
      setActivePackagesDetails(data);
    } catch (e) {
      setLoading(false);
      console.log(e, "CREATE_FRANCHISE_ERROR");
    }
  };

  useEffect(() => {
    if (localUserData?.uid) {
      getActivePackagesItemsDetails(localUserData.uid).then((data) => {
        setActivePackagesDetails(data);
      });
    }
  }, [localUserData]);

  return (
    <FranchiseForm
      title="New Franchises Location"
      loading={loading}
      errors={errors}
      setLoading={setLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export default AddFranchise;
