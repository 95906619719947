/**
 * CRM Reducer
 */
//action types
import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
} from "Store/Actions/types";

const INITIAL_STATE = {
  subscription: [],
  balance: 0,
};

let subscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: action.payload,
        balance: action.balance,
      };
    // update client
    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default subscription;
