import React from "react";

const sidebarMenu = {
  partnerTabsForSidebar: [
    {
      menu_title: "sidebar.drenchCreate",
      externalIcon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/create-goldfinches.svg`}
          alt={"icons"}
          width={20}
        />
      ),
      path: "/dashboard/goldfinch",
      new_item: false,
      child_routes: null,
    },

    {
      menu_title: "sidebar.savedByUsers",
      path: "/dashboard/goldfinches_saved_by_users",
      externalIcon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/claimed-goldfinches.svg`}
          alt={"icons"}
          width={20}
        />
      ),
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.Franchises",
      path: "/dashboard/franchises",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/location.svg`} alt={"icons"} width={20} />
      ),
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.AnalyticsScreen",
      path: "/dashboard/analytics",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/analytics.svg`} alt={"icons"} width={20} />
      ),
      new_item: false,
      child_routes: null,
    },
  ],
  adminTabsForSidebar: [
    {
      menu_title: "sidebar.Subscriptions",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/subscription.svg`} alt={"icons"} width={20} />
      ),
      path: "/dashboard/subscriptions",
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.accountRequest",
      path: "/dashboard/account-request",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/request.svg`} alt={"icons"} width={20} />
      ),
      new_item: false,
      child_routes: null,
    },

    {
      menu_title: "sidebar.AssignDrench",
      path: "/dashboard/rewards",
      externalIcon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/assign-goldfinch.svg`}
          alt={"icons"}
          width={20}
        />
      ),
      new_item: false,
      child_routes: null,
    },

    {
      menu_title: "sidebar.ActiveInactive",
      path: "/dashboard/active-campaigns",
      externalIcon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/activate-goldfinch.svg`}
          alt={"icons"}
          width={20}
        />
      ),
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.notificationSend",
      path: "/dashboard/email-broadcast",
      new_item: false,
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/broadcast.svg`} alt={"icons"} width={20} />
      ),
      child_routes: null,
    },
    {
      menu_title: "sidebar.AnalyticsScreen",
      path: "/dashboard/global-analytics",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/analytics.svg`} alt={"icons"} width={20} />
      ),
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.UsersList",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/user.svg`} alt={"icons"} width={20} />
      ),
      path: "/dashboard/app-users",
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.Reports",
      path: "/dashboard/reports",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/report.svg`} alt={"icons"} width={20} />
      ),
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "sidebar.CategoriesList",
      externalIcon: (
        <img src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/categories.svg`} alt={"icons"} width={20} />
      ),
      path: "/dashboard/categories",
      new_item: false,
      child_routes: null,
    },
    {
      menu_title: "Privacy Policy",
      externalIcon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/sidebar-icons/privacy-policy.png`}
          alt={"icons"}
          width={28}
        />
      ),
      path: "/dashboard/update-privacy-policy",
      new_item: false,
      child_routes: null,
    },
  ],
};

export default sidebarMenu;
