module.exports = {
  "sidebar.app": "אפליקציה",
  "sidebar.horizontal": "אופקי",
  "sidebar.horizontalMenu": "תפריט אופקי",
  "sidebar.general": "כללי",
  "sidebar.component": "רְכִיב",
  "sidebar.features": "מאפיינים",
  "sidebar.applications": "יישומים",
  "sidebar.dashboard": "לוּחַ מַחווָנִים",
  "sidebar.dashboard1": "לוח מחוונים 1",
  "sidebar.dashboard2": "לוח המחוונים 2",
  "sidebar.dashboard3": "לוח מחוונים 3",
  "sidebar.modules": "מודולים",
  "sidebar.agency": "סוֹכְנוּת",
  "sidebar.pages": "דפים",
  "sidebar.gallery": "גלריה",
  "sidebar.pricing": "תמחור",
  "sidebar.terms&Conditions": "תנאי תנאים",
  "sidebar.feedback": "מָשׁוֹב",
  "sidebar.report": "להגיש תלונה",
  "sidebar.faq(s)": "שאלות נפוצות ",
  "sidebar.advancedComponent": "רכיב מתקדם",
  "sidebar.blank": "רֵיק",
  "sidebar.session": "מוֹשָׁב",
  "sidebar.login": "התחברות",
  "sidebar.register": "הירשם",
  "sidebar.lockScreen": "מסך נעילה",
  "sidebar.forgotPassword": "שכחת את הסיסמא",
  "sidebar.404": "404",
  "sidebar.500": "500",
  "sidebar.uiComponents": "רכיבי ממשק משתמש",
  "sidebar.alerts": "התראות",
  "sidebar.appBar": "בר יישומים",
  "sidebar.avatars": "אווטרים",
  "sidebar.buttons": "כפתורים",
  "sidebar.bottomNavigations": "ניווט למטה",
  "sidebar.badges": "תגים",
  "sidebar.cards": "כרטיסים",
  "sidebar.cardsMasonry": "כרטיסים מודפסים",
  "sidebar.chip": "שְׁבָב",
  "sidebar.dialog": "דיאלוג",
  "sidebar.dividers": "מְחוּגָה",
  "sidebar.drawers": "תַחתוֹנִים",
  "sidebar.popover": "פופובר",
  "sidebar.expansionPanel": "לוח הרחבה",
  "sidebar.gridList": "רשימת רשת",
  "sidebar.list": "רשימה",
  "sidebar.menu": "תַפרִיט",
  "sidebar.popoverAndToolTip": "פופ מעל והסבר קצר",
  "sidebar.progress": "התקדמות",
  "sidebar.snackbar": "סנקבר",
  "sidebar.selectionControls": "פקדי בחירה",
  "sidebar.advanceUiComponents": "מתקדם רכיבי ממשק משתמש",
  "sidebar.dateAndTimePicker": "תאריך & תאריך בורר",
  "sidebar.tabs": "כרטיסיות",
  "sidebar.stepper": "צעד",
  "sidebar.notification": "הוֹדָעָה",
  "sidebar.sweetAlert": "התראה מתוקה",
  "sidebar.autoComplete": "השלמה אוטומטית",
  "sidebar.aboutUs": "עלינו",
  "sidebar.widgets": "ווידג'טים",
  "sidebar.forms": "טפסים",
  "sidebar.formElements": "אלמנטים טופס",
  "sidebar.textField": "שדה טקסט",
  "sidebar.selectList": "בחר רשימה",
  "sidebar.charts": "תרשימים",
  "sidebar.reCharts": "תרשימים חוזרים",
  "sidebar.reactChartjs2": "לְהָגִיב תרשים 2",
  "sidebar.icons": "סמלים",
  "sidebar.themifyIcons": "לסנכרן סמלים",
  "sidebar.simpleLineIcons": "אייקונים פשוטים",
  "sidebar.materialIcons": "סמלים חומר",
  "sidebar.fontAwesome": "גופן מדהים",
  "sidebar.tables": "טבלאות",
  "sidebar.basic": "בסיסי",
  "sidebar.dataTable": "טבלת נתונים",
  "sidebar.responsive": "תגובה",
  "sidebar.reactTable": "טבלת תגובה",
  "sidebar.maps": "מפות",
  "sidebar.googleMaps": "גוגל מפות",
  "sidebar.leafletMaps": "מפות עלים",
  "sidebar.inbox": "תיבת הדואר הנכנס",
  "sidebar.users": "משתמשים",
  "sidebar.userProfile1": "פרופיל משתמש 1",
  "sidebar.userProfile2": "פרופיל משתמש 2",
  "sidebar.userManagement": "ניהול משתמשים",
  "sidebar.userProfile": "פרופיל משתמש",
  "sidebar.userList": "רשימת משתמש",
  "sidebar.calendar": "לוּחַ שָׁנָה",
  "sidebar.cultures": "תרבויות",
  "sidebar.dnd": "Dnd",
  "sidebar.selectable": "ניתן לבחירה",
  "sidebar.customRendering": "עיבוד מותאם אישית",
  "sidebar.chat": "לְשׂוֹחֵחַ",
  "sidebar.toDo": "לעשות",
  "sidebar.editor": "עוֹרֵך",
  "sidebar.wysiwygEditor": "עורך WYSIWYG",
  "sidebar.quillEditor": "עורך",
  "sidebar.reactAce": "תגובת אייס",
  "sidebar.dragAndDrop": "גרור ושחרר",
  "sidebar.reactDragula": "תגובתי",
  "sidebar.reactDnd": "לְהָגִיב דנד",
  "sidebar.blogManagement": "בלוג ניהול",
  "sidebar.ecommerce": "מסחר אלקטרוני",
  "sidebar.shopList": "רשימת החנות",
  "sidebar.shopGrid": "חנות רשת",
  "sidebar.invoice": "חשבונית",
  "sidebar.multilevel": "רב",
  "sidebar.sublevel": "תת-קרקעי",
  "widgets.totalEarns": "סך כל הרווחים",
  "widgets.emailsStatistics": "הודעות דוא ל לסטטיסטיקה",
  "widgets.totalRevenue": "סך הרווחים",
  "widgets.onlineVistors": "באינטרנט מבקרים",
  "widgets.trafficSources": "מקורות תנועה",
  "widgets.RecentOrders": "הזמנות אחרונות",
  "widgets.topSellings": "Tמכירת אופ",
  "widgets.productReports": "דוחות מוצרים",
  "widgets.productStats": "נתונים סטטיסטיים של מוצרים",
  "widgets.ComposeEmail": "כתוב מייל אלקטרוני",
  "widgets.employeePayroll": "שכר שכר",
  "widgets.visitors": "מבקרים",
  "widgets.orders": "הזמנות",
  "widgets.totalSales": 'סה"כ מכירות',
  "widgets.orderStatus": "מצב הזמנה",
  "widgets.netProfit": "נֶטוֹ רווח",
  "widgets.overallTrafficStatus": "מצב התנועה הכולל",
  "widgets.ratings": "דירוגים",
  "widgets.tax": "מַס",
  "widgets.expenses": "הוצאות",
  "widgets.currentTime": "זמן נוכחי",
  "widgets.currentDate": "דייט נוכחי",
  "widgets.todayOrders": "הזמנות היום",
  "widgets.toDoList": "לעשות רשימות",
  "widgets.discoverPeople": "גלה אנשים",
  "widgets.commments": "הערות",
  "widgets.newCustomers": "לקוחות חדשים",
  "widgets.recentNotifications": "הודעות אחרונות",
  "widgets.appNotifications": "התראות יישום",
  "widgets.siteVisitors": "",
  "widgets.recentActivities": "פעילויות אחרונות",
  "widgets.recentOrders": "הזמנות אחרונות",
  "widgets.gallery": "גלריה",
  "widgets.pricing": "תמחור",
  "widgets.enterpriseEdition": "גרסה לארגונים",
  "widgets.socialCompanines": "חברות חברתיות",
  "widgets.personalEdition": "מהדורה אישית",
  "widgets.teamEdition": "מהדורת צוות",
  "widgets.standard": "תֶקֶן",
  "widgets.advanced": "מִתקַדֵם",
  "widgets.master": "לִשְׁלוֹט",
  "widgets.Mega": "מגה",
  "widgets.logIn": "התחברn",
  "widgets.signUp": "הירשם",
  "widgets.lockScreen": "מסך נעילה",
  "widgets.alertsWithLink": "התראות עם קישור",
  "widgets.additionalContent": "תוכן נוסף",
  "widgets.alertDismiss": "התראה דחה",
  "widgets.uncontrolledDisableAlerts": "בלתי מבוקרת השבת התראות",
  "widgets.contexualAlerts": "התראות דו - צדדיות",
  "widgets.alertsWithIcons": "התראות עם סמלים",
  "widgets.Simple App Bars": "פשוט ברים אפליקציה",
  "widgets.appBarsWithButtons": "ברים אפליקציה עם לחצנים",
  "widgets.imageAvatars": "תמונה אווטרים",
  "widgets.lettersAvatars": "מכתבים אווטרים",
  "widgets.iconsAvatars": "סמלים אווטרים",
  "widgets.flatButtons": "לחצנים שטוחים",
  "widgets.raisedButton": "לחצן גויס",
  "widgets.buttonWithIconAndLabel": "לחצן עם סמל ותווית",
  "widgets.floatingActionButtons": "לחצני פעולה צפים",
  "widgets.iconButton": "לחצן סמל",
  "widgets.socialMediaButton": "לחצן מדיה חברתית",
  "widgets.reactButton": "לחצן לְהָגִיב",
  "widgets.buttonOutline": "מתאר",
  "widgets.buttonSize": "גודל כפתור",
  "widgets.buttonState": "מצב לחצן",
  "widgets.buttonNavigationWithNoLabel": "כפתור ניווט ללא תווית",
  "widgets.buttonNavigation": "ניווט באמצעות לחצן",
  "widgets.iconNavigation": "ניווט בסמל",
  "widgets.badgeWithHeadings": "תג עם כותרות",
  "widgets.contexualVariations": "וריאציות מידיות",
  "widgets.badgeLinks": "קישורים 'קישורים'",
  "widgets.materialBadge": "תג חומר",
  "widgets.simpleCards": "כרטיסים פשוטים",
  "widgets.backgroundVarient": "רקע וריאנט",
  "widgets.cardOutline": "מתווה כרטיס",
  "widgets.overlayCard": "כרטיס כיסוי",
  "widgets.cardGroup": "קבוצת כרטיס",
  "widgets.cardTitle": "כותרת כרטיס",
  "widgets.speacialTitleTreatment": "טיפול כותרת כותרת",
  "widgets.chipWithClickEvent": "צ'יפ עם אירוע לחץ",
  "widgets.chipArray": "מערך שבבים",
  "widgets.dialogs": "דיאלוגים",
  "widgets.listDividers": "רשימת מחיצות",
  "widgets.insetDividers": "מחלקים",
  "widgets.temporaryDrawers": "מגירות זמניות",
  "widgets.permanentDrawers": "מגירות קבועות",
  "widgets.simpleExpansionPanel": "לוח הרחבה פשוט",
  "widgets.controlledAccordion": "אקורדיון מבוקר",
  "widgets.secondaryHeadingAndColumns": "כותרת משנית ועמודות",
  "widgets.imageOnlyGridLists": "תמונה בלבד רשימות רשת",
  "widgets.advancedGridLists": "רשימות רשת מתקדמות",
  "widgets.singleLineGridLists": "רשימות רשת",
  "widgets.simpleLists": "רשימות פשוטות",
  "widgets.folderLists": "רשימות תיקיות",
  "widgets.listItemWithImage": "פריט רשימה עם תמונה",
  "widgets.switchLists": "החלף רשימות",
  "widgets.insetLists": "רשימות הבלעה",
  "widgets.nestedLists": "רשימות מקוננות",
  "widgets.checkboxListControl": "בקרת רשימת תיבות",
  "widgets.pinedSubHeader": "כותרת משנה",
  "widgets.InteractiveLists": "",
  "widgets.simpleMenus": "תפריטים פשוטים",
  "widgets.selectedMenu": "תפריט נבחר",
  "widgets.maxHeightMenu": "תפריט גובה מקסימלי",
  "widgets.changeTransition": "שינוי מעבר",
  "widgets.paper": "עיתון",
  "widgets.anchorPlayGround": "עוגן",
  "widgets.tooltip": "הסבר קצר",
  "widgets.positionedToolTips": "מיקום סנקבר",
  "widgets.circularProgressBottomStart": "התקדמות מעגלית",
  "widgets.interactiveIntegration": "אינטגרציה אינטראקטיבית",
  "widgets.determinate": "קבעו",
  "widgets.linearProgressLineBar": "שורת התקדמות קו לינארי",
  "widgets.indeterminate": "לֹא קָבוּעַ",
  "widgets.buffer": "בַּלָם",
  "widgets.query": "שאילתא",
  "widgets.transitionControlDirection": "כיוון בקרת מעבר",
  "widgets.simpleSnackbar": "פשוט חטיף",
  "widgets.positionedSnackbar": "מיקום סנקבר",
  "widgets.contexualColoredSnackbars": "צבעוני חטיפים צבעוניים",
  "widgets.simpleCheckbox": "תיבת סימון פשוטה",
  "widgets.interminateSelection": "בחירה סופית",
  "widgets.disabledCheckbox": "תיבת סימון מושבתת",
  "widgets.customColorCheckbox": "תיבת צבע מותאמת אישית",
  "widgets.VerticalStyleCheckbox": "תיבת סימון בסגנון אנכי",
  "widgets.horizontalStyleCheckbox": "סגנון אופקי תיבת סימון",
  "widgets.radioButtons": "כפתורי רדיו",
  "widgets.disabledRadio": "רדיו מושבת",
  "widgets.withError": "עם שגיאה",
  "widgets.switches": "Swiches",
  "widgets.dateAndTimePicker": "תאריך ושעה פיקר",
  "widgets.defaultPicker": "בורר ברירת המחדל",
  "widgets.timePicker": "בורר זמן",
  "widgets.weekPicker": "בורר השבוע",
  "widgets.defaultDatePicker": "בְּרִירַת מֶחדָל תַאֲרִיך קוֹטֵף",
  "widgets.customPicker": "בוחר מותאם אישית",
  "widgets.tabs": "כרטיסיות",
  "widgets.fixedTabs": "כרטיסיות קבועות",
  "widgets.basicTab": "הכרטיסייה הבסיסית",
  "widgets.wrappedLabels": "תוויות עטופות",
  "widgets.centeredLabels": "תוויות מרוכזות",
  "widgets.forcedScrolledButtons": "לחצן גלילה כפויs",
  "widgets.iconsTabs": "כרטיסיות סמלים",
  "widgets.withDisableTabs": "עם השבת כרטיסיות",
  "widgets.iconWithLabel": "סמל עם תווית",
  "widgets.stepper": "צעד",
  "widgets.horizontalLinear": "אופקי ליניארי",
  "widgets.horizontalNonLinear": "אופקי לא ליניארי",
  "widgets.horizontalLinerAlternativeLabel": "אופקי ליינר אלטרנטיבי תווית",
  "widgets.horizontalNonLinerAlternativeLabel":
    "אופקי לא ליינר תווית אלטרנטיבית",
  "widgets.verticalStepper": "סטפר אנכי",
  "widgets.descriptionAlert": "תיאור התראה",
  "widgets.customIconAlert": "התראה סמל מותאם אישית",
  "widgets.withHtmlAlert": "עם התראת HTML",
  "widgets.promptAlert": "התראה מוקדמת",
  "widgets.passwordPromptAlert": "התראת בקשה לסיסמה",
  "widgets.customStyleAlert": "התראה בסגנון מותאם אישית",
  "widgets.autoComplete": "השלמה אוטומטית",
  "widgets.reactSelect": "לְהָגִיב בחר",
  "widgets.downshiftAutoComplete": "השלם אוטומטי",
  "widgets.reactAutoSuggests": "לְהָגִיב אוטומטי מציע",
  "widgets.aboutUs": "עלינו",
  "widgets.ourVission": "שלנו אשר",
  "widgets.ourMissions": "המשימות שלנו",
  "widgets.ourMotivation": "המוטיבציה שלנו",
  "widgets.defualtReactForm": "טופס תגובה ברירת המחדל",
  "widgets.url": "כתובת אתר",
  "widgets.textArea": "אזור טקסט",
  "widgets.file": "קוֹבֶץ",
  "widgets.formGrid": "טופס טבלה",
  "widgets.inlineForm": "טופס מוטבע",
  "widgets.inputSizing": "גודל קלט",
  "widgets.inputGridSizing": "קלט שינוי גודל הרשת",
  "widgets.hiddenLabels": "תוויות נסתרות",
  "widgets.formValidation": "אימות טופס",
  "widgets.number": "מספר",
  "widgets.date": "תַאֲרִיך",
  "widgets.time": "זְמַן",
  "widgets.color": "צֶבַע",
  "widgets.search": "לחפש",
  "widgets.selectMultiple": "בחר מרובים",
  "widgets.inputWithSuccess": "קלט עם הצלחה",
  "widgets.inputWithDanger": "כניסה עם סכנה",
  "widgets.simpleTextField": "שדה טקסט פשוט",
  "widgets.componet": "רכיבים",
  "widgets.layouts": "פריסות",
  "widgets.inputAdorements": "הקלט",
  "widgets.formattedInputs": "תשומות מעוצבות",
  "widgets.simpleSelect": "בחר פשוט",
  "widgets.nativeSelect": "בחירת שפת אם",
  "widgets.MutltiSelectList": "רַב בחר רשימה",
  "widgets.lineChart": "תרשים שורה",
  "widgets.barChart": "טבלת עמודות",
  "widgets.stackedBarChart": "תרשים עמודות מוערמות",
  "widgets.lineBarAreaChart": "תרשים הקו של הקו בר",
  "widgets.areaChart": "תרשים שטח",
  "widgets.stackedAreaChart": "תרשים שטח מוערם",
  "widgets.verticalChart": "תרשים אנכי",
  "widgets.radarChart": "תרשים מכ ם",
  "widgets.doughnut": "סופגניה",
  "widgets.polarChart": "תרשים פולאר",
  "widgets.pieChart": "תרשים עוגה",
  "widgets.bubbleChart": "תרשים הבועה",
  "widgets.horizontalBar": "בר אופק",
  "widgets.basicTable": "טבלה בסיסית",
  "widgets.contexualColoredTable": "טבלה צבעונית",
  "widgets.dataTable": "טבלת נתונים",
  "widgets.employeeList": "רשימת עובדים",
  "widgets.responsiveTable": "טבלאות תגובה",
  "widgets.responsiveFlipTable": "טבלת תגובה לְהַעִיף",
  "widgets.reactGridControlledStateMode": "לְהָגִיב במצב מצב מבוקר",
  "widgets.googleMaps": "גוגל מפות",
  "widgets.productsReports": "דוחות מוצרים",
  "widgets.taskList": "רשימת מטלות",
  "widgets.basicCalender": "בסיסי קלנדר",
  "widgets.culturesCalender": "תרבויות",
  "widgets.dragAndDropCalender": "גרור ושחרר קלנדר",
  "widgets.selectableCalender": "לבחירה קלנדר",
  "widgets.customRendering": "עיבוד מותאם אישית",
  "widgets.customCalender": "לוח שנה מותאם אישית",
  "widgets.searchMailList": "חפש רשימת דואר",
  "components.buyNow": "קנה עכשיו",
  "compenets.choose": "בחר",
  "compenets.username": "שם משתמש",
  "compenets.passwords": "סיסמאות",
  "widgets.forgetPassword": "שכחתי את הסיסמה",
  "compenets.signIn": "היכנס",
  "compenets.dontHaveAccountSignUp": "אין כניסה לחשבון",
  "compenets.enterUserName": "הכנס שם משתמש",
  "compenets.enterEmailAddress": "הזן כתובת דואל",
  "compenets.confirmPasswords": "אשר סיסמאות",
  "components.alreadyHavingAccountSignIn": "כבר יש לך חשבון כניסה",
  "components.enterYourPassword": "הכנס את הסיסמה שלך",
  "components.unlock": "לבטל נעילה",
  "components.enterPasswords": "הזן סיסמאות",
  "components.resetPassword": "Resest סיסמה",
  "components.pageNotfound": "דף לא",
  "components.sorryServerGoesWrong": "שרת מצטער טועה",
  "components.persistentDrawer": "מגירה מתמשכת",
  "components.back": "חזור",
  "components.next": "הַבָּא",
  "components.completeStep": "שלב שלם",
  "components.success": "הַצלָחָה",
  "components.passwordPrompt": "בקשה לסיסמה",
  "components.warning": "אַזהָרָה",
  "components.customIcon": "סמל מותאם אישית",
  "components.customStyle": "סגנון מותאם אישית",
  "components.basic": "בסיסי",
  "components.submit": "שלח",
  "components.compose": "לְהַלחִין",
  "components.sendMessage": "לשלוח הודעה",
  "components.addNewTasks": "הוסף משימות חדשות",
  "components.addToCart": "הוסף לעגלה",
  "components.payNow": "שלם עכשיו",
  "components.print": "הדפס",
  "components.cart": "עֲגָלָה",
  "components.viewCart": "צפה בסל",
  "components.checkout": "לבדוק",
  "widgets.QuickLinks": "קישורים מהירים",
  "widgets.upgrade": "לשדרג",
  "widgets.app": "App",
  "widgets.addNew": "הוסף חדש",
  "widgets.orderDate": "תאריך הזמנה",
  "widgets.status": "סטָטוּס",
  "widgets.trackingNumber": "Tמספר מרתיעה",
  "widgets.action": "פעולה",
  "widgets.designation": "יִעוּד",
  "widgets.subject": "נושא",
  "widgets.send": "לִשְׁלוֹחַ",
  "widgets.saveAsDrafts": "שמירה כטיוטות",
  "widgets.onlineSources": "מקורות מקוונים",
  "widgets.lastMonth": "חודש שעבר",
  "widgets.widgets": "ווידג'טים",
  "widgets.listing": "מודעה",
  "widgets.paid": "בתשלום",
  "widgets.refunded": "החזר כספי",
  "widgets.done": "בוצע",
  "widgets.pending": "ממתין ל",
  "widgets.cancelled": "מבוטל",
  "widgets.approve": "לְאַשֵׁר",
  "widgets.following": "הבא",
  "widgets.follow": "לעקוב אחר",
  "widgets.graphs&Charts": "גרפים ותרשימים",
  "widgets.open": "לִפְתוֹחַ",
  "widgets.bounced": "ניתק",
  "widgets.spam": "ספאם",
  "widgets.unset": "לא הוגדר",
  "widgets.bandwidthUse": "שימוש ברוחב פס",
  "widgets.dataUse": "שימוש בנתונים",
  "widgets.unsubscribe": "בטל רישום",
  "widgets.profile": "פּרוֹפִיל",
  "widgets.messages": "הודעות",
  "widgets.support": "תמיכה",
  "widgets.faq(s)": "פאק (ים)",
  "widgets.upgradePlains": "שדרג שפלים",
  "widgets.logOut": "להתנתק",
  "widgets.mail": "דוֹאַר",
  "widgets.adminTheme": "נושאים של מנהל מערכת",
  "widgets.wordpressTheme": "נושאים",
  "widgets.addToCart": "הוסף לעגלה",
  "widgets.plan": "לְתַכְנֵן",
  "widgets.basic": "בסיסי",
  "widgets.pro": "מִקצוֹעָן",
  "widgets.startToBasic": "התחל בסיסיים",
  "widgets.upgradeToPro": "שדרוג לפרו",
  "widgets.upgradeToAdvance": "שדרג מראש",
  "widgets.comparePlans": "השווה תוכניות",
  "widgets.free": "חופשי",
  "widgets.frequentlyAskedQuestions": "שאלות נפוצות",
  "widgets.newEmails": 'הודעות דוא"ל חדשות',
  "widgets.searchIdeas": "חיפוש רעיונות",
  "widgets.startDate": "תאריך התחלה",
  "widgets.endDate": "תאריך סיום",
  "widgets.category": "קטגוריה",
  "widgets.apply": "להגיש מועמדות",
  "widgets.downloadPdfReport": "הורד דוח PDF",
  "widgets.yesterday": "אתמול",
  "widgets.totalOrders": "סהכ הזמנות",
  "widgets.totalVisitors": "סהכ מבקרים",
  "widgets.typeYourQuestions": "הקלד את השאלות שלך",
  "widgets.username": "שם משתמש",
  "widgets.password": "סיסמה",
  "widgets.signIn": "היכנס",
  "widgets.enterYourPassword": "הכנס את הסיסמה שלך",
  "widgets.alreadyHavingAccountLogin": "כבר יש לך כניסה לחשבון",
  "widgets.composeMail": "כתוב דואר",
  "widgets.issue": "נושא",
  "widgets.recentChat": "צ'אט אחרונים",
  "widgets.previousChat": "צ'אט הקודם",
  "widgets.all": "את כל",
  "widgets.filters": "מסננים",
  "widgets.deleted": "נמחק",
  "widgets.starred": "כיכב",
  "widgets.frontend": "חזיתי",
  "widgets.backend": "Backend",
  "widgets.api": "Api",
  "widgets.simpleAppBar": "פשוט אפליקציה בר",
  "widgets.recents": "דוכנים",
  "widgets.cardLink": "קישור כרטיס",
  "widgets.anotherLink": "קישור נוסף",
  "widgets.cardSubtitle": "כרטיס",
  "widgets.confirmationDialogs": "דיאלוגים לאישור",
  "widgets.deletableChip": "שבב נמחק",
  "widgets.customDeleteIconChip": "מחיקת סמל",
  "widgets.openAlertDialog": "פתח את תיבת הדו-שיח התראה",
  "widgets.openResponsiveDialog": "פתח את תיבת הדו-שיח 'תגובה'",
  "widgets.openSimpleDialog": "פתח דיאלוג פשוט",
  "widgets.openFormDialog": "פתח את תיבת הדו-שיח 'טופס'",
  "widgets.follower": "חָסִיד",
  "widgets.important": "חָשׁוּב",
  "widgets.private": "פְּרָטִי",
  "widgets.openLeft": "פתח שמאלה",
  "widgets.openRight": "פתח את 'ימין'",
  "widgets.openTop": "פתח את הדף",
  "widgets.openBottom": "פתח את תחתית",
  "widgets.selectTripDestination": "בחר יעד נסיעה",
  "widgets.pinnedSubheaderList": "רשימת כותרות משנה מוצמדות",
  "widgets.singleLineItem": "פריט יחיד",
  "widgets.acceptTerms": "קבל תנאים",
  "widgets.optionA": "אפשרות א",
  "widgets.optionB": "אפשרות ב '",
  "widgets.optionC": "אפשרות ג",
  "widgets.optionM": "אפשרות M",
  "widgets.optionN": "אפשרות N",
  "widgets.optionO": "אפשרות O",
  "widgets.customColor": "צבע מותאם אישית",
  "widgets.centeredTabs": "כרטיסיות מרוכזות",
  "widgets.multipleTabs": "כרטיסיות מרובות",
  "widgets.preventScrolledButtons": "מנע לחצני גלילה",
  "widgets.browse": "לְדַפדֵף",
  "widgets.formValidate": "טופס אימות",
  "widgets.code": "קוד",
  "widgets.company": "חֶברָה",
  "widgets.price": "מחיר",
  "widgets.change": "שינוי",
  "widgets.high": "גָבוֹהַ",
  "widgets.low": "נָמוּך",
  "widgets.volume": "כרך",
  "widgets.personalDetails": "פרטים אישיים",
  "widgets.occupation": "כיבוש",
  "widgets.companyName": "שם החברה",
  "widgets.phoneNo": "בטלפון",
  "widgets.city": "עִיר",
  "widgets.zipCode": "מיקוד",
  "widgets.updateProfile": "עדכן פרופיל",
  "widgets.reject": "לִדחוֹת",
  "widgets.exportToExcel": "יצוא ל - לְהִצטַיֵן",
  "widgets.addNewUser": "הוסף משתמש חדש",
  "widgets.workWeek": "שבוע עבודה",
  "widgets.agenda": "סֵדֶר הַיוֹם",
  "widgets.conference": "וְעִידָה",
  "widgets.multilevel": "רב",
  "widgets.dailySales": "מכירות יומיות",
  "widgets.today": "היום",
  "widgets.campaignPerformance": "ביצועי מסע פרסום",
  "widgets.supportRequest": "בקשת תמיכה",
  "widgets.usersList": "רשימת משתמשים",
  "widgets.lastWeek": "שבוע שעבר",
  "themeOptions.sidebarOverlay": "כיסוי שכבה צדדית",
  "themeOptions.sidebarBackgroundImages": "תמונות רקע בסרגל הצד",
  "themeOptions.sidebarImage": "תמונה של סרגל צדדי",
  "themeOptions.miniSidebar": "מיני הסרגל הצידי",
  "themeOptions.boxLayout": "פריסת תיבה",
  "themeOptions.rtlLayout": "פריסת Rtl",
  "themeOptions.darkMode": "מצב כהה",
  "themeOptions.appSettings": "הגדרות אפליקציה",
  "themeOptions.sidebarLight": "אוֹר",
  "themeOptions.sidebarDark": "אפל",
  "button.cancel": "לְבַטֵל",
  "button.add": "לְהוֹסִיף",
  "button.update": "עדכון",
  "button.reply": "תשובה",
  "button.delete": "לִמְחוֹק",
  "button.yes": "כן",
  "button.viewAll": "צפה בהכל",
  "button.like": "כמו",
  "button.assignNow": "הקצה עכשיו",
  "button.seeInsights": "ראה תובנות",
  "sidebar.dateTimePicker": "תאריך & תאריך בורר",
  "components.summary": "סיכום",
  "hint.whatAreYouLookingFor": "מה אתה מחפש",
  "components.yesterday": "אתמול",
  "components.last7Days": "שבעת הימים האחרונים",
  "components.last1Month": "1 חודש אחרון",
  "components.last6Month": "האחרון 6 חודש",
  "components.spaceUsed": "שטח המשמש",
  "components.followers": "עוקב",
  "components.trending": "מגמות",
  "components.paid": "שילם",
  "components.refunded": "החזר כספי",
  "components.done": "בוצע",
  "components.pending": "בהמתנה",
  "components.cancelled": "בוטל",
  "components.approve": "אשר",
  "components.week": "שבוע",
  "components.month": "חודש",
  "components.year": "שנה",
  "components.today": "היום",
  "components.popularity": "פופולריות",
  "components.email": "אֶלֶקטרוֹנִי",
  "components.drafts": "",
  "components.sent": "נשלח",
  "components.trash": "אשפה",
  "components.all": "את כל",
  "components.do": "עשה",
  "components.title": "כותרת",
  "components.projectName": "שם הפרויקט",
  "names.companyName": "שם החברה",
  "components.openAlert": "פתח התראה",
  "components.slideInAlertDialog": "שקופית בדיאלוג התראה",
  "components.openFullScreenDialog": "פתיחת תיבות דו-שיח מסך מלא",
  "components.basicChip": "שבב בסיסי",
  "components.clickableChip": "צ'יפ ללחיצה",
  "components.left": "שמאל",
  "components.right": "נכון",
  "components.expansionPanel1": "לוח הרחבה 1",
  "components.expansionPanel2": "לוח הרחבה 2",
  "settings.generalSetting": "הגדרה כללית",
  "components.advancedSettings": "הגדרות מתקדמות",
  "names.firstName": "שם פרטי",
  "names.lastName": "שם משפחה",
  "components.occupation": "עיסוק",
  "phones.phoneNo": "טלפון לא",
  "components.address": "כתובת",
  "components.city": "עיר",
  "components.state": "מדינה",
  "code.zip קוד": "מיקוד",
  "components.social Connection": "חיבור חברתי",
  "widgets.buyMore": "קנה עוד",
  "widgets.trafficChannel": "ערוץ תעבורה",
  "widgets.stockExchange": "הבורסה",
  "widgets.tweets": "Tweets",
  "widgets.ourLocations": "המיקומים שלנו",
  "widgets.sales": "מכירה",
  "widgets.to": "To",
  "widgets.shipTo": "משלוח אל",
  "widgets.description": "תיאור",
  "widgets.unitPrice": "מחיר היחידה",
  "widgets.total": 'סה"כ',
  "widgets.note": "הערה",
  "widgets.chipWithAvatar": "צ'יפ עם אווטר",
  "widgets.chipWithTextAvatar": "צ'יפ עם אווטר טקסט",
  "widgets.chipWithIconAvatar": "צ'יפ עם סמל גלגול",
  "widgets.customClickableChip": "צ'יפ הניתן ללחיצה אישית",
  "widgets.outlineChip": "צ'יפ מתאר",
  "widgets.disableChip": "השבת צ'יפ",
  "widgets.alertDialog": "התראת דיאלוג",
  "widgets.animatedSlideDialogs": "דיאלוגים שקופיות מונפשים",
  "widgets.fullScreenDialogs": "תיבות דו-שיח למסך מלא",
  "widgets.formDialogs": "טופס דיאלוגים",
  "widgets.simpleDialogs": "דיאלוגים פשוטים",
  "widgets.responsiveFullScreen": "מסך מלא תגובה",
  "widgets.primary": "ראשי",
  "widgets.social": "חברתי",
  "widgets.user": "משתמש",
  "widgets.admin": "מנהל מערכת",
  "widgets.permanentdrawer": "מגירה קבועה",
  "widgets.persistentdrawer": "מגירה מתמדת",
  "widgets.swiches": "Swiches",
  "widgets.horizontalLinearAlternativeLabel":
    "תווית אלטרנטיבית ליניארית אופקית",
  "widgets.horizontalNonLinearAlternativeLabel":
    "אופקי לא ליניארי תווית אלטרנטיבית",
  "widgets.notifications": "הודעות",
  "widgets.basicAlert": "התראה בסיסית",
  "widgets.successAlert": "התראה הצלחה",
  "widgets.warningAlert": "התראת אזהרה",
  "widgets.reactAutoSuggest": "תגובה אוטומטית",
  "widgets.components": "רכיבים",
  "widgets.inputAdornments": "הקלט",
  "widgets.multiSelectList": "רשימת רב",
  "widgets.contextualColoredTable": "טבלת צבעים צבעוניים",
  "widgets.updateYourEmailAddress": "עדכן את כתובת הדואר האלקטרוני שלך",
  "widgets.selectADefaultAddress": "בחר כתובת ברירת מחדל",
  "widgets.activity": "פעילות",
  "widgets.basicCalendar": "לוח שנה בסיסי",
  "widgets.culturesCalendar": "תרבויות לוח שנה",
  "widgets.dragAndDropCalendar": "גרור ושחרר את לוח השנה",
  "widgets.quillEditor": "עורך קוויל",
  "widgets.reactDND": "תגובה dnd",
  "widgets.dragula": "דראגולה",
  "button.acceptTerms": "קבל תנאים",
  "button.reject": "לִדחוֹת",
  "button.addNew": "הוסף חדש",
  "button.goToCampaign": "עבור אל מסע פרסום",
  "button.viewProfile": "צפה בפרופיל",
  "button.sendMessage": "לשלוח הודעה",
  "button.saveNow": "שמור עכשיו",
  "button.pen": "עֵט",
  "button.search": "לחפש",
  "button.downloadPdfReport": "הורד דוח PDF",
  "button.primary": "יְסוֹדִי",
  "button.secondary": "מִשׁנִי",
  "button.danger": "סַכָּנָה",
  "button.info": "מידע",
  "button.success": "הַצלָחָה",
  "button.warning": "אַזהָרָה",
  "button.link": "קישור",
  "button.smallButton": "כפתור קטן",
  "button.largeButton": "כפתור גדול",
  "button.blockLevelButton": "לחצן רמת בלוק",
  "button.primaryButton": "לחצן ראשי",
  "button.button": "לַחְצָן",
  "button.save": "להציל",
  "button.openMenu": "פתח תפריט",
  "button.openWithFadeTransition": "פתח עם מעבר לדעוך",
  "button.openPopover": "פתח את פופובר",
  "button.accept": "לְקַבֵּל",
  "button.click": "נְקִישָׁה",
  "button.complete": "לְהַשְׁלִים",
  "button.back": "חזור",
  "button.next": "הַבָּא",
  "button.completeStep": "שלב שלם",
  "button.error": "שְׁגִיאָה",
  "button.writeNewMessage": "כתוב הודעה חדשה",
  "button.saveChanges": "שמור שינויים",
  "button.addNewUser": "הוסף משתמש חדש",
  "button.more": "יותר",
  "hint.searchMailList": "חפש רשימת דואר",
  "widgets.AcceptorrRejectWithin": "קבל או דחה בתוך",
  "widgets.quoteOfTheDay": "ציטוט היום",
  "widgets.updated10Minago": "עודכן לפני 10 דקות",
  "widgets.personalSchedule": "לוח זמנים אישי",
  "widgets.activeUsers": "משתמשים פעילים",
  "widgets.totalRequest": 'סה"כ בקשה',
  "widgets.new": "חָדָשׁ",
  "widgets.ShareWithFriends": "לשתף עם חברים!",
  "widgets.helpToShareText":
    "עזור לנו להפיץ את העולם על ידי שיתוף האתר שלנו עם חברים וחברים שלך על מדיה חברתית!",
  "widgets.thisWeek": "השבוע",
  "widgets.howWouldYouRateUs": "איך היית מדרג אותנו?",
  "widgets.booking": "הזמנה",
  "widgets.confirmed": "מְאוּשָׁר",
  "widgets.monthly": "חודשי",
  "widgets.weekly": "שבועי",
  "widgets.target": "יעד",
  "widgets.totalActiveUsers": 'סה"כ משתמשים פעילים',
  "sidebar.user": "משתמש",
  "sidebar.miscellaneous": "שונות",
  "sidebar.promo": "פרומו",
  "themeOptions.themeColor": "צבע ערכת נושא",
  "module.inbox": "תיבת הדואר הנכנס",
  "module.drafts": "",
  "module.sent": "נשלח",
  "module.trash": "אשפה",
  "module.spam": "ספאם",
  "module.frontend": "חזיתי",
  "module.backend": "Backend",
  "module.api": "Api",
  "module.issue": "נושא",
  "components.emailPrefrences": 'העדפות דוא"ל',
  "components.myProfile": "הפרופיל שלי",
  "sidebar.gettingStarted": "מתחילים",
  "widgets.deadline": "מועד אחרון",
  "widgets.team": "קְבוּצָה",
  "widgets.projectManagement": "ניהול פרוייקט",
  "widgets.latestPost": "הפרסום האחרון",
  "widgets.projectTaskManagement": "ניהול משימות פרוייקט",
  "widgets.selectProject": "בחר פרויקט",
  "widgets.activityBoard": "לוח פעילות",
  "widgets.checklist": "צ'ק ליסט",
  "sidebar.shop": "לִקְנוֹת",
  "sidebar.cart": "עֲגָלָה",
  "sidebar.checkout": "לבדוק",
  "components.product": "מוצר",
  "components.quantity": "כַּמוּת",
  "components.totalPrice": "מחיר סופי",
  "components.removeProduct": "הסר מוצר",
  "components.mobileNumber": "מספר טלפון נייד",
  "components.address2Optional": "כתובת 2 (אופציונלי)",
  "components.country": "מדינה",
  "components.zip": "רוכסן",
  "components.saveContinue": "שמור המשך",
  "components.placeOrder": "בצע הזמנה",
  "components.payment": "תַשְׁלוּם",
  "components.billingAddress": "כתובת לחיוב",
  "components.ShippingAddressText": "כתובת המשלוח זהה לכתובת לחיוב.",
  "components.CartEmptyText": "עגלת הקניות שלך ריקה!",
  "components.NoItemFound": "לא נמצא פריט",
  "components.goToShop": "לך לחנות",
  "components.cardNumber": "מספר כרטיס",
  "components.expiryDate": "תאריך תפוגה",
  "components.cvv": "CVV",
  "components.nameOnCard": "שם על כרטיס",
  "components.confirmPayment": "אשר תשלום",
  "sidebar.saas": "ס.א.ס.",
  "sidebar.multiLevel": "מדורג",
  "sidebar.level1": "שלב 1",
  "sidebar.level2": "שלב 2",
  "sidebar.boxed": "מאורגן",
  "sidebar.news": "חֲדָשׁוֹת",
  "sidebar.extensions": "תוספים",
  "sidebar.imageCropper": "תמונה קרופר",
  "sidebar.videoPlayer": "נגן וידאו",
  "sidebar.dropzone": "איזור נפילה",
  "widgets.baseConfig": "תצורת Base",
  "widgets.customControlBar": "סרגל בקרה מותאם אישית",
  "widgets.withDownloadButton": "עם לחצן הורדה",
  "widgets.httpLiveStreaming": "HTTP הזרמת חי",
  "widgets.keyboardShortcuts": "קיצורי דרך במקלדת",
  "button.useDefaultImage": "השתמש בתמונת ברירת מחדל",
  "button.cropImage": "חתוך תמונה",
  "widgets.preview": "תצוגה מקדימה",
  "widgets.croppedImage": "תמונה חתוכה",
  "sidebar.crm": "CRM",
  "widgets.transactionList": "רשימת עסקאות",
  "widgets.transferReport": "- דוח העברה",
  "widgets.expenseCategory": "- הוצאה",
  "widgets.upcomingEvents": "אירועים קרובים",
  "widgets.OngoingProjects": "פרויקטים מתמשכים",
  "widgets.ProjectStatus": "מצב הפרויקט",
  "widgets.LiveChatSupport": "תמיכה בצ'אט חי",
  "sidebar.projects": "פרויקטים",
  "sidebar.projectDetail": "פירוט הפרויקט",
  "sidebar.clients": "לקוחות",
  "sidebar.reports": "דיווחים",
};
