/**
 * CRM Reducer
 */
//action types
import {} from "Store/Actions/types";
console.log("REACT_APP_FUNCTION_URL", process.env.REACT_APP_FUNCTION_URL);
const INITIAL_STATE = {
  // function_url: "http://127.0.0.1:5001/goldfinch-dev/us-central1",
  // function_url: "http://127.0.0.1:5001/goldfinch-staging-205e4/us-central1",
  function_url: process.env.REACT_APP_FUNCTION_URL || "https://us-central1-goldfinch-c2012.cloudfunctions.net",
};

let Cf = (state = INITIAL_STATE, action) => {
  return state;
};

export default Cf;
