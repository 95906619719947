import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { FormGroup, Input } from "reactstrap";
import Button from "@material-ui/core/Button";
import GoogleMap from "../../Components/GoogleMap/GoogleMap";
import { Backdrop, CircularProgress, Grid, Tooltip } from "@material-ui/core";
import LocationApi from "../../Container/locationapi";
import _ from "underscore";
import { useSelector } from "react-redux";
import { OpeningHours } from "../../Components/OpeningHours";
import Header from "../../Components/NewHeader";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    "height": 24,
    "width": 24,
    "backgroundColor": "#fff",
    "border": "2px solid currentColor",
    "marginTop": -8,
    "marginLeft": -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const FranchiseForm = ({ handleSubmit, loading, setLoading, errors, type = "create", defaultValues = null, title }) => {
  const [address, setAddress] = useState();
  const [fullAddress, setFullAddress] = useState();

  const [name, setName] = useState(null);
  const [openingHoursObj, setOpeningHoursObj] = useState({});
  const localUserData = useSelector((state) => state.authUser.userDaata);

  const [latitude, setLatitude] = useState(localUserData?.latitude);
  const [longitude, setLongitude] = useState(localUserData?.longitude);
  const [matchRange, setMatchRange] = useState(3000);
  const getAddress = (address, lat, lng) => {
    if (lat && lng) {
      setLatLng(lat, lng);
    }
    if (address.length > 50) {
      setAddress(address.substr(0, 50) + "...");
      setFullAddress(address);
    } else {
      setAddress(address);
      setFullAddress(address);
    }
  };

  function setLatLng(lat, lng) {
    setLatitude("");
    setLongitude("");
    setLatitude(lat);
    setLongitude(lng);
  }

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setAddress(defaultValues.address);
      setLatitude(defaultValues.latitude);
      setLongitude(defaultValues.longitude);
      setMatchRange(defaultValues.matchRange || 3000);
    }
    console.log(defaultValues, "defaultValues");
  }, [defaultValues]);

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 10 }} style={{ width: "100%", zIndex: 1, height: "100vh" }} open={loading}>
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      <Header title={title} />
      <Grid container>
        <Grid item xs={12} className="paddingH-50">
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="text1" className="d-flex justify-content-start align-items-center mb-10">
                Franchise Name{" "}
                <Tooltip
                  className="ml-10"
                  title="Please type the name of your franchise here. We recommend that you type the name of your area, followed by a space and then the name of your brand."
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Typography>
              <Input value={name} onChange={(e) => setName(e.target.value)} error={errors.name} />
              {errors.name && (
                <Typography variant="caption" color="error">
                  {errors.name}
                </Typography>
              )}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant="text1" className="d-flex justify-content-start align-items-center mb-10">
                Location{" "}
                <Tooltip className="ml-10" title="Type in the correct address of your physically available store.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Typography>

              <LocationApi getAddress={getAddress} address={address} />
              {errors.address && (
                <Typography variant="caption" color="error">
                  {errors.address}
                </Typography>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FormGroup>
                <div style={{}}>
                  <div
                    style={{
                      //   position: "absolute",
                      //   top: 0,
                      height: 400,
                      width: "100%",
                    }}
                  >
                    {latitude && longitude ? (
                      <GoogleMap
                        lat={latitude}
                        lng={longitude}
                        latitude={latitude}
                        longitude={longitude}
                        range={matchRange}
                        getAddress={getAddress}
                      />
                    ) : null}
                  </div>
                </div>
              </FormGroup>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="text1" className="d-flex justify-content-start align-items-center mb-10">
                Diameter{" "}
                <Tooltip className="ml-10" title="Type in the correct address of your physically available store.">
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Typography>
              <PrettoSlider
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                min={1}
                max={5}
                defaultValue={3000}
                value={(matchRange || 3000) / 1000}
                onChange={(e, value) => setMatchRange(value * 1000)}
              />
            </Grid>

            <Grid item xs={12}>
              <OpeningHours
                previousSavedData={defaultValues}
                openingHoursObj={openingHoursObj}
                setOpeningHoursObj={setOpeningHoursObj}
                showAllDays={true}
                hasMarginTop={false}
              />
            </Grid>
            <Grid item xs={12} align="center" className=" franchise-footer">
              <Button
                className="btn-dark text-white btn-block w-70"
                variant="contained"
                size="large"
                type={"button"}
                onClick={() =>
                  handleSubmit({
                    name,
                    address,
                    latitude,
                    longitude,
                    restaurantId: localUserData?.uid,
                    openingHours: openingHoursObj,
                    restaurantId: localUserData?.uid,
                    matchRange: matchRange,
                  })
                }
              >
                {type === "create" ? "Create" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default FranchiseForm;
