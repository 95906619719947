import * as React from "react";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, CircularProgress } from "@material-ui/core";
import { db } from "../../Firebase";
import Header from "./../../Components/NewHeader";
import moment from "moment";
import ViewIcon from "../../Assets/img/view.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleClickOpen = (value) => {
    setFeedback(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      name: "userRef",
      label: "ID",
      options: {
        customBodyRender: (value) => {
          return value?.id;
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        customBodyRender: (value) =>
          moment(new Date(value?.seconds * 1000)).format("MMM-DD-YYYY"),
      },
    },
    {
      name: "userRef",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value?.fullName;
        },
      },
    },
    {
      name: `userRef`,
      label: "Email",
      options: {
        customBodyRender: (value) => {
          return value?.email;
        },
      },
    },
    {
      name: `partnerRef`,
      label: "Partner Name",
      options: {
        customBodyRender: (value) => {
          return value?.companyName;
        },
      },
    },
    {
      name: `feedback`,
      label: "Feedback",
      options: {
        customBodyRender: (value) => {
          const maxLength = 30;
          if (value?.length > maxLength) {
            return value?.substr(0, maxLength) + "...";
          }
          return value;
        },
      },
    },
    {
      name: "feedback",
      label: "Actions",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <img
              style={{ cursor: "pointer", margin: "0 5px" }}
              onClick={(event) => {
                handleClickOpen(value);
              }}
              src={ViewIcon}
              alt={"View details"}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    getData()
      .then((data) => {
        setReports(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const getData = async () => {
    return new Promise((res, rej) => {
      try {
        db.collection("reports")
          .get()
          .then(async (querySnapshot) => {
            const data = [];

            querySnapshot.forEach((doc) => {
              const convertedDoc = doc.data();
              data.push({ ...convertedDoc, id: doc.id });
            });

            for (let doc of data) {
              if (doc.userRef) {
                const userDoc = await doc.userRef.get();
                doc.userRef = userDoc.data();
              }
              if (doc.partnerRef) {
                const partnerDoc = await doc.partnerRef.get();
                doc.partnerRef = partnerDoc.data();
              }
            }
            res(data);
          });
      } catch (e) {
        rej(e);
      }
    });
  };

  return loading ? (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress disableShrink />
    </div>
  ) : (
    <>
      <Header title="Users Feedback" onRefresh={getData} />
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <MUIDataTable
          // title={
          //   <h1 style={{ color: "#242424" }} className={"font-weight-bold"}>
          //     Abuse Reports
          //   </h1>
          // }
          data={reports || []}
          columns={columns}
          options={{
            selectableRows: false,
            print: false,
            download: false,
          }}
        />
      </div>

      <Dialog open={open} keepMounted onClose={handleClose}>
        <DialogTitle style={{ minWidth: 400 }}>{"User Feedback"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{feedback}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reports;
