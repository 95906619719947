import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Form, FormGroup, Input, Label } from "reactstrap";
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { Grid, Select, MenuItem } from "@material-ui/core";
import Header from "./../../Components/NewHeader";
import { convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { auth } from "../../Firebase";

export default function Notification_Send(props) {
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());

  const onSend = async (event) => {
    event.preventDefault();

    const text = editorState.getCurrentContent().getPlainText();
    if (!text || !event.target.title.value || !event.target.type.value) return;

    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let fetchURL = function_url + "/emailBroadcast";
    // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/emailBroadcast";
    const authToken = await auth.currentUser.getIdToken();

    try {
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": authToken },
        body: JSON.stringify({
          subject: event.target.title.value,
          text: text,
          html: html,
          type: event.target.type.value,
        }),
      });
      if (response.ok) {
        let result = await response.json();
        NotificationManager.info(result.message);
      }
    } catch (err) {
      console.log(err, "error response");
      NotificationManager.error(JSON.stringify(err));
    }
  };

  useEffect(() => {}, []);

  return (
    <div>
      <Header title="Email Broadcast" />
      <Form onSubmit={onSend} style={{ marginTop: "", paddingLeft: 20, paddingRight: 20 }}>
        <Grid container spacing={2}>
          <Grid item md={4} sm={12} xs={12}>
            <Label style={{ fontSize: 17, marginBottom: 8 }}>Type</Label>
            <div>
              <Select style={{ width: "100%", height: 48, borderRadius: 8 }} variant="outlined" name="type">
                <MenuItem value="partners">Partners</MenuItem>
                <MenuItem value="users">Users</MenuItem>
              </Select>
            </div>
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <FormGroup>
              <Label required style={{ fontSize: 17 }}>
                Title
              </Label>
              <Input
                type="text"
                required
                name="title"
                id="title"
                style={{ height: "55px !important" }}
                className="has-input input-lg"
                placeholder="Title"
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Label required style={{ fontSize: 17 }}>
              Email
            </Label>
            <div
              style={{
                border: "1px solid #e6e6e6",
                borderRadius: 8,
              }}
            >
              <Editor
                spellCheck={true}
                editorState={editorState}
                editorClassName="htmlEditorContentArea"
                onEditorStateChange={setEditorState}
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FormGroup className="mb-15">
              <Button
                className="btn-dark text-white btn-block w-50"
                style={{ margin: "0 auto", display: "block" }}
                variant="contained"
                size="large"
                type="submit"
              >
                Send
              </Button>
            </FormGroup>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
