import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VerificationInput from "react-verification-input";
import { Form, FormFeedback, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { db } from "./../Firebase";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { useSelector } from "react-redux";

export default function ConfirmationModal({ open, setOpen }) {
  const function_url = useSelector((state) => state?.Cf?.function_url || "");
  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false);
  const [value, setValue] = useState('');
  const [codeDetails, setCodeDetails] = useState(null);
  const [passwords, setPasswords] = useState({
    password: '',
    confirmPassword: ''
  });
  

  const handleClose = () => {
    setShowUpdatePasswordForm(false);
    setCodeDetails(null);
    setValue(null);
    setPasswords({
      password: '',
      confirmPassword: ''
    });
    setOpen(false);
  };

  const handleCodeSubmit = async () => {
    try {
      if(!value) return;
      const codes = await db.collection('forgotPassword').where('code', '==', parseInt(value)).get();
      if(!codes.docs.length) {
        NotificationManager.error("Code Invalid");
        return;
      }

      const detail = codes.docs[0].data();
      
      const expiry = moment(new Date(detail?.expiresOn?.seconds * 1000));
      const isExpired = expiry.isSameOrAfter(moment());
      if (!isExpired) {
        NotificationManager.error("Code Expired");
        return;
      }

      setCodeDetails(detail)
      setShowUpdatePasswordForm(true);
    } catch (error) {
      console.log(error);
      NotificationManager.error("Something Failed");
    }
  }

  const handlePasswordSubmit = async () => {
    try {
      if(!passwords.password || !passwords.confirmPassword || passwords.confirmPassword !== passwords.password) {
        NotificationManager.error("Password do not match");
        return;
      };

      if(!codeDetails || !codeDetails?.email || !codeDetails?.code) {
        NotificationManager.error("Please try again something failed!");
        return;
      };
      
      let fetchURL = function_url + "/verifyCode";
      // let fetchURL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/verifyCode";
      let response = await fetch(fetchURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: codeDetails.email,
          code: codeDetails.code,
          password: passwords.password,
        }),
      });
      
      const data = await response.json();

      if(data?.success) {
        NotificationManager.success("Password Updated");
        handleClose();
      }

    } catch (error) {
      console.log(error);
      NotificationManager.error("Something Failed");
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirmation Code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!showUpdatePasswordForm ? 
            'Add your password conformation code here.': 
            'Add your new password'
          }

        </DialogContentText>
        {!showUpdatePasswordForm ? (
          <Grid container className="remove-input-styles">
            <VerificationInput
              value={value}
              onChange={setValue}
              classNames={{
                container: "container",
                character: "character",
                characterInactive: "character--inactive",
                characterSelected: "character--selected",
              }}
              length={4}
            />
          </Grid>
        ) : (
          <Form
            id="resetForm"
            style={{ width: "90%" }}
          >
            <FormGroup className="has-wrapper text-left">
              <Label htmlFor="password">Password</Label>
              <InputGroup>
                <Input
                  onChange={(e) => setPasswords({ ...passwords, password: e.target.value })}
                  type="password"
                  name="password"
                  id="password"
                  className="has-input input-lg"
                  placeholder="Enter password"
                  feedback={true}
                  required
                />
                <FormFeedback name="email" />
              </InputGroup>

              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <InputGroup>
                <Input
                  onChange={(e) => setPasswords({ ...passwords, confirmPassword: e.target.value })}
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="has-input input-lg"
                  placeholder="Enter confirm password"
                  feedback={true}
                  required
                />
                <FormFeedback name="email" />
              </InputGroup>
            </FormGroup>
          </Form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={showUpdatePasswordForm ? handlePasswordSubmit : handleCodeSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
