import moment from "moment";
import { db } from "../Firebase";

const getActiveSubscriptions = async (userId) => {
  const subscriptions = await db.collection("subscriptions").where("uid", "==", userId).get();

  return subscriptions.docs.map((doc) => doc.data());
};

const getAllPackages = async () => {
  const subscriptions = await db.collection("packages").get();
  return subscriptions.docs.map((doc) => doc.data());
};

const getUserFranchises = async (userId) => {
  const franchises = await db.collection("franchises").where("restaurantId", "==", userId).get();

  return franchises.size;
};

const getUserCampaigns = async (userId, date) => {
  const [activeCampaigns, deletedCampaigns] = await Promise.all([
    db
    .collection("drenches")
    .where("restaurantID", "==", userId)
    .where("drenchTimeStart", ">=", date.toDate())
    .get(),
    db
    .collection("deletedCampaigns")
    .where("restaurantID", "==", userId)
    .where("drenchTimeStart", ">=", date.toDate())
    .get()
  ]);

  const finches = [];
  const goldfinches = [];

  activeCampaigns.docs.forEach((doc) => {
    const campaign = doc.data();
    if (campaign.type === "drizzles") {
      finches.push(campaign);
    } else if (campaign.type === "drenches") {
      goldfinches.push(campaign);
    }
  });

  deletedCampaigns.docs.forEach((doc) => {
    const campaign = doc.data();
    if (campaign.type === "drizzles") {
      finches.push(campaign);
    } else if (campaign.type === "drenches") {
      goldfinches.push(campaign);
    }
  });

  return [finches.length, goldfinches.length];
};

export const getActivePackagesItemsDetails = async (userId) => {
  const [subscriptions, packages, franchises] = await Promise.all([
    getActiveSubscriptions(userId),
    getAllPackages(),
    getUserFranchises(userId),
  ]);

  let oldestSubscriptionStartDate = moment();
  const { campaign, franchise } = subscriptions.reduce(
    ({ campaign, franchise }, subscription) => {
      const current = moment();
      const startDate = moment(subscription.current_period_start * 1000);
      const endDate = moment(subscription.current_period_end * 1000);

      if(startDate.isBefore(oldestSubscriptionStartDate)) oldestSubscriptionStartDate = startDate.startOf('day');

      if (subscription.packageType === "CAMPAIGN" && current.isBetween(startDate, endDate)) {
        campaign.push(subscription);
      } else if (subscription.packageType === "FRANCHISE" && current.isBetween(startDate, endDate)) {
        franchise.push(subscription);
      }

      return { campaign, franchise };
    },
    { campaign: [], franchise: [] }
  );

  const campaigns = await getUserCampaigns(userId, oldestSubscriptionStartDate);

  const campaignPackages = campaign.reduce(
    ({ goldfinches, finches }, subscription) => {
      if (subscription?.isAssigned) {
        return {
          goldfinches:
            subscription?.rewardType.toUpperCase() === "GOLDFINCH" ? goldfinches + subscription?.quantity : goldfinches,
          finches: subscription?.rewardType.toUpperCase() === "FINCH" ? finches + subscription?.quantity : finches,
        };
      }
      const packageDetails = packages.find((packageItem) => packageItem.stripeProductId === subscription.plan.product);
      return {
        goldfinches: goldfinches + packageDetails?.numberOfGoldFinches,
        finches: finches + packageDetails?.numberOfFinches,
      };
    },
    { goldfinches: 0, finches: 0 }
  );

  const franchisePackages = franchise.reduce((franchisesNumber, subscription) => {
    // const packageDetails = packages.find((packageItem) => packageItem.stripeProductId === subscription.plan.product);
    return franchisesNumber + subscription.quantity;
  }, 0);

  return {
    campaign: {
      goldfinches: campaignPackages.goldfinches - campaigns[1],
      finches: campaignPackages.finches - campaigns[0],
    },
    franchise: franchisePackages - franchises,
  };
};

export const getActiveFranchisePackages = async (userId) => {
  const subscriptions = await getActiveSubscriptions(userId);

  const franchise = subscriptions.reduce((franchisesNumber, subscription) => {
    if (subscription.packageType === "FRANCHISE") {
      if (
        moment().isBetween(
          moment(subscription?.current_period_start * 1000),
          moment(subscription?.current_period_end * 1000)
        )
      ) {
        return franchisesNumber + subscription.quantity;
      }
      return franchisesNumber;
    }
    return franchisesNumber;
  }, 0);
  
  return franchise;
};
