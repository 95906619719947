/**
 * Rct Collapsible Card
 */
import React, { useState } from "react";
import { Collapse, Badge } from "reactstrap";
import classnames from "classnames";

import Button from "@material-ui/core/Button";
// rct section loader
import RctSectionLoader from "../RctSectionLoader/RctSectionLoader";
// import EditProfile from "../../Routes/account-request/editProfile";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useLocation } from "react-router-dom";
import SignupFirebase from "Routes/session/register";
// import CreateAccount from "Routes/account-request/createAccount";

function RctCollapsibleCard(props) {
  const [reload, setReload] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [close, setClose] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [singleUserData, setsingleUserData] = useState(false);

  const location = useLocation();
  // console.log(location, "route");
  const onReload = (e) => {
    e.preventDefault();
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1500);
  };

  const {
    children,
    collapsible,
    closeable,
    reloadable,
    heading,
    fullBlock,
    colClasses,
    customClasses,
    headingCustomClasses,
    contentCustomClasses,
    badge,
    drizzleBtnTitle,
  } = props;

  const ViewModalFunc = () => {
    return (
      <div>
        <Modal
          isOpen={viewModal}
          toggle={() => viewToggle()}
          className="custom-modal-style-drench"
        >
          <ModalHeader toggle={viewToggle}>Create Account</ModalHeader>
          <ModalBody className="custom-modal-style-drench">
            <div>
              {/* <EditProfile
                toggle={viewToggle}
                singleUserData={singleUserData}
              /> */}
              {/* <CreateAccount toggle={viewToggle} setViewModal={setViewModal} /> */}
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    );
  };
  const viewToggle = () => setViewModal(!viewModal);

  const viewData = () => {
    viewToggle();
  };

  return (
    <div
      className={classnames(colClasses ? colClasses : "", {
        "d-block": !collapse,
      })}
    >
      {ViewModalFunc()}
      <div
        className={classnames(
          `rct-block ${customClasses ? customClasses : ""}`,
          { "d-none": close }
        )}
      >
        {heading && (
          <div
            className={`rct-block-title ${
              headingCustomClasses ? headingCustomClasses : ""
            }`}
          >
            <h4>
              {heading}{" "}
              {badge && (
                <Badge className="p-1 ml-10" color={badge.class}>
                  {badge.name}
                </Badge>
              )}
            </h4>
            {/* {(collapsible || reloadable || closeable) && (
              <div className='contextual-link'>
                {collapsible && (
                  <a
                    href='!#'
                    onClick={e => {
                      e.preventDefault()
                      setCollapse(!collapse)
                    }}
                  >
                    <i className='ti-minus'></i>
                  </a>
                )}
                {reloadable && (
                  <a href='!#' onClick={e => onReload(e)}>
                    <i className='ti-reload'></i>
                  </a>
                )}
                {closeable && (
                  <a
                    href='!#'
                    onClick={e => {
                      e.preventDefault()
                      setClose(true)
                    }}
                  >
                    <i className='ti-close'></i>
                  </a>
                )}
              </div>
            )} */}
          </div>
        )}
        {/* {loading ? loadingFor === key ? <CircularProgress color={'white'} size={30} /> : 'Delete' : 'Delete'} */}
        {/* {location.pathname === '/app/video-player' && ( */}
        <div style={{ position: "absolute", right: 20, top: 20 }}>
          {props.drizzleBtnTitle && (
            <Button
              onClick={() => {
                props?.addDrenchModal("drizzles");
                //console.log(props.drizzleBtnTitle + " clicked");
              }}
              variant="contained"
              color="primary"
              className="bg-yellow-new"
              style={{
                marginRight:
                  props.drenchBtnTitle || props.accountBtnTitle ? 20 : 0,
              }}
            >
              {props.drizzleBtnTitle}
            </Button>
          )}
          {props.drenchBtnTitle && (
            <Button
              // onClick={ViewModalFunc}
              /*onClick={() => viewData()}*/
              onClick={() => {
                props?.addDrenchModal("drenches");
                //console.log(props.btnTitle + " clicked");
              }}
              variant="contained"
              color="primary"
              className="bg-yellow-new"
              style={{ marginRight: props.accountBtnTitle ? 20 : 0 }}
            >
              {props.drenchBtnTitle}
            </Button>
          )}
          {props.accountBtnTitle && (
            <Button
              // onClick={ViewModalFunc}
              onClick={() => viewData()}
              variant="contained"
              color="primary"
              className="bg-yellow-new"
            >
              {props.accountBtnTitle}
            </Button>
          )}
        </div>
        {/* )} */}
        <Collapse isOpen={collapse}>
          <div
            className={classnames(
              contentCustomClasses ? contentCustomClasses : "",
              { "rct-block-content": !fullBlock, "rct-full-block": fullBlock }
            )}
          >
            {children}
          </div>
        </Collapse>
        {reload && <RctSectionLoader />}
      </div>
    </div>
  );
}

export default RctCollapsibleCard;
