import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { collapsedSidebarAction } from "Store/Actions";
import ReplayIcon from "@material-ui/icons/Replay";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: 20,
    color: "#242424",
  },
  appBar: {
    backgroundColor: "#Fff",

    paddingLeft: 20,
    paddingRight: 20,
  },
  hamburger: {
    fontSize: 28,
    color: "#707070",
  },
}));

export default function ButtonAppBar({ title, onRefresh }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const onToggleNavCollapsed = (event) => {
    const val = settings.navCollapsed ? false : true;
    dispatch(collapsedSidebarAction(val));
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.appBar}>
          <IconButton
            color="inherit"
            mini="true"
            aria-label="Menu"
            className="humburger p-2"
            edge="start"
            onClick={onToggleNavCollapsed}
          >
            <MenuIcon className={classes.hamburger} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {Boolean(onRefresh) && (
            <Button
              variant="link"
              onClick={onRefresh}
              startIcon={<ReplayIcon />}
              style={{ color: "#11A4C2", fontWeight: "bold" }}
            >
              Refresh
            </Button>
          )}
        </Toolbar>
        <div className={"theme-line-breaker mb-4"} />
      </AppBar>
    </div>
  );
}
