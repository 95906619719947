import { Grid, Switch, Tooltip } from "@material-ui/core";
import { FormGroup as InputGroup, Input, Label } from "reactstrap";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export const OpeningHours = ({
  showAllDays = false,
  setOpeningHoursObj,
  openingHoursObj,
  previousSavedData: singleDrenchData = null,
  hasMarginTop = false,
}) => {
  const [key, setKey] = useState(false);
  const [startTimeModay, setStartTimeMonday] = useState(moment().format("HH:MM"));
  const [endTimeModay, setEndTimeMonday] = useState(moment().format("HH:MM"));
  const [saturday, onSelectSaturday] = useState(false);
  const [startTimeSaturday, setStartTimeSaturday] = useState(moment().format("HH:MM"));
  const [endTimeSaturday, setEndTimeSaturday] = useState(moment().format("HH:MM"));
  const [tuesday, onSelectTuesday] = useState(false);
  const [startTimeTuesday, setStartTimeTuesday] = useState(moment().format("HH:MM"));
  const [endTimeTuesday, setEndTimeTuesday] = useState(moment().format("HH:MM"));
  const [wednesday, onSelectWednesday] = useState(false);
  const [startTimeWednesday, setStartTimeWednesday] = useState(moment().format("HH:MM"));
  const [endTimeWednesday, setEndTimeWednesday] = useState(moment().format("HH:MM"));
  const [thursday, onSelectThursday] = useState(false);
  const [startTimeThursday, setStartTimeThursday] = useState(moment().format("HH:MM"));
  const [endTimeThursday, setEndTimeThursday] = useState(moment().format("HH:MM"));
  const [friday, onSelectFriday] = useState(false);
  const [startTimeFriday, setStartTimeFriday] = useState(moment().format("HH:MM"));
  const [endTimeFriday, setEndTimeFriday] = useState(moment().format("HH:MM"));
  const [sunday, onSelectSunday] = useState(false);
  const [startTimeSunday, setStartTimeSunday] = useState(moment().format("HH:MM"));
  const [endTimeSunday, setEndTimeSunday] = useState(moment().format("HH:MM"));
  const [selectAll, onSelectAll] = useState(false);
  const [startTimeAll, setStartTimeAll] = useState(moment().format("HH:MM"));
  const [endTimeAll, setEndTimeAll] = useState(moment().format("HH:MM"));
  const [monday, onSelectMonday] = useState(false);

  const toggleSwitchAll = (key) => {
    setKey(!key);
    onSelectAll(!key);
    onSelectMonday(!key);
    onSelectTuesday(!key);
    onSelectWednesday(!key);
    onSelectThursday(!key);
    onSelectFriday(!key);
    onSelectSaturday(!key);
    onSelectSunday(!key);
    setStartTimeMonday(startTimeAll);
    setEndTimeMonday(endTimeAll);
    setEndTimeTuesday(endTimeAll);
    setStartTimeTuesday(startTimeAll);
    setStartTimeWednesday(startTimeAll);
    setEndTimeWednesday(endTimeAll);
    setStartTimeThursday(startTimeAll);
    setEndTimeThursday(endTimeAll);
    setStartTimeFriday(startTimeAll);
    setEndTimeFriday(endTimeAll);
    setStartTimeSaturday(startTimeAll);
    setEndTimeSaturday(endTimeAll);
    setStartTimeSunday(startTimeAll);
    setEndTimeSunday(endTimeAll);
  };

  const onChangeAll = (e) => {
    setStartTimeMonday(e);
    setStartTimeTuesday(e);
    setStartTimeWednesday(e);
    setStartTimeThursday(e);
    setStartTimeFriday(e);
    setStartTimeSaturday(e);
    setStartTimeSunday(e);
    //  setStartTimeMonday(startTimeAll)
    //  setEndTimeMonday(endTimeAll)
    //  setEndTimeTuesday(endTimeAll)
    //  setEndTimeWednesday(endTimeAll)
    //  setEndTimeFriday(endTimeAll)
    //  setEndTimeSaturday(endTimeAll)
    //  setEndTimeSunday(endTimeAll)
    //  setEndTimeMonday(e)
  };
  const onChangeAllEnd = (e) => {
    setEndTimeMonday(e);
    setEndTimeTuesday(e);
    setEndTimeWednesday(e);
    setEndTimeThursday(e);
    setEndTimeFriday(e);
    setEndTimeSaturday(e);
    setEndTimeSunday(e);
  };

  const toggleSwitch = (monday) => {
    onSelectMonday(!monday);
  };

  const toggleSwitchThusday = (tuesday) => {
    onSelectTuesday(!tuesday);
  };

  const toggleSwitchWednesday = (wednesday) => {
    onSelectWednesday(!wednesday);
  };

  const toggleSwitchThurday = (thursday) => {
    onSelectThursday(!thursday);
  };

  const toggleSwitchFriday = (friday) => {
    onSelectFriday(!friday);
  };

  const toggleSwitchSturday = (saturday) => {
    onSelectSaturday(!saturday);
  };

  const toggleSwitchSunday = (sunday) => {
    onSelectSunday(!sunday);
  };

  const formatTime = ({ day, dayName, start, end }) => {
    let temp = {};
    const startTime = moment.isMoment(start) ? start.format("HH:mm").split(":") : start.split(":");
    const endTime = moment.isMoment(end) ? end.format("HH:mm").split(":") : end.split(":");
    if (start) {
      temp = {
        ...temp,
        [dayName]: {
          openingHour: parseInt(startTime[0]),
          openingMinute: parseInt(startTime[1]),
          closingHour: parseInt(endTime[0]),
          closingMinute: parseInt(endTime[1]),
        },
      };
    }
    if (end) {
      temp = {
        ...temp,
        [dayName]: {
          closingHour: parseInt(endTime[0]),
          closingMinute: parseInt(endTime[1]),
          openingHour: parseInt(startTime[0]),
          openingMinute: parseInt(startTime[1]),
        },
      };
    }
    return temp;
  };

  const formatData = () => {
    let openingHours = { ...openingHoursObj };
    const arr = [
      {
        day: monday,
        dayName: "monday",
        start: startTimeModay,
        end: endTimeModay,
      },
      {
        day: tuesday,
        dayName: "tuesday",
        start: startTimeTuesday,
        end: endTimeTuesday,
      },
      {
        day: wednesday,
        dayName: "wednesday",
        start: startTimeWednesday,
        end: endTimeWednesday,
      },
      {
        day: thursday,
        dayName: "thursday",
        start: startTimeThursday,
        end: endTimeThursday,
      },
      {
        day: friday,
        dayName: "friday",
        start: startTimeFriday,
        end: endTimeFriday,
      },
      {
        day: saturday,
        dayName: "saturday",
        start: startTimeSaturday,
        end: endTimeSaturday,
      },
      {
        day: sunday,
        dayName: "sunday",
        start: startTimeSunday,
        end: endTimeSunday,
      },
    ];
    arr.forEach((data) => {
      if (data.day === false) {
        return delete openingHours[data.dayName];
      }
      openingHours = { ...openingHours, ...formatTime(data) };
    });
    return openingHours;
  };

  useEffect(() => {
    if (singleDrenchData && singleDrenchData.openingHours) {
      const today = new Date();
      if (singleDrenchData.openingHours.monday) {
        let dayStr = singleDrenchData.openingHours.monday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );

        onSelectMonday(true);
        setStartTimeMonday(moment(startTimeday));
        setEndTimeMonday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.tuesday) {
        let dayStr = singleDrenchData.openingHours.tuesday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectTuesday(true);
        setStartTimeTuesday(moment(startTimeday));
        setEndTimeTuesday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.wednesday) {
        let dayStr = singleDrenchData.openingHours.wednesday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectWednesday(true);
        setStartTimeWednesday(moment(startTimeday));
        setEndTimeWednesday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.thursday) {
        let dayStr = singleDrenchData.openingHours.thursday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectThursday(true);
        setStartTimeThursday(moment(startTimeday));
        setEndTimeThursday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.friday) {
        let dayStr = singleDrenchData.openingHours.friday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectFriday(true);
        setStartTimeFriday(moment(startTimeday));
        setEndTimeFriday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.saturday) {
        let dayStr = singleDrenchData.openingHours.saturday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectSaturday(true);
        setStartTimeSaturday(moment(startTimeday));
        setEndTimeSaturday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.sunday) {
        let dayStr = singleDrenchData.openingHours.sunday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectSunday(true);
        setStartTimeSunday(moment(startTimeday));
        setEndTimeSunday(moment(endTimeday));
      }
    }
    // if (singleDrenchData?.openingHours?.sunday) {
    //   let dayStr = singleDrenchData.openingHours.sunday;
    //   let startTimeday = new Date(
    //     today.getFullYear(),
    //     today.getMonth(),
    //     today.getDate(),
    //     dayStr.openingHour,
    //     dayStr.openingMinute,
    //   );
    //   let endTimeday = new Date(
    //     today.getFullYear(),
    //     today.getMonth(),
    //     today.getDate(),
    //     dayStr.closingHour,
    //     dayStr.closingMinute,
    //   );
    // }
  }, [singleDrenchData]);

  useEffect(() => {
    setOpeningHoursObj(formatData());
  }, [
    key,
    startTimeModay,
    endTimeModay,
    saturday,
    startTimeSaturday,
    endTimeSaturday,
    tuesday,
    startTimeTuesday,
    endTimeTuesday,
    wednesday,
    startTimeWednesday,
    endTimeWednesday,
    thursday,
    startTimeThursday,
    endTimeThursday,
    friday,
    startTimeFriday,
    endTimeFriday,
    sunday,
    startTimeSunday,
    endTimeSunday,
    selectAll,
    startTimeAll,
    endTimeAll,
    monday,
  ]);

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "2rem",
        overflowX: "auto",
      }}
    >
      <Grid container style={{ marginTop: "2rem" }}>
        <Grid item md={12} sm={12}>
          <div className={"store-timing-container"}>
            <h2 className="d-flex justify-content-start align-items-center">
              {" "}
              Set your store time{" "}
              <Tooltip className="ml-10" title="Type in the correct address of your physically available store.">
                <InfoIcon fontSize="small" />
              </Tooltip>
            </h2>
            {showAllDays && (
              <InputGroup check>
                <div className={"d-flex  justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchAll(key)}
                      on={selectAll}
                    />
                    Select All Days
                  </Label>
                  <div>
                    <Label>Start Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      disabled={!selectAll}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={({ target: { value } }) => onChangeAll(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      disabled={!selectAll}
                      className="xxx"
                      onChange={({ target: { value } }) => onChangeAllEnd(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
            )}
            <div>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitch(monday)}
                      on={monday}
                      checked={monday}
                    />
                    Monday
                  </Label>
                  <div>
                    <Label>Start Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      value={monday && startTimeModay?.format ? startTimeModay?.format("HH:mm") : ""}
                      disabled={!monday}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={({ target: { value } }) => setStartTimeMonday(moment(value, "HH:mm"))}
                    />
                  </div>
                  <div>
                    <Label>End Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      value={monday && endTimeModay?.format ? endTimeModay?.format("HH:mm") : ""}
                      defaultValue={moment()}
                      disabled={!monday}
                      className="xxx"
                      onChange={({ target: { value } }) => setEndTimeMonday(moment(value, "HH:mm"))}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchThusday(tuesday)}
                      on={tuesday}
                      checked={tuesday}
                    />
                    Tuesday
                  </Label>
                  <div>
                    <Label>Start Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      value={tuesday && startTimeTuesday?.format ? startTimeTuesday?.format("HH:mm") : ""}
                      showSecond={false}
                      defaultValue={moment()}
                      disabled={!tuesday}
                      className="xxx"
                      onChange={({ target: { value } }) => setStartTimeTuesday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      disabled={!tuesday}
                      value={tuesday && endTimeTuesday?.format ? endTimeTuesday?.format("HH:mm") : ""}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={({ target: { value } }) => setEndTimeTuesday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchWednesday(wednesday)}
                      on={wednesday}
                      checked={wednesday}
                    />{" "}
                    Wednesday
                  </Label>
                  <div>
                    <Label>Start Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      value={wednesday && startTimeWednesday?.format ? startTimeWednesday?.format("HH:mm") : ""}
                      defaultValue={moment()}
                      disabled={!wednesday}
                      className="xxx"
                      onChange={({ target: { value } }) => setStartTimeWednesday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      value={wednesday && endTimeWednesday?.format ? endTimeWednesday?.format("HH:mm") : ""}
                      disabled={!wednesday}
                      className="xxx"
                      onChange={({ target: { value } }) => setEndTimeWednesday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchThurday(thursday)}
                      on={thursday}
                      checked={thursday}
                    />
                    Thursday
                  </Label>
                  <div>
                    <Label>Start Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      value={thursday && startTimeThursday?.format ? startTimeThursday?.format("HH:mm") : ""}
                      defaultValue={moment()}
                      disabled={!thursday}
                      className="xxx"
                      onChange={({ target: { value } }) => setStartTimeThursday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time</Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      value={thursday && endTimeThursday?.format ? endTimeThursday?.format("HH:mm") : ""}
                      disabled={!thursday}
                      onChange={({ target: { value } }) => setEndTimeThursday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchFriday(friday)}
                      on={friday}
                      checked={friday}
                    />
                    Friday
                  </Label>
                  <div>
                    <Label>Start Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      disabled={!friday}
                      defaultValue={moment()}
                      value={friday && startTimeFriday?.format ? startTimeFriday?.format("HH:mm") : ""}
                      className="xxx"
                      onChange={({ target: { value } }) => setStartTimeFriday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      value={friday && endTimeFriday?.format ? endTimeFriday?.format("HH:mm") : ""}
                      defaultValue={moment()}
                      className="xxx"
                      disabled={!friday}
                      onChange={({ target: { value } }) => setEndTimeFriday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchSturday(saturday)}
                      on={saturday}
                      checked={saturday}
                    />{" "}
                    Saturday
                  </Label>
                  <div>
                    <Label>Start Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      disabled={!saturday}
                      value={saturday && startTimeSaturday?.format ? startTimeSaturday?.format("HH:mm") : ""}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={({ target: { value } }) => setStartTimeSaturday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      disabled={!saturday}
                      defaultValue={moment()}
                      className="xxx"
                      value={saturday && endTimeSaturday?.format ? endTimeSaturday?.format("HH:mm") : ""}
                      onChange={({ target: { value } }) => setEndTimeSaturday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
              <InputGroup check>
                <div className={"d-flex justify-content-between align-items-center timing-container"}>
                  <Label check>
                    <Switch
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchSunday(sunday)}
                      on={sunday}
                      checked={sunday}
                    />{" "}
                    Sunday
                  </Label>
                  <div>
                    <Label>Start Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      disabled={!sunday}
                      defaultValue={moment()}
                      className="xxx"
                      value={sunday && startTimeSunday?.format ? startTimeSunday?.format("HH:mm") : ""}
                      onChange={({ target: { value } }) => setStartTimeSunday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                  <div>
                    <Label>End Time: </Label>
                    <br />
                    <Input
                      type={"time"}
                      style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      disabled={!sunday}
                      value={sunday && endTimeSunday?.format ? endTimeSunday?.format("HH:mm") : ""}
                      className="xxx"
                      onChange={({ target: { value } }) => setEndTimeSunday(moment(value, "HH:mm"))}
                      use12Hours={true}
                    />
                  </div>
                </div>
              </InputGroup>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={"theme-line-breaker"} style={{ position: "absolute", left: 0, bottom: 0 }} />
    </div>
  );
};
